import { useState, useEffect } from "react"
import { getLocations } from "data/locations"

export default async function isCustomerHavingMatchingLocation(countryCode) {
  //Check if organization has matching Location
  const locations = await getLocations()
  if (locations) {
    return locations.some(location => countryCode === location.countryCode)
  }
  return false
}

//Add more locations as needed
export const US = "US"
export const CA = "CA"
/**
 * Retrieve if customer has matching Location
 *
 * @param {String} countryCode
 */
export function useCustomerLocation(countryCode) {
  const [hasMatchingLocation, setHasMatchingLocation] = useState(false)
  useEffect(() => {
    const retrieveCustomerLocations = async () => {
      const isHavingMatchingLocation = await isCustomerHavingMatchingLocation(countryCode)
      setHasMatchingLocation(isHavingMatchingLocation)
    }
    retrieveCustomerLocations()
  }, [countryCode])

  return hasMatchingLocation
}
