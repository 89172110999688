import axios from "axios"
import memoizeOne from "memoize-one"
import { isDevEnv } from "lib/user-session"

async function getVersion() {
  // do not ask for version.json on dev
  if (isDevEnv) {
    return { branch: "working-branch", build: "0" }
  }
  try {
    const { data } = await axios("/version.json")
    return data
  } catch (err) {
    // Shouldn't break production if version.json is inaccessible for some reason
    return { branch: "working-branch", build: "0" }
  }
}

const getCachedVersion = memoizeOne(getVersion)

export { getVersion as getUncachedVersion }
export default getCachedVersion
