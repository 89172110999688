import { lazy } from "react"

import { ProtectedToolboxLayoutRoute } from "components/layouts/ToolboxLayout"
import { rateCenterPath } from "./Paths"

const Ratecenters = lazy(() => import("./RateCenters"))

const Routes = () => {
  return (
    <>
      <ProtectedToolboxLayoutRoute exact path={rateCenterPath} Component={Ratecenters} />
    </>
  )
}

export default Routes
