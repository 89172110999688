import { lazy } from "react"

import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"
import {
  portOrdersPath,
  portOrdersReadyToSubmitPath,
  portOrdersErrorsPath,
  portOrdersInProgressPath,
  portOrdersArchivePath,
  newPortinOrderPath,
  updatePortinOrderPath,
  managePortinOrderPath
} from "./Paths"

const PortinOrders = lazy(() => import("./PortOrdersLanding"))
const NewPortinOrders = lazy(() => import("./components/NewPortinOrders/NewPortinOrders"))
// Edit and view section managePortinOrder will be deprecated
const PortOrder = lazy(() => import("./components/PortOrder/PortOrder"))

const Routes = () => {
  // const isNumberPortingEnabledForOrganization = useEnablements(NUMBER_PORTING)
  // const [hasNoPortinLocationRestrictions] = useAccess(portinsNoLocationRestrictionsPermissions)
  // const isCustomerHavingUSLocation = useCustomerLocation(US)
  // const isCustomerHavingCALocation = useCustomerLocation(CA)

  return (
    <>
      <>
        <ProtectedHubLayoutRoute exact path={portOrdersPath} Component={PortinOrders} />
        <ProtectedHubLayoutRoute exact path={portOrdersReadyToSubmitPath} Component={PortinOrders} />
        <ProtectedHubLayoutRoute exact path={portOrdersErrorsPath} Component={PortinOrders} />
        <ProtectedHubLayoutRoute exact path={portOrdersInProgressPath} Component={PortinOrders} />
        <ProtectedHubLayoutRoute exact path={portOrdersArchivePath} Component={PortinOrders} />
        <ProtectedHubLayoutRoute exact path={newPortinOrderPath} Component={NewPortinOrders} />
        <ProtectedHubLayoutRoute exact path={updatePortinOrderPath} Component={PortOrder} />
        <ProtectedHubLayoutRoute exact path={managePortinOrderPath} Component={PortOrder} />
      </>
    </>
  )
}

export default Routes
