import _flow from "lodash/flow"
import getConfiguration from "data/configuration"
import { decorateWithPaginationFunctionAware } from "../pagination"
import {
  populateParameter,
  bearerToken,
  originHeader,
  organization,
  anyOrganization,
  acceptIds,
  extractData
} from "../utils"
import { errorInterceptor } from "data/apis/errorInterceptor"

const url = populateParameter(async () => {
  const { foundry } = await getConfiguration()
  return foundry.url
})

const defaultParameters = _flow(errorInterceptor, url, bearerToken, originHeader)
const defaultOrganizationParameters = _flow(defaultParameters, organization)
const defaultAnyOrganizationParameters = _flow(defaultParameters, anyOrganization)
const allResults = decorateWithPaginationFunctionAware

/**
 * Notes about lodash.flow
 *
 * https://lodash.com/docs/4.17.11#flow
 *
 * _flow(a, b, c)(fn) == c(b(a(fn)))
 *
 * This was counter-intuitive to me.
 *
 * For example:
 *
 * _flow(allResults, defaultOrganizationParameters, extractData)(x)
 *
 * is equivalent to:
 *
 * extractData(defaultOrganizationParameters(allResults(x)))
 *
 * It makes sense for extractData to be on the outside (last parameter), since it doesn't change the request, it just pulls the data
 * out of the response
 *
 * It's important that allResults is on the inside (first parameter), because it needs all the parameters to be filled before
 * running
 *
 */

/**
 * Fills in the url, bearer token, and origin for wrapped calls
 * Extracts the enveloped data from the response
 */
const wrapFoundryFunction = _flow(defaultParameters, extractData)

/**
 * Use this when you:
 * - have a SDK function that needs organization
 *
 * Fills in the url, bearer token, origin, and organization for wrapped calls
 * Extracts the enveloped data from the response
 */
const wrapFoundryFunctionWithOrganization = _flow(defaultOrganizationParameters, extractData)

/**
 * Use this when you:
 * - have a SDK function that needs organization
 * - need to read the pagination information from the response
 *
 * Fills in the url, bearer token, origin, and organization for wrapped calls
 * DOES NOT extract the enveloped data.
 */
const wrapFoundryFunctionWithOrganizationWithPagination = defaultOrganizationParameters

/**
 * Use this when you:
 * - have a SDK function that doesn't need organization
 * - defaults to page size of 1000
 *
 * Fills in the url, bearer token, origin, and organization for wrapped calls
 * Extracts the enveloped data from the response
 */
const wrapFoundryFunctionWithoutOrganizationPaginated = _flow(defaultParameters, fn => {
  return (query, pagination = { offset: 0, limit: 1000 }, ...args) => fn(query, pagination, ...args)
})

/**
 * Use this when you:
 * - have a SDK function that needs organization
 * - have a SDK function that takes an object that accepts { ids } and you want a function that you can pass
 *   a list of ids (eg. rather than calling searchUsers({ ids }), you can call searchUsers(ids))
 *
 * Fills in the url, bearer token, origin, and organization for wrapped calls
 * Converts function from taking ({ ids }) to (ids)
 * Extracts the enveloped data from the response
 */
const wrapIdsFoundryFunction = _flow(defaultOrganizationParameters, acceptIds, extractData)

/**
 * Use this when you:
 * - have a SDK function that needs organization
 * - want to get all results (searches across pages)
 * - have a SDK function that takes an object that accepts { ids } and you want a function that you can pass
 *   a list of ids (eg. rather than calling searchUsers({ ids }), you can call searchUsers(ids))
 *
 * Fills in the url, bearer token, origin, and organization for wrapped calls
 * Converts function from taking ({ ids }) to (ids)
 * Gets all pages of data
 * Extracts the enveloped data from the response
 */
const wrapAllResultsWithIds = _flow(allResults, defaultOrganizationParameters, acceptIds, extractData)

/**
 * This is the same as above, but will pass null for the organization parameter, which should include results for
 * all organizations
 */
const wrapAllResultsWithIdsAnyOrganization = _flow(allResults, defaultAnyOrganizationParameters, acceptIds, extractData)

/**
 * Use this when you:
 * - have a SDK function that doesn't need organization
 * - want to get all results (searches across pages)
 *
 * Fills in the url, bearer token, origin for wrapped calls
 * Gets all pages of data
 * Extracts the enveloped data from the response
 */

const wrapAllResultsNoOrganization = _flow(allResults, defaultParameters, extractData)
const wrapAllResultsNoOrganizationNoDataExtracting = _flow(allResults, defaultParameters)

/**
 * Use this when you:
 * - have a SDK function that needs organization
 * - want to get all results (searches across pages)
 *
 * Fills in the url, bearer token, origin, and organization for wrapped calls
 * Gets all pages of data
 * Extracts the enveloped data from the response
 */
const wrapAllResults = _flow(allResults, defaultOrganizationParameters, extractData)

export {
  wrapFoundryFunction,
  wrapFoundryFunctionWithOrganization,
  wrapFoundryFunctionWithoutOrganizationPaginated,
  wrapFoundryFunctionWithOrganizationWithPagination,
  wrapIdsFoundryFunction,
  wrapAllResultsWithIds,
  wrapAllResultsWithIdsAnyOrganization,
  wrapAllResults,
  wrapAllResultsNoOrganization,
  wrapAllResultsNoOrganizationNoDataExtracting
}
