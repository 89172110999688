import React, { HTMLAttributes, FunctionComponent } from "react"

type ImageProps = FunctionComponent<HTMLAttributes<HTMLOrSVGElement> & { alignTop?: string; fontSize?: string }>

const Image: ImageProps = ({ alignTop, fontSize, children, className = "" }) => {
  return (
    <div
      className={`image-component ${className} ${alignTop ? "image-component--align-vertical-top" : ""}`}
      style={{
        fontSize
      }}
    >
      {children}
    </div>
  )
}

export default Image
