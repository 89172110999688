import { useEffect, useState } from "react"
import { getCurrencyByLocation } from "data/pricing"
import { getProductsByLocation, getOrganization } from "./apis/foundry"
import { getContractedProducts } from "./apis/mint"
import { getOrganization as getActingOrganization } from "lib/user-session"
import { getEnabledProducts, getNonAlloyEnabledProducts } from "data/apis/floppy"
import { searchProducts } from "./apis/foundry"
import { isOrganizationAlloy } from "data/apis/foundry/organizations"
import _intersectionBy from "lodash/intersectionBy"
import _find from "lodash/find"
import _differenceBy from "lodash/differenceBy"
import _groupBy from "lodash/groupBy"
import { ProductCategory } from "./ProductCategory"

export const RecordingConfig = {
  recordAll: 0,
  recordRandom: 1,
  manualRecording: 2
}

export const DefaultRecordingConfig = { automatic: true, percent: 100, playback: false }
export const DEFAULT_PERCENT = 10

export const RecordingPlanType = {
  METERED: "metered",
  UNMETERED: "unmetered"
}

export const RECORDING_CATEGORY = "call_recording"

export const isVoiceProduct = (product, floppyProducts) => {
  const floppyProduct = floppyProducts.find(floppyProduct => floppyProduct.sku === product?.sku)
  return floppyProduct?.category === "voice"
}

export const isVideoProduct = (product, floppyProducts) => {
  const floppyProduct = floppyProducts.find(floppyProduct => floppyProduct.sku === product?.sku)
  return floppyProduct?.category === "video"
}

export const isVoiceService = service => {
  return service.type === "UC-USER" && !isVideoService(service)
}

export const isVideoService = service => {
  // TODO This is temporary. We should look this up by PBX feature
  return service && service.sku.substring(0, 12) === "UC-MR-COLLAB"
}

export const useTriageUserServices = user => {
  const [enabledProducts, setEnabledProducts] = useState([])
  const [canceled, setCanceled] = useState(false)
  useEffect(() => {
    const retrieveEnabledProducts = async () => {
      const products = await getEnabledProducts()
      if (!canceled) {
        setEnabledProducts(products)
      }
    }
    retrieveEnabledProducts()
    return () => {
      setCanceled(true)
    }
  }, [user.userName, canceled])
  const voiceServices =
    user && user.services
      ? user.services.filter(userService => {
          const enabledService = _find(enabledProducts, { sku: userService.sku })
          return enabledService && enabledService.category === "voice"
        })
      : []
  const videoServices =
    user && user.services
      ? user.services.filter(userService => {
          const enabledService = _find(enabledProducts, { sku: userService.sku })
          return enabledService && enabledService.category === "voice"
        })
      : []
  const otherServices = user ? _differenceBy(user.services, voiceServices, videoServices, "sku") : []
  return [voiceServices, videoServices, otherServices]
}

/**
 * Returns a list with two values
 * The first is the list of contracted products
 * The second is the list of non-contracted products
 *
 * @param {Number} locationId
 * @param {String} productType
 */
async function getProductsAtLocation(locationId) {
  const organizationCode = getActingOrganization()
  const organization = await getOrganization(organizationCode)
  if (organization.isMsa) {
    const products = await getMSAProductsAtLocation(locationId, organizationCode)
    return products
  } else {
    return await getCSAProductsAtLocation(locationId)
  }
}

/**
 * Returns a list products that is from HUB enabled non-alloy product list
 * It is ONLY used to resolve product info in the case of customer that is
 * in the process of Alloy migration, in which case the customer is flipped as an Alloy customer
 * but still has services that are using non-Alloy (legacy) products. That is why
 * in the case of non-Alloy customer, it is not needed thus simply return empty list.
 *
 */
async function getNonAlloyProducts() {
  const isAlloyCustomer = await isOrganizationAlloy()
  if (isAlloyCustomer) {
    const nonAlloyProducts = await getNonAlloyEnabledProducts()
    return nonAlloyProducts
  } else {
    return []
  }
}

/**
 * Returns a list products that combine data from Foundry and HUB Floppy
 *
 * @param {String[]} skus
 */
async function getMergedFoundryHubProducts(skus) {
  const productsPromise = searchProducts({ skus, showInactive: true })
  const enabledProductsPromise = getEnabledProducts()
  const nonAlloyProductsPromise = getNonAlloyProducts()

  const [products, enabledProducts, nonAlloyProducts] = await Promise.all([
    productsPromise,
    enabledProductsPromise,
    nonAlloyProductsPromise
  ])

  return mergeFoundryProductsWithHub(products, enabledProducts, nonAlloyProducts)
}

/**
 * Returns a list with two values
 * The first is the list of contracted products
 * The second is the list of non-contracted products
 *
 * @param {Number} locationId
 * @param {String} category
 */
async function getProductsAtLocationByCategory(locationId, category) {
  const organizationCode = getActingOrganization()
  const organization = await getOrganization(organizationCode)
  if (organization.isMsa) {
    const products = await getMSAProductsAtLocationByCategory(locationId, organizationCode, category)
    return products
  } else {
    return await getCSAProductsAtLocationByCategory(locationId, category)
  }
}

/**
 * Returns a list with two elements (a list of contracted products and a list of uncontracted products)
 * @param {Number} locationId A numeric location id
 * @param {String} organization An organization code
 * @param {String} productType A product type
 */
async function getMSAProductsAtLocation(locationId, organizationCode) {
  const currency = await getCurrencyByLocation(locationId)

  const contractedProductsPromise = getContractedProductsFromMint(currency, organizationCode)
  const enabledProductsPromise = getEnabledProducts()

  return combineMSAContractedAndEnabledProducts(currency, locationId, contractedProductsPromise, enabledProductsPromise)
}

/**
 * Returns a list with two elements (a list of contracted products and a list of uncontracted products)
 * @param {Number} locationId A numeric location id
 * @param {String} organizationCode An organization code
 * @param {String} category A floppy category. Note that this is different from product type
 */
async function getMSAProductsAtLocationByCategory(locationId, organizationCode, category) {
  const currency = await getCurrencyByLocation(locationId)

  const contractedProductsPromise = getContractedProductsFromMint(currency, organizationCode)
  const enabledProductsPromise = getEnabledProducts([category])

  return combineMSAContractedAndEnabledProducts(currency, locationId, contractedProductsPromise, enabledProductsPromise)
}

/**
 * Given a location, determine if this is an alloy customer or a non-alloy MSA customer
 *
 * If Alloy, we skip the foundry check (getProductsByLocation)
 *
 * This returns a promise (that resolves to a function),
 * which is just an optimization that prevents blocking calls and makes the code
 * more consistent with the older code
 *
 * The function that gets resolved takes a list (in this case the list of contracted products) and
 * can filter on them. For alloy, this doesn't filter anything, but for non-Alloy, it is the
 * intersection of the contracted products with the list of "correct currency" products from Foundry (by sku)
 *
 * @param {Number} locationId
 */
function getProductFilterPromise(locationId) {
  return new Promise(async resolve => {
    const isAlloyCustomer = await isOrganizationAlloy()
    if (isAlloyCustomer) {
      resolve(products => products)
    } else {
      const currencyProducts = await getProductsByLocation(locationId, false)
      resolve(products => _intersectionBy(products, currencyProducts, "sku"))
    }
  })
}

/**
 * Creates a product filter and then merges the contract, enabled products together using the filter
 *
 * @param {String} currency The currency of the location
 * @param {Number} locationId The location that is being used to look up products
 * @param {Promise<Product[]>} contractedProductsPromise The list of contract products (from Mint)
 * @param {Promise<Product[]>} enabledProductsPromise The list of enabled products (from Floppy)
 */
async function combineMSAContractedAndEnabledProducts(
  currency,
  locationId,
  contractedProductsPromise,
  enabledProductsPromise
) {
  const productFilterPromise = getProductFilterPromise(locationId)

  const [contractedProducts, enabledProducts, productFilter] = await Promise.all([
    contractedProductsPromise,
    enabledProductsPromise,
    productFilterPromise
  ])

  return mergeMsaProducts(contractedProducts, enabledProducts, productFilter, currency)
}

/**
 * Returns a list of contracted products
 * @param {Number} locationId A numeric location id
 * @param {String} productType A product type
 */
async function getCSAProductsAtLocation(locationId) {
  const contractedProductsPromise = getProductsByLocation(locationId, true)
  const enabledProductsPromise = getEnabledProducts()

  const [contractedProducts, enabledProducts] = await Promise.all([contractedProductsPromise, enabledProductsPromise])

  return mergeCsaProducts(contractedProducts, enabledProducts)
}

/**
 * Returns a list of contracted products
 * @param {Number} locationId A numeric location id
 * @param {String} category A product type
 */
async function getCSAProductsAtLocationByCategory(locationId, category) {
  const contractedProductsPromise = getProductsByLocation(locationId, true)
  const enabledProductsPromise = getEnabledProducts([category])

  const [contractedProducts, enabledProducts] = await Promise.all([contractedProductsPromise, enabledProductsPromise])

  const intersection = _intersectionBy(contractedProducts, enabledProducts, "sku")
  return intersection.map(product => ({ ...product, ..._find(enabledProducts, { sku: product.sku }) }))
}

/**
 * Given a list of contracted products and all products, returns a list of contracted products and uncontracted products
 *
 * @param {Product[]} contractedProducts A list of products from mint that are on the organizations contract
 * @param {Product[]} enabledProducts A list of products that are enabled
 * @param {Product[]} productFilter A function that will filter some of the contracted products out
 * @param {String} currency A three digit currency code
 */
function mergeMsaProducts(contractedProducts, enabledProducts, productFilter, currency) {
  const mergedContractedProducts = productFilter(contractedProducts).map(contractedProduct => {
    const matchingProduct = enabledProducts.find(product => contractedProduct.sku === product.sku)

    return {
      ...matchingProduct,
      name: contractedProduct.description,
      productPrice: {
        price: contractedProduct.price,
        threeDigit: currency
      },
      productType: contractedProduct.productType
    }
  })

  // Returns the list of products that are in both mergedContractedProducts and enabledProducts
  const enabledContractedProducts = _intersectionBy(mergedContractedProducts, enabledProducts, "sku")

  return enabledContractedProducts
}

/**
 * Given a list of contracted (CSA) products and all products, returns a list of contracted products
 *
 * @param {Product[]} contractedProducts A list of products from Foundry that are on a CSA
 * @param {Product[]} enabledProducts A list of products that are enabled
 */
function mergeCsaProducts(contractedProducts, enabledProducts) {
  const enabledContractedProducts = _intersectionBy(contractedProducts, enabledProducts, "sku").map(
    contractedProduct => {
      const matchingProduct = enabledProducts.find(product => contractedProduct.sku === product.sku)

      return {
        ...contractedProduct,
        category: matchingProduct.category,
        subCategory: matchingProduct.subCategory
      }
    }
  )
  return enabledContractedProducts
}

/**
 * Given a list of Foundry products and HUB Floppy products, returns a list of products with
 * combining data
 *
 * @param {Product[]} foundryProducts A list of products from Foundry
 * @param {Product[]} enabledProducts A list of products that are HUB enabled from Floppy
 * @param {Product[]} nonAlloyProducts A list of non-Alloy products that are HUB enabled from Floppy
 */
function mergeFoundryProductsWithHub(foundryProducts, enabledProducts, nonAlloyProducts) {
  const mergedProducts = foundryProducts.map(foundryProduct => {
    const matchingProduct = enabledProducts.find(product => foundryProduct.sku === product.sku)

    if (matchingProduct) {
      return {
        ...foundryProduct,
        category: matchingProduct?.category,
        subCategory: matchingProduct?.subCategory
      }
    } else if (nonAlloyProducts?.length > 0) {
      // This is to mark product as "legacy" to for ALLOY customer that is in the process of migration and
      // still has service(s) with product(s) that are NON-ALLOY.
      // In other words it is ONLY applicable to ALLOY customer who is in the process of migration.
      const matchingNonAlloyProduct = nonAlloyProducts.find(product => foundryProduct.sku === product.sku)
      return {
        ...foundryProduct,
        category: matchingNonAlloyProduct?.category,
        subCategory: matchingNonAlloyProduct?.subCategory,
        legacy: !!matchingNonAlloyProduct
      }
    }
    return foundryProduct
  })

  return mergedProducts
}

/**
 * Retrieves a list of contracted products from Mint based on currency (location)
 * Note that it has to do some massaging as the contracted products endpoint
 * from Mint doesn't have filtering options
 *
 * @param {String} currency A three character currency code
 * @param {String} organization An organization code
 */
async function getContractedProductsFromMint(currency, organizationCode) {
  const mintResponse = await getContractedProducts(organizationCode)
  if (mintResponse.length > 0) {
    const { records } = mintResponse[0]
    const matchingCurrencyRecord = records.find(record => record.currency === currency)
    if (!!matchingCurrencyRecord) {
      const productsWithMatchingCurrency = matchingCurrencyRecord.products || []
      return productsWithMatchingCurrency
    }
  }
  return []
}

function isRecordingProduct(product) {
  return product?.category === RECORDING_CATEGORY
}

function isMeteredRecordingProduct(product) {
  return product?.category === RECORDING_CATEGORY && product?.subCategory === RecordingPlanType.METERED
}

function isUnMeteredRecordingProduct(product) {
  return product?.category === RECORDING_CATEGORY && product?.subCategory === RecordingPlanType.UNMETERED
}

function categorizeProducts(products = []) {
  const productMap = _groupBy(products, "category")

  const voiceProducts = productMap[ProductCategory.voice]
  const videoProducts = productMap[ProductCategory.video]
  const faxProducts = productMap[ProductCategory.fax]
  const didProducts = productMap[ProductCategory.did]
  const discoverProducts = productMap[ProductCategory.discover]
  const recordingProducts = productMap[ProductCategory.recording]
  const contactCenterProducts = productMap[ProductCategory.contactCenter]
  const smsProducts = productMap[ProductCategory.sms]

  return {
    voiceProducts: voiceProducts ? voiceProducts : [],
    videoProducts: videoProducts ? videoProducts : [],
    faxProducts: faxProducts ? faxProducts : [],
    didProducts: didProducts ? didProducts : [],
    discoverProducts: discoverProducts ? discoverProducts : [],
    recordingProducts: recordingProducts ? recordingProducts : [],
    contactCenterProducts: contactCenterProducts ? contactCenterProducts : [],
    smsProducts: smsProducts ? smsProducts : []
  }
}

export {
  getProductsAtLocation,
  getMergedFoundryHubProducts,
  mergeMsaProducts, // exported only for tests
  mergeCsaProducts, // exported only for tests
  getProductsAtLocationByCategory,
  getCSAProductsAtLocationByCategory,
  getMSAProductsAtLocationByCategory,
  getMSAProductsAtLocation,
  isRecordingProduct,
  isMeteredRecordingProduct,
  isUnMeteredRecordingProduct,
  categorizeProducts,
  mergeFoundryProductsWithHub
}
