import { Redirect, Route, Switch } from "routing"

import DIDRoutes from "DIDs/NumberRequests/Routes"
import PortRequestsRoutes from "DIDs/PortRequests/Routes"
import ToolboxRoutes from "DIDs/ToolboxRoutes"
import RateCenterRoutes from "../RateCenter/Routes"
import ErrorsRoutes from "Errors/Routes"

export default function Routes() {
  return (
    <Switch>
      <>
        <DIDRoutes />
        <PortRequestsRoutes />

        <RateCenterRoutes />
        <ToolboxRoutes />
        <ErrorsRoutes />
        <Route exact path="/">
          <Redirect to="/dids/requests?organization=hubdevcustomer" />
        </Route>
      </>
    </Switch>
  )
}
