import React, { FunctionComponent, useState } from "react"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import { getOrganization } from "lib/user-session"
import useOrganization from "hooks/useOrganization"
import { useIsAllowedToManageOthers } from "../../components/SwitchOrganization"
import { useToggle } from "react-use"
import { useIntl } from "react-intl"
import classNames from "classnames"
import SearchCustomers from "./SearchCustomers"
import FavoriteCustomers from "./FavoriteCustomers"
import AllCustomers from "./AllCustomers"
import useFavourites from "../hooks/useFavourites"

type TenantSwitcherProps = {
  allowCustomerSwitching: boolean
}
const TenantSwitcher: FunctionComponent<TenantSwitcherProps> = ({ allowCustomerSwitching }) => {
  const { formatMessage } = useIntl()
  const organizationCode = getOrganization()
  const [organization] = useOrganization(organizationCode)
  const [isShowDropdown, toggleDropdown] = useToggle(false)
  const isAllowedToManageOthers = useIsAllowedToManageOthers()
  const [query, setQuery] = useState<string>("")
  const { favouriteTenants, updateFavourites } = useFavourites()

  const TenantName = () => (
    <span id="customer-name" className="tenant-switcher__organization-name">
      {organization?.name}
    </span>
  )

  if (!allowCustomerSwitching || !isAllowedToManageOthers) {
    return (
      <div className="tenant-switcher">
        <TenantName />
      </div>
    )
  }

  return (
    <>
      <Dropdown isOpen={isShowDropdown} toggle={toggleDropdown}>
        <DropdownToggle
          tag={"button"}
          id="tenant-switcher__button"
          className={classNames("tenant-switcher tenant-switcher--button", {
            "tenant-switcher--button__open": isShowDropdown
          })}
          aria-label={formatMessage({ id: "navigation.tenantSwitcher.button" }, { tenant: organization?.name })}
        >
          <TenantName />
          <i className={"icon-switch_icon tenant-switcher__icon"} />
        </DropdownToggle>
        {isShowDropdown && (
          <DropdownMenu className={"tenant-switcher--dropdown-menu"}>
            <SearchCustomers filterValue={query} onFilterChange={setQuery} />
            <FavoriteCustomers favouriteTenants={favouriteTenants} updateFavourites={updateFavourites} />
            <AllCustomers query={query} favouriteTenants={favouriteTenants} updateFavourites={updateFavourites} />
          </DropdownMenu>
        )}
      </Dropdown>
    </>
  )
}

export default TenantSwitcher
