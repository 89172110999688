import React from "react"
import { FormattedMessage } from "react-intl"
import { getOrganization as getActingOrganization } from "lib/user-session"
import { useIsPartnerTenant } from "../hooks/useIsPartnerTenant"

const ActivityStatus = tenant => (
  <FormattedMessage id={"tenantSwitcher.customerStatus"} values={{ status: tenant.active ? "active" : "suspended" }} />
)

const OwnOrganizationStatus = <FormattedMessage id={"tenantSwitcher.ownOrganization"} />

const MsaOrCsaStatus = tenant => <FormattedMessage id={"tenantSwitcher.isMSA"} values={{ isMsa: tenant.isMsa }} />

const IsPartner = tenant => (tenant.type === "reseller" ? <FormattedMessage id={"tenantSwitcher.partner"} /> : null)

const ManagedBy = tenant =>
  tenant.managedByPartner ? (
    <FormattedMessage id={"tenantSwitcher.managedBy"} values={{ managedBy: tenant.managedByPartner.name }} />
  ) : null

const TenantStatus = ({ tenant }) => {
  const isPartner = useIsPartnerTenant()
  const statuses = isPartner
    ? [ActivityStatus(tenant)]
    : [ActivityStatus(tenant), MsaOrCsaStatus(tenant), IsPartner(tenant), ManagedBy(tenant)]
  const isOwnOrganization = tenant.code === getActingOrganization()
  const activeStatuses = statuses.filter(b => b)
  return (
    <span className={"tenant__statuses"}>
      {isOwnOrganization && <span className={"tenant__status"}>{OwnOrganizationStatus}</span>}
      {!isOwnOrganization &&
        activeStatuses.map((status, index) => (
          <span className={"tenant__status"} key={index}>
            {status}
            {index < activeStatuses.length - 1 && ", "}
          </span>
        ))}
    </span>
  )
}

export default TenantStatus
