import React from "react"
import { Button, Collapse } from "reactstrap"
import camelCase from "lodash/camelCase"
import classNames from "classnames"

type ToggleSectionProps = {
  title: string
  isOpen?: boolean
  toggleOpen: () => void
}
const ToggleSection: React.FunctionComponent<ToggleSectionProps> = ({
  title,
  isOpen = false,
  toggleOpen,
  children
}) => {
  const collapseId = `toggle-section--${camelCase(title)}__collapse` //using camelCase to remove spaces and special chars
  const buttonId = `toggle-section--${camelCase(title)}__button`

  return (
    <div className="toggle-section">
      <Button
        id={buttonId}
        color="toggle"
        className="toggle-section__button"
        onClick={toggleOpen}
        aria-expanded={isOpen}
        aria-controls={collapseId}
      >
        <span className="toggle-section__button__title">{title}</span>
        <span className="toggle-section__button__arrow">
          <i className={classNames({ "icon-arrow-down": isOpen, "icon-arrow-up": !isOpen })} />
        </span>
      </Button>
      <Collapse
        id={collapseId}
        aria-labelledby={buttonId}
        role="region"
        isOpen={isOpen}
        className={"toggle-section--body"}
      >
        {children}
      </Collapse>
    </div>
  )
}

export default ToggleSection
