import _getService from "@fuze/apis-foundry/dist/services/functions/getService"
import _searchServices from "@fuze/apis-foundry/dist/services/functions/searchServices"
import _getServices from "@fuze/apis-foundry/dist/services/functions/getServices"

import {
  wrapFoundryFunction,
  wrapAllResultsNoOrganization,
  wrapFoundryFunctionWithOrganizationWithPagination,
  wrapAllResultsNoOrganizationNoDataExtracting
} from "./decorators"

export const searchServices = wrapAllResultsNoOrganization(_searchServices)
export const searchServicesRaw = wrapAllResultsNoOrganizationNoDataExtracting(_searchServices)
export const getServicesByType = (type, query, pagination) =>
  wrapFoundryFunctionWithOrganizationWithPagination(_getServices)(type, query, pagination)
export const getService = wrapFoundryFunction(_getService)
