import { searchExtensions, getUsersByIds, getDepartmentsByIds, searchHandsets } from "./apis/foundry"
import { getMergedFoundryHubProducts } from "data/products"
import { getOrganization as getActingOrganization } from "../lib/user-session"

import { getServicePrices } from "./pricing"
import _keyBy from "lodash/keyBy"
import _curryRight from "lodash/curryRight"
import { emptyPromise } from "./query/resolverHelpers"
import { getAllStoredLocationsByIds } from "./locations"
import { searchDIDs } from "./apis/foundry/dids"

function products() {
  return {
    resolve: retrieveProductsForServices,
    reduce: combineServicesWithProducts
  }
}

function retrieveProductsForServices(services) {
  const skus = services.map(service => service.sku)
  if (skus.length > 0) {
    // get products from foundry
    // combine data from floppy
    return getMergedFoundryHubProducts(skus)
  }
  return emptyPromise()
}

function combineServicesWithProducts(services, products) {
  const productMap = _keyBy(products, "sku")
  return services.map(service => {
    return {
      ...service,
      product: productMap[service.sku]
    }
  })
}

function extensions(request) {
  return {
    resolve: _curryRight(retrieveExtensionsForServices, 2)(request),
    reduce: combineServicesWithExtensions
  }
}

function retrieveExtensionsForServices(services, request = serviceIds => searchExtensions(serviceIds)) {
  const serviceIds = services.map(service => service.id)
  if (serviceIds.length > 0) {
    return request({ services: serviceIds })
  }
  return emptyPromise()
}

function combineServicesWithExtensions(services, extensions) {
  const extensionMap = _keyBy(extensions, "service.id")
  return services.map(service => ({
    ...service,
    extension: extensionMap[service.id]
  }))
}

function dids() {
  return {
    resolve: retrieveDIDsForExtensions,
    reduce: combineExtensionsWithDIDs
  }
}

function retrieveDIDsForExtensions(extensions) {
  const dids = extensions.map(extension => extension.did)
  if (dids.length > 0) {
    return searchDIDs({ dids })
  }
  return emptyPromise()
}

function combineExtensionsWithDIDs(extensions, dids) {
  const didsMap = _keyBy(dids, "did")
  return extensions.map(extension => ({
    ...didsMap[extension.did],
    ...extension
  }))
}

function prices() {
  return {
    resolve: retrievePricesForServices,
    reduce: combineServicesWithPrices
  }
}

function retrievePricesForServices(services) {
  const serviceIds = services.map(service => service.id)
  if (serviceIds.length > 0) {
    return getServicePrices(services, null)
  }
  return emptyPromise()
}

function combineServicesWithPrices(services, prices) {
  const priceMap = _keyBy(prices, "serviceId")
  return services.map(service => ({
    ...service,
    price: priceMap[service.id]
  }))
}

function locations() {
  return {
    resolve: retrieveLocationsForServices,
    reduce: combineServicesWithLocations
  }
}

function retrieveLocationsForServices(services) {
  const locationIds = services.filter(service => !!service.location).map(service => service.location.id)
  if (locationIds.length > 0) {
    return getAllStoredLocationsByIds(locationIds)
  }
  return emptyPromise()
}

function combineServicesWithLocations(services, locations) {
  const locationMap = _keyBy(locations, "id")
  return services.map(service => {
    return {
      ...service,
      location: !!service.location && locationMap[service.location.id]
    }
  })
}

function departments() {
  return {
    resolve: retrieveDepartmentsForServices,
    reduce: combineServicesWithDepartments
  }
}

function retrieveDepartmentsForServices(services) {
  const departmentIds = services.filter(service => !!service.department).map(service => service.department.id)
  if (departmentIds.length > 0) {
    return getDepartmentsByIds(departmentIds)
  }
  return emptyPromise()
}

function combineServicesWithDepartments(services, departments) {
  const departmentMap = _keyBy(departments, "id")
  return services.map(service => {
    return {
      ...service,
      department: !!service.department && departmentMap[service.department.id]
    }
  })
}

function users(request) {
  return {
    resolve: _curryRight(retrieveUsersForServices, 2)(request),
    reduce: combineServicesWithUsers
  }
}

function retrieveUsersForServices(services, request = userIds => getUsersByIds(userIds)) {
  const userIds = services.filter(service => !!service.user).map(service => service.user.id)
  if (userIds.length > 0) {
    return request(userIds)
  }
  return emptyPromise()
}

function combineServicesWithUsers(services, users) {
  const userMap = _keyBy(users, "id")
  return services.map(service => {
    return {
      ...service,
      user: !!service.user && userMap[service.user.id]
    }
  })
}

function retrieveHandsetsForServices(services) {
  const organization = getActingOrganization()
  const serviceIds = services.map(service => service.id)
  if (serviceIds) {
    return searchHandsets(organization, { serviceIds })
  }
  return emptyPromise()
}

function combineServicesWithHandsets(services, handsets) {
  const handsetMap = _keyBy(handsets, "service.id")
  return services.map(service => ({
    ...service,
    handset: handsetMap[service.id]
  }))
}

export {
  products,
  retrieveProductsForServices,
  combineServicesWithProducts,
  prices,
  retrievePricesForServices,
  combineServicesWithPrices,
  locations,
  retrieveLocationsForServices,
  combineServicesWithLocations,
  departments,
  retrieveDepartmentsForServices,
  combineServicesWithDepartments,
  users,
  retrieveUsersForServices,
  combineServicesWithUsers,
  extensions,
  retrieveExtensionsForServices,
  combineServicesWithExtensions,
  retrieveHandsetsForServices,
  combineServicesWithHandsets,
  dids
}
