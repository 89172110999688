import {
  getLocations as getAPILocations,
  searchLocationRegistrations,
  searchLocationRegistrationsCount
} from "./apis/foundry"
import _merge from "lodash/merge"
import _find from "lodash/find"
import _intersectionWith from "lodash/intersectionWith"
import _flow from "lodash/flow"
import { loggedInUserManagedLocations } from "lib/user-session"
import { getOrganization as getActingOrganization } from "lib/user-session"

const TTL = 5 * 60 * 1000 // 5 min

// TODO: Eventurely HUB will need to support the following scenarios, but given API only supports
// the first scenario, we will leave the other two scenarios commented for now
export const IstNoPeers = { voiceEnabled: true, hasIST: true, hasPeer: false }
// const LegacyRegistration = { voiceEnabled: true, hasIST: false, hasPeers: false, countOnly: true }
// const FailedRegistration = {  }

export const retrieveLocations = async () => {
  const locations = await getAPILocations()

  if (!locations.length) return []

  const locationsRegistration = await searchLocationRegistrations({
    locationIds: locations.map(location => location.id)
  })
  return locations.map(location =>
    _merge(location, { registration: _find(locationsRegistration, { locationId: location.id }) })
  )
}

/**
 * As the name suggests this function is used to cache API call results
 * @param apiFunc The function that issues API calls
 * @param ttl Time To Live - used to invalidate cache on a time based approach
 * @returns {function(): *} A Promise which holds the requested data (whether readily available or deferred)
 */
const cachedAPICall = (apiFunc, ttl = TTL) => {
  let lastUpdate, lastResult
  return invalidateCache => {
    if (!lastUpdate || new Date().getTime() >= lastUpdate + ttl || invalidateCache) {
      lastUpdate = new Date().getTime()
      lastResult = apiFunc()
    }
    return lastResult
  }
}

async function getFilteredLocations(getCachedLocations) {
  const allocatedLocations = loggedInUserManagedLocations() || []
  const cachedLocations = await getCachedLocations
  return allocatedLocations.length
    ? _intersectionWith(cachedLocations, allocatedLocations, (location, id) => location.id === id)
    : cachedLocations
}

const allLocations = cachedAPICall(retrieveLocations)
const cachedLocationsData = _flow(allLocations, getFilteredLocations)

const getStoredLocations = async (q, invalidateCache) => {
  let filteredLocations = await cachedLocationsData(invalidateCache)

  if (q && q.locationIds && q.locationIds.length) {
    filteredLocations = filteredLocations.filter(location => q.locationIds.includes(location.id))
  }

  return filteredLocations
}

const getAllLocations = async () => await allLocations()

const getStoredLocationById = async id => {
  let filteredLocations = await cachedLocationsData()

  return filteredLocations.find(location => location.id === id)
}

const getStoredLocationsByIds = async ids => {
  let filteredLocations = await cachedLocationsData()

  return filteredLocations.filter(location => ids.includes(location.id))
}

const getAllStoredLocationsByIds = async ids => {
  let allLocations = await getAllLocations()

  return allLocations.filter(location => ids.includes(location.id))
}

const getStoredVoiceEnabledLocations = async () => {
  let filteredLocations = await cachedLocationsData()

  return filteredLocations.filter(location => location.registration.voiceEnabled)
}

const isLocationVoiceEnabled = async locationId => {
  const voiceEnabledLocations = await getStoredVoiceEnabledLocations()
  return !!voiceEnabledLocations.find(location => location.id === locationId)
}

const getLocationRegistration = async locationId => {
  let locations = await getAllLocations()
  const location = locations.find(location => location.id === locationId)
  return !!location && location.registration
}

const getActionRequiredLocationsByFilter = async filters => {
  const actingOrg = getActingOrganization()
  const result = await searchLocationRegistrations({ organization: actingOrg, ...filters, countOnly: false })
  return result
}

const getAllActionRequiredLocations = async () => {
  const locations = await getStoredLocations()
  const istNoPeersLocRegPromise = getActionRequiredLocationsByFilter(IstNoPeers)
  const [istNoPeersLocRegs] = await Promise.all([istNoPeersLocRegPromise])
  return locations.filter(location => istNoPeersLocRegs.find(registration => registration.locationId === location.id))
}

const getActionRequiredLocationsCountByFilter = async filters => {
  const actingOrg = getActingOrganization()
  const result = await searchLocationRegistrationsCount({ organization: actingOrg, ...filters, countOnly: true })
  return result?.pagination?.total || 0
}

const getActionRequiredLocationTotalCount = async () => {
  const locations = await getStoredLocations()
  const locationIds = locations.map(location => location.id)
  const istNoPeersPromise = getActionRequiredLocationsCountByFilter({ ...IstNoPeers, locationIds: locationIds })
  const [istNoPeersCount] = await Promise.all([istNoPeersPromise])
  return Number(istNoPeersCount) // + Number(errorCount2) + Number(errorCount3)
}

export {
  getStoredLocations as getLocations,
  getAllLocations,
  getStoredLocationsByIds as getLocationsByIds,
  getStoredLocationById as getLocationById,
  getStoredVoiceEnabledLocations,
  getAllStoredLocationsByIds,
  isLocationVoiceEnabled,
  getLocationRegistration,
  cachedAPICall,
  getAllActionRequiredLocations,
  getActionRequiredLocationTotalCount
}
