import { lazy } from "react"

import { ProtectedToolboxLayoutRoute, ProtectedToolboxGlobalLayoutRoute } from "components/layouts/ToolboxLayout"
import { allDidRequestsPath, didInvestigationsPath, emailDetailPath } from "./NumberRequests/Paths"
import {
  logisticsPortinsPath,
  managePortinOrderByLogisticsPath,
  holidayPortalPath,
  managerFocChangeRequestsLogisticsPath
} from "./PortRequests/Paths"

const DidInvestigation = lazy(() => import("./NumberRequests/components/DidInvestigation"))
const DidRequests = lazy(() => import("./NumberRequests/DidRequests"))
const DidInvestigations = lazy(() => import("./NumberRequests/DidInvestigations"))
const PortinOrders = lazy(() => import("./PortRequests/PortinOrders"))
const ManagePortinOrder = lazy(() => import("./PortRequests/components/PortOrder/PortOrder"))
const FocChangeRequests = lazy(() => import("./PortRequests/FocChangeRequests"))
const PortingHolidays = lazy(() => import("./PortRequests/Holidays"))

//A Logistics user should have updateDids to be able to see the Customer Dids menu on ToolboxSideBar
//used to control All number requests, Rate centers & DID failures on Toolbox sidebar

//A Logistics user should have updatePortinOrdersElevated to be able to see the Number porting menu on ToolboxSideBar
//Used to control All number ports & Foc change requests on left nav

//The above permissions are assigned to a FUZE_LOGISTICS which is an add-on to FUZE_INTERNAL
//The FUZE_INTERNAL will automatically get the view permissions of the Number Requests and Number Porting data.
//Used to control Holiday Portal on left nav

const buildPlanPath = orgCode => `/alloy/migration/plan/${orgCode}`

const ToolboxRoutes = () => {
  return (
    <>
      <ProtectedToolboxLayoutRoute exact path={emailDetailPath} Component={DidInvestigation} />
      <ProtectedToolboxGlobalLayoutRoute exact path={allDidRequestsPath} Component={DidRequests} />
      <ProtectedToolboxLayoutRoute exact path={didInvestigationsPath} Component={DidInvestigations} />
      <ProtectedToolboxGlobalLayoutRoute exact path={logisticsPortinsPath} Component={PortinOrders} />
      <ProtectedToolboxLayoutRoute exact path={managePortinOrderByLogisticsPath} Component={ManagePortinOrder} />
      <ProtectedToolboxLayoutRoute exact path={managerFocChangeRequestsLogisticsPath} Component={FocChangeRequests} />
      <ProtectedToolboxLayoutRoute exact path={holidayPortalPath} Component={PortingHolidays} />
    </>
  )
}
export { buildPlanPath }
export default ToolboxRoutes
