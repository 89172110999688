import { useState } from "react"
import { useLocation } from "routing"

export default function useSidebarSubmenu(section, matchingPath) {
  const page = useLocation()

  const matchingPathRegex = new RegExp(`^${matchingPath}`)
  const isCurrentPageInSubmenu = matchingPathRegex.test(page.pathname)

  const [openedSubMenu, setOpenedSubMenu] = useState(isCurrentPageInSubmenu && section)
  const toggleSubMenu = subMenuToToggle => {
    if (subMenuToToggle === openedSubMenu) {
      setOpenedSubMenu("")
    } else {
      setOpenedSubMenu(subMenuToToggle)
    }
  }

  return { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu }
}
