import React from "react"
import DebouncedInput from "react-debounce-input"
import classNames from "classnames"
import styles from "./SearchInput.module.scss"
import { IconMagnifyingGlass } from "@fuze/icon-font"

function SearchInput({
  ariaLabel,
  debounceTimeout = 300,
  id,
  minLength = 1,
  placeholder,
  value,
  onChange,
  isExpandable = false,
  isExpanded = false,
  setIsExpanded = f => f,
  autoFocus = false,
  intl
}) {
  function onKeyPress(e) {
    // on press Enter key, don't submit form
    if (e.key === "Enter") {
      return false
    }
  }

  return (
    <div
      className={classNames(
        "search-input-with-icon",
        { [styles.expandableSearch]: isExpandable },
        { [styles.isExpanded]: isExpanded }
      )}
    >
      {isExpandable && (
        <button
          aria-label={intl.formatMessage({ id: "search.toggle" })}
          className={styles.openButton}
          aria-expanded={isExpanded ? true : false}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      )}
      <div className={classNames(styles.searchWrapper)}>
        <IconMagnifyingGlass />
        <DebouncedInput
          id={id}
          className="form-control"
          placeholder={placeholder}
          minLength={minLength}
          debounceTimeout={debounceTimeout}
          onChange={event => onChange(event.target.value)}
          onKeyPress={onKeyPress}
          value={value}
          autoComplete="off"
          aria-label={ariaLabel}
          autoFocus={autoFocus}
        />
      </div>
    </div>
  )
}

const SearchContainer = ({ children, className, ...props }) => (
  <div className={classNames(styles.searchContainer, className)} {...props}>
    {children}
  </div>
)

export default SearchInput
export { SearchContainer }
