export const numberValidationRegex = /^\d{3}-\d{7}$|^(\+\d{1,2})?(\(\d{3}\)){1}(\d{3})-{1}(\d{4})$|^(\+1)?(\(\d{3}\)){1}(\d{3}){1}(\d{4})$|^(\+1)?\d{10,11}$|^(\+1)?(\(\d{3}\)|(\d{3}))\s(\d{3})\s(\d{4})$|^(\+1)?(\(\d{3}\)|(\d{3}))\.(\d{3})\.(\d{4})$|^(\+1)?(\(\d{3}\)|(\d{3}))-(\d{3})-(\d{4})$/

/* The restrictionForNumberValidationRegex is used to matches any character that's not a digit.
It is used to remove special characters in billing and replacement billing telephone numbers */
export const restrictionForNumberValidationRegex = /\D/g

export const middleInitialValidationRegex = /^[a-zA-Z ]{1}$/

export const accountNumberValidationRegex = /^[a-zA-Z0-9]{1,20}$/

export const accountPinValidationRegex = /^[a-zA-Z0-9]{1,15}$/

export const streetNumberValidationRegex = /^[a-zA-Z0-9-]{1,10}$/

// only alphabets, numbers and space will be allowed and length of the input box will be 6
export const numberPrefixValidationRegex = /^[a-zA-Z0-9 ]{1,6}$/
// only alphabets, numbers and space will be allowed and length of the input box will be 45
export const numberSuffixandStreetSuffixValidationRegex = /^[a-zA-Z0-9 ]{1,45}$/

export const cityValidationRegex = /^[a-zA-Z0-9- ]{1,32}$/

// only alphabets, numbers, hiphens and space will be allowed and length of the input box will be 10
export const zipValidationRegex = /^[a-zA-Z0-9- ]{1,10}$/

export const zipPlusValidationRegex = /^\d{4}$/

export const orderIdRegex = /[0-9]+/

/*
 * Hint: If only multi digit and other country codes needed to be checked use the following format on first group
 * checking. Also look at other probable combinations such as +1 (123) 356 7890
 *
 * replace: /^(\+1)? .../
 * with: /^(\+\d{1,2})? ..../ where 2 or 3 are the number of digits in the country code
 *
 * Note:
 *
 * RegEx definitions
 * --------------------
 *
 * Dotted numbers 123.456.7890, +1123.456.7890, +1(123).456.7890, +91(123).456.7890
 *
 * ^(\+\d{1,2})?(\(\d{3}\)|(\d{3}))\.(\d{3})\.(\d{4})$
 *
 * Spaced numbers +1(123) 456 7890, 123 456 7890
 * ^(\+\d{1,2})?(\(\d{3}\)|(\d{3}))\s(\d{3})\s(\d{4})$
 *
 * Hyphenated numbers (123)-456-7890, +1(123)-456-7890
 *
 * ^(\+\d{1,2})?(\(\d{3}\)|(\d{3}))-(\d{3})-(\d{4})$
 *
 * Mixed numbers +1(123)4567890, (123)4567890, (123)456-7890, 123-4567890
 *
 * ^\d{3}-\d{7}$|^(\+\d{1,2})?(\(\d{3}\)){1}(\d{3})-{1}(\d{4})$|^(\+\d{1,2})?(\(\d{3}\)){1}(\d{3}){1}(\d{4})$
 *
 * Both 10 digits and 11 digit numbers 1234567890, +11234567890
 * This also covers toll-free numbers 18006657231
 * If 18 needs to specifically tested then add another regex
 *
 * ^(\+\d{1,2})?\d{10,11}$
 *
 * Constructed RegEx pattern
 *
 * ^\d{3}-\d{7}$|^(\+\d{1,2})?(\(\d{3}\)){1}(\d{3})-{1}(\d{4})$|^(\+1)?(\(\d{3}\)){1}(\d{3}){1}(\d{4})$|^(\+1)?\d{10,11}$|^(\+1)?(\(\d{3}\)|(\d{3}))\s(\d{3})\s(\d{4})$|^(\+1)?(\(\d{3}\)|(\d{3}))\.(\d{3})\.(\d{4})$|^(\+1)?(\(\d{3}\)|(\d{3}))-(\d{3})-(\d{4})$
 */
export const phoneNumberFormatsRegex = /^\d{3}-\d{7}$|^(\+\d{1,2})?(\(\d{3}\)){1}(\d{3})-{1}(\d{4})$|^(\+1)?(\(\d{3}\)){1}(\d{3}){1}(\d{4})$|^(\+1)?\d{10,11}$|^(\+1)?(\(\d{3}\)|(\d{3}))\s(\d{3})\s(\d{4})$|^(\+1)?(\(\d{3}\)|(\d{3}))\.(\d{3})\.(\d{4})$|^(\+1)?(\(\d{3}\)|(\d{3}))-(\d{3})-(\d{4})$/

// Restricted characters in phone numbers
export const restrictedPhoneNumberCharsRegex = /[^0-9\n+()\-\s.]+/gm

// 10 digit number if you want a more complex phone number validation use the 'phoneNumberFormatsRegex'
export const tenDigitNumericCharRegex = /^\d{10}$/

// Email Validation expression
/*
 * This regex has two parts: the part before the @, and the part after the @. There are two alternatives for the part before the @.
 * The first alternative allows it to consist of a series of letters, digits and certain symbols, including one or more dots.
 * The other alternative requires the part before the @ to be enclosed in double quotes, allowing any string of ASCII characters between the quotes. Whitespace characters, double quotes and backslashes must be escaped with backslashes.
 * The part after the @ also has two alternatives. It can either be a fully qualified domain name (e.g. regular-expressions.info), or it can be a literal Internet address between square brackets.
 * The literal Internet address can either be an IP address, or a domain-specific routing address.
 * HINT: Example of Valid Email Address: testemail@xxxxx.com
 * Invalid Email Address: testemail#xxxx-com/testemail#xxxx.com
 */
