import { getManagedCustomers } from "@fuze/apis-mint/dist/partners"
import { getPartnerTenants } from "@fuze/apis-warden/dist/partnerManagement"
import { useAsync } from "react-use"
import { useIsPartnerTenant } from "./useIsPartnerTenant"
import _intersectionWith from "lodash/intersectionWith"
import IDisplayTenant from "../types/IDisplayTenant"

type usePartnerOrganizationsResponse = { value?: IDisplayTenant[]; loading: boolean; error?: Error }
const usePartnerOrganizations = (): usePartnerOrganizationsResponse => {
  const isPartnerTenant = useIsPartnerTenant()
  return useAsync(async () => {
    if (isPartnerTenant) {
      const myManagedCustomers = await getManagedCustomers()
      const myActOnList = await getPartnerTenants()
      const organizationsThatThePartnerIsAllowedToManage = _intersectionWith(
        myManagedCustomers,
        myActOnList.actOn,
        (managedItem, actOnItem) => managedItem.customerCode === actOnItem.tenantKey
      )
      return organizationsThatThePartnerIsAllowedToManage.map(item => ({ ...item, code: item.customerCode }))
    } else {
      return []
    }
  }, [isPartnerTenant])
}

export default usePartnerOrganizations
