import _isEqual from "lodash/isEqual"
import {
  BULK_PHONE_NUMBERS_FILE_TEMPLATE,
  BULK_PHONE_NUMBERS_FILE_UPLOAD,
  PORTIN_HOLIDAYS_FILE_TEMPLATE,
  BULK_PHONE_NUMBERS_FILE_UPLOAD_ERROR
} from "DIDs/PortRequests/data/constants"
import papaParse from "papaparse"
import { readFile, getExtension } from "lib/file-utilities"
import saveAs from "file-saver"

// This method is used to generate and download the sample csv template format to user
export const downloadSampleBulkPhonenumbersTemplate = () => {
  let csv = ""
  BULK_PHONE_NUMBERS_FILE_TEMPLATE.SAMPLE_PHONE_NUMBERS_DATA_WITH_HEADER.map(
    data => (csv = csv.concat(data).concat("\n"))
  )
  saveAs(new Blob([csv]), BULK_PHONE_NUMBERS_FILE_TEMPLATE.SAMPLE_CSV_FILE_NAME, { type: "text/csv" })
}

// This method is used to generate and download the sample holiday csv template format to user
export const downloadSampleBulkHolidaysTemplate = () => {
  let csv = ""
  PORTIN_HOLIDAYS_FILE_TEMPLATE.SAMPLE_HOLIDAY_DATA_WITH_HEADER.map(data => (csv = csv.concat(data).concat("\n")))
  saveAs(new Blob([csv]), PORTIN_HOLIDAYS_FILE_TEMPLATE.SAMPLE_CSV_FILE_NAME, { type: "text/csv" })
}

/**
 *
 * @param {*} file A CSV file with phone number details
 *
 * @return {Promise}
 * On resolve, returns the JSON data of user information, with headers removed
 * Rejects with a type and additional parameters depending on the type
 */
export const extractPhonenumbersFromCSVFile = file => {
  return new Promise((resolve, reject) => {
    const extension = getExtension(file)
    if (isValidExtension(extension)) {
      readFile(file).then(fileContents => {
        const { data, errors } = papaParse.parse(fileContents, { delimiter: "\n", skipEmptyLines: "greedy" })
        if (errors.length === 0) {
          const [firstRow] = data
          if (isValidFileFormat(firstRow)) {
            resolve(data.slice(1))
          } else {
            reject(invalidFileFormat())
          }
        } else {
          reject(parsingError(errors))
        }
      })
    } else {
      reject(invalidExtension(extension))
    }
  })
}

/**
 *
 * @param {*} file A CSV file with holiday details
 *
 * @return {Promise}
 * On resolve, returns the JSON data of user information, with headers removed
 * Rejects with a type and additional parameters depending on the type
 */
export const extractHolidayDatesFromCSVFile = file => {
  return new Promise((resolve, reject) => {
    const extension = getExtension(file)
    if (isValidExtension(extension)) {
      readFile(file).then(fileContents => {
        const { data, errors } = papaParse.parse(fileContents, { delimiter: "\n", skipEmptyLines: "greedy" })
        if (errors.length === 0) {
          resolve(data)
        } else {
          reject(parsingError(errors))
        }
      })
    } else {
      reject(invalidExtension(extension))
    }
  })
}

const isValidExtension = extension => extension && BULK_PHONE_NUMBERS_FILE_UPLOAD.CSV_EXTENSION === extension

const invalidExtension = extension => {
  let validExtension = BULK_PHONE_NUMBERS_FILE_UPLOAD.CSV_EXTENSION
  return {
    type: BULK_PHONE_NUMBERS_FILE_UPLOAD_ERROR.InvalidExtension,
    extension,
    validExtension
  }
}

const parsingError = errors => {
  return { type: BULK_PHONE_NUMBERS_FILE_UPLOAD_ERROR.ParsingError, errors }
}

const isValidFileFormat = headerRow => {
  return _isEqual(headerRow, BULK_PHONE_NUMBERS_FILE_UPLOAD.CSV_HEADERS)
}

const invalidFileFormat = () => {
  return { type: BULK_PHONE_NUMBERS_FILE_UPLOAD_ERROR.InvalidFileFormat }
}
