import { useState } from "react"
import { createUserPreferences, getUserPreferences, updateUserPreferences } from "@fuze/apis-floppy"
import { getUser } from "lib/user-session"
import { IFavouriteTenantCode } from "../types/IFavouriteTenant"
import { xor } from "lodash"
import { useMount } from "react-use"

/**
 * Provide favourite tenant codes per user
 */
export default function useFavourites() {
  const userId = getUser().userId
  const [favouriteTenants, setFavouriteTenants] = useState<IFavouriteTenantCode[]>([])

  // retrieve favourite tenant codes
  const getFavourites = async () => {
    const userPreferences = await getUserPreferences(userId)
    setFavouriteTenants(userPreferences?.results?.[0]?.value?.favouriteTenantCodes || [])
  }

  // update favourite tenants (add if not in the list or remove if the tenant in question was on the list already)
  const updateFavourites = async (tenantCode: IFavouriteTenantCode) => {
    try {
      await updateUserPreferences(getUser().userId, xor(favouriteTenants, [tenantCode]))
    } catch (e) {
      // create a user record if one doesnt exist
      if (e?.response?.status === 404) {
        await createUserPreferences(userId, [tenantCode])
      }
    }

    await getFavourites() // refresh the list
  }

  useMount(getFavourites)

  return { favouriteTenants, getFavourites, updateFavourites }
}
