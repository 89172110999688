import React, { useEffect, useState } from "react"
import { useIntl, FormattedMessage } from "react-intl"
import ToggleSection from "./ToggleSection"
import useAllTenants from "../hooks/useAllTenants"
import InfiniteScroll from "react-infinite-scroll-component"
import { sortBy } from "lodash"
import Tenant from "./Tenant"
import { Spinner } from "reactstrap"
import { IFavouriteTenantCode } from "../types/IFavouriteTenant"
import IDisplayTenant from "../types/IDisplayTenant"

type AllCustomersProps = {
  query: string
  favouriteTenants: IFavouriteTenantCode[]
  updateFavourites: Function
}
const AllCustomers: React.FunctionComponent<AllCustomersProps> = ({ query, favouriteTenants, updateFavourites }) => {
  const { tenants, pagination, loadMore } = useAllTenants(query)
  const [isOpen, setOpen] = useState(false)
  useEffect(() => {
    if (!!query) {
      setOpen(true)
    }
  }, [query])

  const tenantsWithFavourites: IDisplayTenant[] = tenants.map(tenant => ({
    ...tenant,
    isFavourite: favouriteTenants.includes(tenant.code)
  }))

  const intl = useIntl()
  const toggleOpen = () => setOpen(prev => !prev)
  return (
    <ToggleSection
      title={intl.formatMessage({ id: "tenantSwitcher.allCustomers" })}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
    >
      <ul id={"tenant-switcher--dropdown-menu--all-customers"} className={"tenant-switcher--dropdown-menu--section"}>
        <InfiniteScroll
          dataLength={tenantsWithFavourites?.length}
          next={loadMore}
          hasMore={tenantsWithFavourites?.length < (pagination?.total ?? 0)}
          loader={<Spinner />}
          pullDownToRefresh={false}
          scrollableTarget="tenant-switcher--dropdown-menu--all-customers"
        >
          {sortBy(tenantsWithFavourites, "name")?.map(tenant => (
            <Tenant tenant={tenant} key={tenant.code} updateFavourites={updateFavourites} />
          ))}
        </InfiniteScroll>
        {!tenantsWithFavourites.length && (
          <span className={"tenant-switcher__no-match"}>
            <FormattedMessage id="tenantSwitcher.noMatch" />
          </span>
        )}
      </ul>
    </ToggleSection>
  )
}

export default AllCustomers
