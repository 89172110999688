/**
 * Changes focus to an element with a certain ID
 *
 * @param {string} id A string ID (ex. "main-content")
 *
 * @return null
 */
function changeFocusToElementWithId(id) {
  document.getElementById(id)?.focus()
}

export { changeFocusToElementWithId }
