import ExternalPathnames from "constants/external-pathnames"
import { FormattedMessage } from "react-intl"
import useConfiguration from "hooks/useConfiguration"
import useIsHubActive, { useIsToolboxActive } from "../hooks/useIsHubActive"
import classnames from "classnames"
import { useProtectedLinkSection } from "routing"

const ExternalLinks = () => {
  const { portal } = useConfiguration()
  const isHubActive = useIsHubActive()
  const isToolboxActive = useIsToolboxActive()
  const [HomeSection, HomeLink] = useProtectedLinkSection("/")
  const [ToolboxSection, ToolboxLink] = useProtectedLinkSection("/dids/logistics/investigations")

  return (
    <ul className="top-nav-bar--links">
      <HomeSection>
        <li>
          <HomeLink
            className={classnames({
              "header-navigation-button": !isHubActive,
              "header-navigation-button--active": isHubActive
            })}
            id="navigation-hub"
          >
            <FormattedMessage id="navigation.hub" />
          </HomeLink>
        </li>
      </HomeSection>
      <li>
        <a href={portal.url} className={"header-navigation-button"}>
          <FormattedMessage id="navigation.portal" />
        </a>
      </li>
      <li>
        <a href={ExternalPathnames.DATA_FUZE} className={"header-navigation-button"}>
          <FormattedMessage id="navigation.data" />
        </a>
      </li>
      <li>
        <a href={ExternalPathnames.STATUS_THINKINGPHONES} className={"header-navigation-button"}>
          <FormattedMessage id="navigation.status" />
        </a>
      </li>
      <ToolboxSection>
        <li>
          <ToolboxLink
            className={classnames({
              "header-navigation-button": !isToolboxActive,
              "header-navigation-button--active": isToolboxActive
            })}
            id="navigation-logistics"
          >
            <FormattedMessage id="navigation.logistics" />
          </ToolboxLink>
        </li>
      </ToolboxSection>
    </ul>
  )
}

export default ExternalLinks
