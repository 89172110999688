/**
 * Default text splitter function for chunking data for the <NumberPortingTagInput /> component.
 * If a chunk with invalid char is found that chunk is ignored. Further, empty
 * tags are not pruned as react-tagsinput does the pruning and is redundant here.
 *
 * @param {RegExp} invalidChars RegExp of invalid characters
 */
export const defaultTagSplitter = invalidChars => data =>
  data.split("\n").map(datum => {
    const value = datum.replace(invalidChars, "")?.replace(/\s+/g, " ").trim()
    return value
  })

/**
 * Return a tag style after validating the phone numbers
 *
 * @param {Function} validatorFunction A function that runs the validation
 * @param {RegExp} validateRegex A regex for the validation
 *
 * @Returns {string | null} Returns null or error class if true or false respectively
 */
export const getStyleSelector = (validatorFunction, validateRegex) => tag =>
  validatorFunction([tag], validateRegex) ? null : "react-tagsinput-tag danger"
