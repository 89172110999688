import _getProducts from "@fuze/apis-foundry/dist/products/functions/getProducts"
import _searchProducts from "@fuze/apis-foundry/dist/products/functions/searchProducts"
import _getProductsByLocation from "@fuze/apis-foundry/dist/products/functions/getProductsByLocation"

import _flow from "lodash/flow"
import memoizeOne from "memoize-one"
import { wrapAllResultsNoOrganization } from "./decorators"

export const getProducts = (catalog, productTypes) => wrapAllResultsNoOrganization(_getProducts)(catalog, productTypes)
export const searchProducts = _flow(wrapAllResultsNoOrganization, memoizeOne)(_searchProducts)
export const getProductsByLocation = _flow(wrapAllResultsNoOrganization, memoizeOne)(_getProductsByLocation)
