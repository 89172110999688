import { useCallback, useEffect, useState } from "react"
import { createGlobalState, useAsyncFn } from "react-use"
import { getActionRequiredLocationTotalCount, getAllActionRequiredLocations } from "../../data/locations"

export const useGlobalActionReqTotalCount = createGlobalState(0)

function useActionRequiredLocationsTotalCount() {
  const [totalCount, setTotalCount] = useGlobalActionReqTotalCount()
  const [loading, setLoading] = useState(false)

  const getTotalCount = useCallback(async () => {
    setLoading(true)
    const result = await getActionRequiredLocationTotalCount()
    setTotalCount(result)
    setLoading(false)
  }, [setTotalCount])

  useEffect(() => {
    getTotalCount()
  }, [getTotalCount])

  return { totalCount, setTotalCount, loading, refreshTotalCount: getTotalCount }
}

export function useAllActionRequiredLocations() {
  return useAsyncFn(getAllActionRequiredLocations)
}

export default useActionRequiredLocationsTotalCount
