import { resolveDependencies, DependenciesResolverBuilder } from "./resolveDependencies"

/**
 * Given a root query and a list of resolver lookup objects,
 * combine the root entities with the dependencies
 *
 * A passed-in resolver is an object with a resolve function and a reduce function. The
 * resolve function is responsible for reading the necessary data from the root entities in order
 * to query for the dependent entities. The reduce function is responsible for taking the
 * data returned from the dependency query and combining that with the root entities. The reason that
 * these two functions are split up is for some optimization in that we can often make a lot
 * of the network calls in parallel for dependencies
 *
 * For example, suppose that I wanted a list of services with extensions and users
 *
 * I would start by making a call to search services (based on service ids, for instance)
 * Then I would pass in dependencies for users and extensions
 *
 * The extensions resolver would have a resolve function that would grab all of the services
 * and then return a promise with the list of extensions that are relevant for those services. It would
 * then have a reduce function that would take a list of services and extensions and combine the
 * extension object into the service object
 *
 * const services = await queryEntities(() => searchSevices({ ids }), extensions(), users())
 *
 * where extensions() might be defined like this:
 *
 * const extensions = services => {
 *   return {
 *     resolve: (services) => {
 *       const serviceIds = services.map(service => service.id)
 *       return searchExtensions({ serviceIds })
 *     },
 *     reduce: (services, extensions) => {
 *       const extensionMap = _keyBy(extensions, "service.id")
 *       return services.map(service => ({
 *         ..service,
 *         extension: extensionMap[service.id]
 *       }))
 *     }
 *   }
 *
 * }
 *
 * @param {function} entityQuery
 * @param  {...any} resolvers
 */
export async function queryEntities(entityQuery, ...resolvers) {
  // Do the initial query
  const entities = await entityQuery()

  return resolveDependencies(entities, ...resolvers)
}

/**
 * This is a convenience class that makes the queries a little nice to read/work with
 *
 * The above example could be rewritten like:
 *
 * const services = await new QueryBuilder()
 *   .with(extensions())
 *   .with(users())
 *   .execute(() => searchServices({ ids }))
 */
export class QueryBuilder extends DependenciesResolverBuilder {
  // Execute the function, passing the necessary information to queryEntities
  async execute(queryFn) {
    return await queryEntities(queryFn, ...this.dependencies)
  }
}

/**
 * One more piece that I find makes using the library easier to read
 *
 * const services = await buildQuery()
 *   .with(extensions())
 *   .with(users())
 *   .execute(() => searchServices({ ids }))
 *
 * You could also write it as
 *
 * const services = await buildQuery()
 *   .with(extensions(), users())
 *   .execute(() => searchServices({ ids }))
 *
 * @param {function} query
 */
export const buildQuery = () => new QueryBuilder()
