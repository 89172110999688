import { withRouter } from "routing"
import { logInfo } from "./logging"
import * as appcues from "../appcues"
import { Component } from "react"
import { isDevEnv } from "lib/user-session"

const pageViewTitle = "HUB page view"
function logPageView(location) {
  if (isDevEnv) {
    console.log(pageViewTitle, location.pathname)
  } else {
    logInfo(pageViewTitle, { path: location.pathname })
    appcues.logPageView()
  }
}

/**
 * A component that is included by the root component that connects to the router library to
 * log changes to the route
 */
class PageViews extends Component {
  render() {
    const location = this.props.history.location
    logPageView(location)
    return null
  }
}

export default withRouter(PageViews)
