import { getOrganization } from "@fuze/apis-foundry/dist/organizations"

import { register } from "lib/appcues"
import { identify, track } from "data/analytics"
import { getUser } from "@fuze/services-auth/dist/user-session"

export async function identifyUser() {
  // TODO Differentiate between a user logging in and refreshing the page
  const organizationData = await getOrganization(getUser().tenantKey)
  await track("Logged in")
  identify(getUser())
  register(getUser(), organizationData)
}
