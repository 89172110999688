import getConfiguration from "../../configuration"
import { getToken } from "lib/token"
import { decorateWardenWithPagination } from "../pagination"
import { url } from "./decorators"
import { bearerToken, organization, extractData, extractResults } from "../utils"

// TODO Don't do this here
import _getOptions from "@fuze/apis-warden/dist/authentication/functions/getOptions"

import _getRoles from "@fuze/apis-warden/dist/rolesAndPermissions/functions/getRoles"
import _getUsersWithRoles from "@fuze/apis-warden/dist/rolesAndPermissions/functions/getUsersWithRoles"
import _updateUserRole from "@fuze/apis-warden/dist/rolesAndPermissions/functions/updateUserRole"
import _getTenants, { targetTypes } from "@fuze/apis-warden/dist/rolesAndPermissions/functions/getTenants"
import _deleteUserRole from "@fuze/apis-warden/dist/rolesAndPermissions/functions/deleteUserRole"
import _updateManagedLocations from "@fuze/apis-warden/dist/rolesAndPermissions/functions/updateManagedLocations"
import _deleteManagedLocations from "@fuze/apis-warden/dist/rolesAndPermissions/functions/deleteManagedLocations"
import _updateManagedBy from "@fuze/apis-warden/dist/rolesAndPermissions/functions/updateManagedBy"
import _getAllRoles from "@fuze/apis-warden/dist/rolesAndPermissions/functions/getAllRoles"
import _getUser from "@fuze/apis-warden/dist/rolesAndPermissions/functions/getUser"
import _getUsers from "@fuze/apis-warden/dist/rolesAndPermissions/functions/getUsers"
import _deleteManagedBy from "@fuze/apis-warden/dist/rolesAndPermissions/functions/deleteManagedBy"

import { errorInterceptor } from "data/apis/errorInterceptor"

import _chunk from "lodash/chunk"
import _flatten from "lodash/flatten"
import _flow from "lodash/flow"

import memoizeOne from "memoize-one"

const defaultParameters = _flow(errorInterceptor, url, bearerToken)
const defaultOrganizationParameters = _flow(defaultParameters, organization)

const getRoles = organization =>
  _flow(defaultParameters, decorateWardenWithPagination, extractResults)(_getRoles)(organization, true)

const getAllRoles = _flow(memoizeOne, defaultParameters, extractResults)(_getAllRoles)
const getUsersWithRoles = roles =>
  _flow(defaultOrganizationParameters, decorateWardenWithPagination, extractResults)(_getUsersWithRoles)(roles, false)

const limitedResults = fn => decorateWardenWithPagination(fn, 1000, 100)
const tenantTypes = [targetTypes.operator, targetTypes.partner, targetTypes.reseller]
const getManagedTenants = () =>
  _flow(memoizeOne, defaultParameters, limitedResults, extractResults)(_getTenants)(tenantTypes)

const updateUserRole = (userId, roleId) =>
  _flow(defaultOrganizationParameters, extractData)(_updateUserRole)(userId, roleId)
const updateManagedBy = (userId, managedByKey) =>
  _flow(defaultParameters, extractData)(_updateManagedBy)(userId, managedByKey)
const updateManagedLocations = (userId, managedLocations) =>
  _flow(defaultParameters, extractData)(_updateManagedLocations)(userId, managedLocations)
const deleteManagedLocations = userId => _flow(defaultParameters, extractData)(_deleteManagedLocations)(userId)
const deleteManagedBy = userId => _flow(defaultParameters, extractData)(_deleteManagedBy)(userId)
const deleteUserRole = (userId, roleId) =>
  _flow(defaultOrganizationParameters, extractData)(_deleteUserRole)(userId, roleId)

const getUsers = userIds => _flow(defaultParameters, extractData)(_getUsers)(userIds)

// TODO Remove the options call from this
async function getUser(userId) {
  const { warden } = await getConfiguration()
  const token = getToken()

  const { data } = await _getUser(warden.url, token, userId)
  // TODO Separate this out of the getWardenUser call
  const { data: authOptions } = await _getOptions(warden.url, warden.appKey, userId)
  // combine warden user data and auth options
  return { ...data, ...authOptions }
}

async function getChunkedUsers(userIds) {
  if (userIds.length) {
    const chunkedIds = _chunk(userIds, 50)
    const requests = chunkedIds.map(getUsers)

    const responses = await Promise.all(requests)
    return _flatten(responses)
  } else {
    return []
  }
}

const memoizedGetAllRoles = memoizeOne(getAllRoles)
const memoizedGetManagedTenants = memoizeOne(getManagedTenants)

export {
  getRoles,
  memoizedGetAllRoles as getAllRoles,
  getUsersWithRoles,
  updateUserRole,
  memoizedGetManagedTenants as getManagedTenants,
  updateManagedBy,
  deleteManagedBy,
  updateManagedLocations,
  deleteManagedLocations,
  getUser,
  getChunkedUsers as getUsers,
  deleteUserRole
}
