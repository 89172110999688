import { searchOrganizationsPage } from "data/apis/foundry"
import { useAsyncRetry } from "react-use"
import { getPartnerTenantsByOrg } from "@fuze/apis-warden"
import _flatten from "lodash/flatten"
import _unionBy from "lodash/unionBy"
import { useIsOperatorTenant } from "./useIsOperatorTenant"

/**
 * Get a list of organizations according to query and pagination,
 * then for every reseller on the batch get their act on list and modify customers of those resellers/partners to account for the managing organization
 */
function useOrganizationsWithResellers(query, paginationParams) {
  const isOperatorTenant = useIsOperatorTenant()
  const { value: organizations, loading, error, retry: loadMore } = useAsyncRetry(async () => {
    if (isOperatorTenant) {
      const organizations = await searchOrganizationsPage(query, paginationParams)
      const resellers = organizations.data.filter(organization => organization.type === "reseller")
      const resellersMap = (
        await Promise.all(
          resellers.map(async reseller => {
            const { actOn: actOnList } = await getPartnerTenantsByOrg(reseller.code)
            return { reseller, actOnList }
          })
        )
      ).filter(item => item.actOnList.length)

      const managedOrganizations = _flatten(
        resellersMap.map(reseller => {
          const resellerManagedCodes = reseller.actOnList.map(item => item.tenantKey)

          //managed organizations by this reseller
          return organizations.data
            .filter(organization => resellerManagedCodes.includes(organization.code))
            .map(organization => ({ ...organization, managedByPartner: reseller.reseller }))
        })
      )

      return { ...organizations, data: _unionBy(managedOrganizations, organizations.data, "code") }
    } else {
      return null
    }
  }, [query, isOperatorTenant])

  return { organizations, loading, error, loadMore }
}

export default useOrganizationsWithResellers
