import getConfiguration from "../configuration"
import { getToken } from "lib/token"
import { getOrganization as getActingOrganization } from "lib/user-session"
import _getContractedProducts from "@fuze/apis-mint/dist/products/functions/getContractedProducts"
import _getServicePrices from "@fuze/apis-mint/dist/services/functions/getServicePrices"
import _getMintLocation from "@fuze/apis-mint/dist/locations/functions/getMintLocation"
import { errorInterceptor } from "data/apis/errorInterceptor"
import _flow from "lodash/flow"
import memoizeOne from "memoize-one"

function wrapMintFunction(fn) {
  return async function (...args) {
    const { mint } = await getConfiguration()
    const token = getToken()

    const { data } = await fn(mint.url, token, ...args)
    return data
  }
}

function decorateWithOrganization(fn) {
  return async function (...args) {
    const organization = getActingOrganization()

    return await fn(organization, ...args)
  }
}

const getContractedProducts = organization =>
  _flow(errorInterceptor, wrapMintFunction, memoizeOne)(_getContractedProducts)(organization)
const getServicePrices = _flow(
  errorInterceptor,
  decorateWithOrganization,
  memoizeOne
)(wrapMintFunction(_getServicePrices))

const getContractedProductsMemoized = memoizeOne(getContractedProducts)

const getMintLocation = _flow(errorInterceptor, wrapMintFunction, memoizeOne)(_getMintLocation)

export { getServicePrices, getContractedProductsMemoized as getContractedProducts, getMintLocation }
