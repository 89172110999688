import React, { useRef, useEffect, useState } from "react"

const HubSidebarSubmenuItem = ({ children, itemAnimationSpeed, isSubmenuOpen, onClick, zIndex }) => {
  const ref = useRef()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const newHeight = ref?.current?.getBoundingClientRect().height || 0

    if (height !== newHeight) {
      setHeight(newHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <li
      onClick={onClick}
      ref={ref}
      style={{
        transition: itemAnimationSpeed,
        marginTop: isSubmenuOpen ? 0 : `-${height}px`,
        zIndex
      }}
    >
      {children}
    </li>
  )
}

export default HubSidebarSubmenuItem
