import React from "react"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import { hasSomePermission } from "lib/permissions"
import { rateCenterPath } from "./Paths"
import { useAccess } from "components/permissions/Access"

import permissions from "constants/permissions"

const requiredPermissions = [permissions.dids.viewDidRateCenters, permissions.dids.updateDidRateCenters]
const updateRateCenterPermissions = [permissions.dids.updateDidRateCenters]

export const useRateCenterAccess = () => useAccess(requiredPermissions, rateCenterPath)

export const RateCenterAccess = ({ children }) => (
  <>
    <PermissionsProtected requiredPermissions={requiredPermissions} permissionsCheck={hasSomePermission}>
      {children(rateCenterPath)}
    </PermissionsProtected>
  </>
)

export const RateCenterUploadAccess = ({ children }) => (
  <>
    <PermissionsProtected requiredPermissions={updateRateCenterPermissions}>{children}</PermissionsProtected>
  </>
)
