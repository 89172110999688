import React from "react"
import { PORTIN_PROCESSING_STATUS } from "../data/constants"
import styles from "./icons.module.scss"
import classNames from "classnames"

export const getProcessingIconByStatus = status => {
  switch (status) {
    case PORTIN_PROCESSING_STATUS.PROCESSING:
      return <i className={classNames("icon-arrow-right", styles.processingIcon)} />
    case PORTIN_PROCESSING_STATUS.COMPLETED:
      return <i className={classNames("icon-checkmark successIcon", styles.successIcon)} />
    case PORTIN_PROCESSING_STATUS.FAILED:
      return <i className={classNames("icon-alert failureIcon", styles.failureIcon)} />
    default:
      return ""
  }
}
