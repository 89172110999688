import React from "react"
import { useAsync } from "react-use"
import { IntlProvider } from "react-intl"
import initializeTranslations from "lib/translations"

import PageSpinner from "components/spinner/PageSpinner"

export default function TranslationProvider({ children }) {
  const { value: translations, loading } = useAsync(async () => {
    const translations = await initializeTranslations()
    return translations
  })

  if (loading) {
    return <PageSpinner />
  }

  return (
    <IntlProvider locale={translations.locale} messages={translations.messages}>
      {children}
    </IntlProvider>
  )
}
