/**
 * This is the utility file for all menu action code related to work
 */

import {
  ACTION,
  PORTIN_PROCESSING_STATUS,
  DATE_CONSTANTS,
  ALLOWED_BULK_ACTIONS_MENU_ITEMS_BY_STATUS,
  PORTIN_ORDER_STATUS
} from "DIDs/PortRequests/data/constants"

import { getCurrentDate, getMomentDateFromString, dateToEST, getBusinessDaysInBetween } from "./calendarUtility"

/**
 * Returns all possible actions for a given status
 * refer to ALLOWED_ACTIONS_MENU_ITEMS_BY_STATUS in constants.js
 * @param {number} status
 *
 * @returns {[]|undefined} an array or undefined
 *
 */
export const getBulkActionsByStatus = status => ALLOWED_BULK_ACTIONS_MENU_ITEMS_BY_STATUS[status]

/**
 * Gets he supported action keys. As some keys may not have bulk actions.
 * Todo: Add all supported keys here. Currently only DELETE, CANCEL is here as
 * Only those keys are dynamically mapped.
 *
 * @returns {[]}
 */
export const getSupportedActions = () => [
  ACTION.CANCEL,
  ACTION.DELETE,
  ACTION.SUBMIT,
  ACTION.SCHEDULE_ACTIVATION_DATE,
  ACTION.ADDITIONAL_RECIPIENTS
]

/**
 * Check if the action is supported
 *
 * @param {string} action ACTION enum check if the action is supported
 */
export const isSupportedAction = action => getSupportedActions().includes(action)

/**
 * This is a helper method so when used in multiple places there is less confusion.
 * Think of it as a interface.
 *
 * @param {string} action Action object
 * @param {} payload An payload of selected rows
 * @param {object} options Any extra potions
 */
export const createMenuAction = (action, payload = null, options = {}) => ({ action, payload, options })

/**
 * Prepare the order[] to be processed when menu actions are selected
 *
 * @param {object[]} orders
 */
export const getOrderDetails = orders => {
  return orders?.map(({ orderId, carrierDisplayName, rateCenters, phoneNumberType, requestedQuantity, ...rest }) => ({
    orderId,
    rateCenter: `${carrierDisplayName}  ${rateCenters ? "/" + rateCenters : ""}`,
    type: phoneNumberType,
    count: requestedQuantity ?? 0,
    processingStatus: PORTIN_PROCESSING_STATUS.OPEN,
    ...rest
  }))
}

/**
 * Cancellations are only allowed if the currentDate is less than the MIN_SLA_FOR_ORDER_CANCELLATION in
 * business days.
 *
 * @param {object} order The order to be validated
 *
 * @returns {boolean}
 */
export const getIsCancellable = order => {
  if (order?.activation?.actualFocDate) {
    const daysLeftToPortNumbers = getBusinessDaysInBetween(
      dateToEST(getMomentDateFromString(order.activation.actualFocDate)),
      dateToEST(getCurrentDate())
    )
    //Ports can be canelled only if the Firm Order Committment date is > DATE_CONSTANTS.MIN_SLA_FOR_ORDER_CANCELLATION
    //which is 2 days or if for what ever reason the Port did not happen and the current date crossed the FOC date
    return daysLeftToPortNumbers > DATE_CONSTANTS.MIN_SLA_FOR_ORDER_CANCELLATION || daysLeftToPortNumbers < 0
  } else {
    //If no FOC date, orders can be cancelled at any time
    return true
  }
}

/**
 * This function is used to check the portability error is because of duplicate phone numbers.
 * @param {string} errorCode its coming from API and its value will be like "numberPorting.checkPortability.phonenumber.duplicates"
 */
export const getIsDuplicateNumber = errorCode => errorCode?.split(".").pop() === "duplicates"

/**
 * This function is used to return the total duplicate phone numbers count
 * @param {Array} phoneNumbers duplicate phone numbers
 */
export const getDuplicateCount = phoneNumbers => {
  return (
    phoneNumbers
      // Here we are iterating the phoneNumbers array and checking how many times that numbers is duplicated and
      // totally how many duplicate numbers are in entered phone numbers.
      .map(number =>
        // Here we are splitting the number based on space(" ") and getting the last element of the array and removing
        // the first and last character of the string to get the duplicated count
        // Eg: If number = "5202387775 (94)"
        // then number.split(" ") => ["5202387775", "(94)"]
        // then number.split(" ")?.pop() => "(94)"
        // then number.split(" ")?.pop()?.slice(1, -1) => "94"
        Number(number.split(" ")?.pop()?.slice(1, -1))
      )
      // Adding all duplicate count from initial to end and return total count
      .reduce((initialDuplicateCount, lastDuplicateCount) => initialDuplicateCount + lastDuplicateCount)
  )
}

/**
 * Given an action and value the function will use validators
 * to determine if action is valid to be executed. This is
 * used in the bucket sorting pruning function.
 *
 * @param {string} action @see ACTION object
 * @param {object} value An order object
 */
export const isActionValid = (action, value) => {
  let isValid

  const {
    orderStatus,
    activation: { actualFocDate }
  } = value

  /**
   * Actions may be valid for display, but not valid for
   * execution
   */

  switch (action) {
    case ACTION.CANCEL:
      isValid = getIsCancellable(value)
      break
    default:
      isValid = true
  }

  // Do not allow chages to an order which has received firm order commitmen
  if (
    orderStatus === PORTIN_ORDER_STATUS.REQUESTED_SUPP.statusCode ||
    orderStatus === PORTIN_ORDER_STATUS.EXCEPTION.statusCode
  ) {
    isValid = isValid && !actualFocDate
  }

  return isValid
}
