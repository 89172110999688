export const Table = Object.freeze({
  ROWS_PER_PAGE: 50,
  PAGINATION_SIZE: 6
})

export const Email = Object.freeze({
  FILE_EXTENSTION: "eml",
  TEXT_AREA_ROWS: 5,
  TEXT_AREA_COLS: 80
})

export const Did = Object.freeze({
  TEXT_AREA_ROWS: 6,
  TEXT_AREA_COLS: 160
})

export const EmailResolveStatus = Object.freeze({
  LINK_TO_REQUEST: 100,
  IGNORE_EMAIL: 101
})

export const TimeConstants = Object.freeze({
  COUNT_REFRESH_INTERVAL: 60000
})

export const DID_REQUEST_STATUS_CATEGORIES = Object.freeze({
  IN_PROGRESS: "inprogress",
  COMPLETED: "completed",
  REJECTED: "rejected",
  ERROR_CORRECTION: "errorcorrection",
  ALL: "all"
})

export const DID_REQUEST_STATUS_VALUES = Object.freeze({
  REQUEST_RECEIVED: "received",
  SUBMITTED_TO_CARRIER: "submitted",
  CARRIER_ACKNOWLEDGED: "acknowledged",
  PARTIALLY_COMPLETED: "partial",
  COMPLETED: "completed",
  FORCE_COMPLETED: "forcecompleted",
  CUSTOMER_CANCELLED: "cancelled",
  REJECTED: "rejected",
  PARTIALLY_BACKORDERED: "partiallybackordered",
  BACKORDERED: "backordered"
})

export const DID_REQUEST_STATUS = Object.freeze({
  REQUEST_RECEIVED: {
    statusCode: 0,
    category: DID_REQUEST_STATUS_CATEGORIES.IN_PROGRESS,
    value: DID_REQUEST_STATUS_VALUES.REQUEST_RECEIVED
  },
  SUBMITTED_TO_CARRIER: {
    statusCode: 1,
    category: DID_REQUEST_STATUS_CATEGORIES.IN_PROGRESS,
    value: DID_REQUEST_STATUS_VALUES.SUBMITTED_TO_CARRIER
  },
  CARRIER_ACKNOWLEDGED: {
    statusCode: 2,
    category: DID_REQUEST_STATUS_CATEGORIES.IN_PROGRESS,
    value: DID_REQUEST_STATUS_VALUES.CARRIER_ACKNOWLEDGED
  },
  REJECTED: {
    statusCode: 3,
    category: DID_REQUEST_STATUS_CATEGORIES.REJECTED,
    value: DID_REQUEST_STATUS_VALUES.REJECTED
  },
  PARTIALLY_COMPLETED: {
    statusCode: 4,
    category: DID_REQUEST_STATUS_CATEGORIES.IN_PROGRESS,
    value: DID_REQUEST_STATUS_VALUES.PARTIALLY_COMPLETED
  },
  CUSTOMER_CANCELLED: {
    statusCode: 5,
    category: DID_REQUEST_STATUS_CATEGORIES.COMPLETED,
    value: DID_REQUEST_STATUS_VALUES.CUSTOMER_CANCELLED
  },
  COMPLETED: {
    statusCode: 6,
    category: DID_REQUEST_STATUS_CATEGORIES.COMPLETED,
    value: DID_REQUEST_STATUS_VALUES.COMPLETED
  },
  LARGE_ORDER_REQUEST_RECEIVED: {
    statusCode: 7,
    category: DID_REQUEST_STATUS_CATEGORIES.IN_PROGRESS,
    value: DID_REQUEST_STATUS_VALUES.REQUEST_RECEIVED
  },
  FORCE_COMPLETED: {
    statusCode: 8,
    category: DID_REQUEST_STATUS_CATEGORIES.COMPLETED,
    value: DID_REQUEST_STATUS_VALUES.FORCE_COMPLETED
  },
  PARTIALLY_BACKORDERED: {
    statusCode: 9,
    category: DID_REQUEST_STATUS_CATEGORIES.IN_PROGRESS,
    value: DID_REQUEST_STATUS_VALUES.PARTIALLY_BACKORDERED
  },
  BACKORDERED: {
    statusCode: 10,
    category: DID_REQUEST_STATUS_CATEGORIES.IN_PROGRESS,
    value: DID_REQUEST_STATUS_VALUES.BACKORDERED
  }
})

export const DID_ALLOWED_REQUEST_RESOLUTIONS = Object.freeze({
  REQUEST_RECEIVED: [
    DID_REQUEST_STATUS.CARRIER_ACKNOWLEDGED.statusCode,
    DID_REQUEST_STATUS.COMPLETED.statusCode,
    DID_REQUEST_STATUS.REJECTED.statusCode,
    DID_REQUEST_STATUS.FORCE_COMPLETED.statusCode
  ],
  SUBMITTED_TO_CARRIER: [
    DID_REQUEST_STATUS.CARRIER_ACKNOWLEDGED.statusCode,
    DID_REQUEST_STATUS.COMPLETED.statusCode,
    DID_REQUEST_STATUS.REJECTED.statusCode,
    DID_REQUEST_STATUS.FORCE_COMPLETED.statusCode
  ],
  CARRIER_ACKNOWLEDGED: [
    DID_REQUEST_STATUS.COMPLETED.statusCode,
    DID_REQUEST_STATUS.REJECTED.statusCode,
    DID_REQUEST_STATUS.BACKORDERED.statusCode,
    DID_REQUEST_STATUS.FORCE_COMPLETED.statusCode
  ],
  REJECTED: [],
  PARTIALLY_COMPLETED: [
    DID_REQUEST_STATUS.COMPLETED.statusCode,
    DID_REQUEST_STATUS.BACKORDERED.statusCode,
    DID_REQUEST_STATUS.FORCE_COMPLETED.statusCode
  ],
  FORCE_COMPLETED: [],
  COMPLETED: [],
  LARGE_ORDER_REQUEST_RECEIVED: [
    DID_REQUEST_STATUS.CARRIER_ACKNOWLEDGED.statusCode,
    DID_REQUEST_STATUS.COMPLETED.statusCode,
    DID_REQUEST_STATUS.REJECTED.statusCode,
    DID_REQUEST_STATUS.FORCE_COMPLETED.statusCode
  ],
  PARTIALLY_BACKORDERED: [DID_REQUEST_STATUS.COMPLETED.statusCode, DID_REQUEST_STATUS.FORCE_COMPLETED.statusCode],
  BACKORDERED: [
    DID_REQUEST_STATUS.CARRIER_ACKNOWLEDGED.statusCode,
    DID_REQUEST_STATUS.COMPLETED.statusCode,
    DID_REQUEST_STATUS.REJECTED.statusCode,
    DID_REQUEST_STATUS.FORCE_COMPLETED.statusCode
  ]
})

export const DID_REQUEST_ERROR_STATUS_CATEGORY_CODES = Object.freeze({
  ERRORED: "2",
  UNRECOGNIZED: "3",
  SPAM: "4",
  IGNORED: "5",
  CODES: ["2", "3", "4", "5"]
})

export const DID_EMAIL_PROCESSED_TYPES = Object.freeze({
  NEW: { code: 0, value: "new" },
  PROCESSED: { code: 1, value: "processed" },
  ERRORED: { code: 2, value: "errored" },
  UNRECOGNIZED: { code: 3, value: "unrecognized" },
  SPAM: { code: 4, value: "spam" },
  IGNORED: { code: 5, value: "ignored" }
})
