import _flow from "lodash/flow"
import memoize from "memoize-one"

import _searchUsers from "@fuze/apis-foundry/dist/users/functions/searchUsers"
import _getUsers from "@fuze/apis-foundry/dist/users/functions/getUsers"
import _updateUser from "@fuze/apis-foundry/dist/users/functions/updateUser"
import _deleteUser from "@fuze/apis-foundry/dist/users/functions/deleteUser"
import _getUser from "@fuze/apis-foundry/dist/users/functions/getUser"
import _validateWelcomeEmails from "@fuze/apis-foundry/dist/users/functions/validateWelcomeEmails"
import _sendWelcomeEmails from "@fuze/apis-foundry/dist/users/functions/sendWelcomeEmails"
import _validateUsername from "@fuze/apis-foundry/dist/users/functions/validateUsername"
import _setRecordingConfiguration from "@fuze/apis-foundry/dist/users/functions/setRecordingConfiguration"
import _getRecordingConfiguration from "@fuze/apis-foundry/dist/users/functions/getRecordingConfiguration"
import _createUser from "@fuze/apis-foundry/dist/users/functions/createUser"

import {
  wrapFoundryFunction,
  wrapFoundryFunctionWithOrganization,
  wrapFoundryFunctionWithOrganizationWithPagination,
  wrapAllResults,
  wrapAllResultsWithIds,
  wrapAllResultsWithIdsAnyOrganization
} from "./decorators"
import { cached } from "../utils"

export const getUsersByPage = (filters, pageSize, page = 1) => {
  const offset = (page - 1) * pageSize
  return wrapFoundryFunctionWithOrganizationWithPagination(_searchUsers)(filters, { offset, limit: pageSize })
}

export const getUsersWithFilters = filters => {
  return wrapAllResults(_searchUsers)(filters)
}

export const getUsers = q => wrapFoundryFunctionWithOrganization(_getUsers)(q)
export const getUsersByIds = _flow(wrapAllResultsWithIds, cached)(_searchUsers)
export const getUsersByIdsAnyOrganization = _flow(wrapAllResultsWithIdsAnyOrganization)(_searchUsers)
export const deleteUser = userId => wrapFoundryFunction(_deleteUser)(userId)
export const getUser = wrapFoundryFunction(_getUser)
export const searchUsers = q => wrapAllResults(_searchUsers)({ q, active: true })
export const searchUsersByUsername = usernames => wrapAllResults(_searchUsers)({ usernames })
export const searchUsersByQueryWithoutPagination = q => wrapAllResults(_searchUsers)(q)
export const searchUsersByQuery = q => wrapFoundryFunctionWithOrganizationWithPagination(_searchUsers)(q)
export const updateUser = wrapFoundryFunction(_updateUser)
export const validateWelcomeEmails = userIds => wrapFoundryFunction(_validateWelcomeEmails)(userIds)
export const sendWelcomeEmails = userIds => wrapFoundryFunction(_sendWelcomeEmails)(userIds)
// Note the wrapping of the entire function (including the userName). Without this, each call of this function
// will return a new memoized function and will therefore not be cached at all
export const validateUsername = memoize(userName => wrapFoundryFunction(_validateUsername)(userName))
export const getRecordingConfiguration = userId => wrapFoundryFunction(_getRecordingConfiguration)(userId)
export const setRecordingConfiguration = (unitOfWorkToken, userId, configuration) =>
  wrapFoundryFunction(_setRecordingConfiguration)(unitOfWorkToken, userId, configuration)
export const createUser = userProperties => wrapFoundryFunction(_createUser)(userProperties)
