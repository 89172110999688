import _getCurrencyByLocation from "@fuze/apis-foundry/dist/currency/functions/getCurrencyByLocation"
import _searchCurrenciesByLocations from "@fuze/apis-foundry/dist/currency/functions/searchCurrenciesByLocations"

import { wrapFoundryFunction } from "./decorators"
import _flow from "lodash/flow"
import memoizeOne from "memoize-one"

const getCurrencyByLocation = _flow(wrapFoundryFunction, memoizeOne)(_getCurrencyByLocation)
const searchCurrenciesByLocations = _flow(wrapFoundryFunction, memoizeOne)(_searchCurrenciesByLocations)

export { getCurrencyByLocation as getCurrencyByLocationFoundry, searchCurrenciesByLocations }
