import { buildQuery } from "./query/queryEntities"
import { products, locations, departments, extensions, users, dids, prices } from "./service-resolvers"

import { searchServices, getUsersByIds, searchExtensions } from "./apis/foundry"
import _groupBy from "lodash/groupBy"
import _drop from "lodash/drop"
import _concat from "lodash/concat"
import { ProductCategory } from "./ProductCategory"

const getService = async serviceId => {
  const [service] = await getServices([serviceId])
  return service
}

/**
 * Given a list of service ids, retrieve:
 * - the service objects
 * - the extension objects as applicable
 * - the user objects as applicable
 * @param {} serviceIds
 */
const getServices = async serviceIds => {
  const usersWithDepartmentsAndLocations = async userIds => {
    return await buildQuery()
      .with(locations())
      .with(departments())
      .execute(() => getUsersByIds(userIds))
  }

  return await buildQuery()
    .with(locations())
    .with(departments())
    .with(users(usersWithDepartmentsAndLocations))
    .with(extensions())
    .execute(() => searchServices({ ids: serviceIds }))
}

const getServicesForUser = async userId => {
  return await getServicesForUsers([userId])
}

const getServicesWithoutPricingForUser = async userId => {
  return await getServicesWithoutPricingForUsers([userId])
}

const getServicesForUsers = async userIds => {
  return await buildQuery()
    .with(products())
    .with(prices())
    .execute(() => searchServices({ users: userIds }))
}

const getServicesWithoutPricingForUsers = async userIds => {
  return await buildQuery()
    .with(products())
    .execute(() => searchServices({ users: userIds }))
}

const getServicesWithExtensionsForUser = userId => getServicesWithExtensionsForUsers([userId])

const getServicesWithExtensionsForUsers = async userIds => {
  const extensionsWithDIDs = async serviceIds => {
    return await buildQuery()
      .with(dids())
      .execute(() => searchExtensions(serviceIds))
  }

  return await buildQuery()
    .with(extensions(extensionsWithDIDs))
    .with(products())
    .execute(() => searchServices({ users: userIds }))
}

const getServicesWithExtensionsLocationsDepartmentsForUser = userId =>
  getServicesWithExtensionsLocationsDepartmentsForUsers([userId])

const getServicesWithExtensionsLocationsDepartmentsForUsers = async userIds => {
  const extensionsWithDIDs = async serviceIds => {
    return await buildQuery()
      .with(dids())
      .execute(() => searchExtensions(serviceIds))
  }

  return await buildQuery()
    .with(locations())
    .with(departments())
    .with(extensions(extensionsWithDIDs))
    .with(products())
    .execute(() => searchServices({ users: userIds }))
}

function getServiceWithProductAndExtension(serviceId) {
  return buildQuery()
    .with(extensions())
    .with(products())
    .execute(() => getService(serviceId))
}

// This is to return an array of services that does NOT include the 1st service
function dropFirstService(services = []) {
  return _drop(services, 1)
}
/**
 * Categories services based on product type (and in some cases SKU)
 *
 * @param {*} services A list of services (with product populated)
 *
 * Assumes that each service has an attached product
 */
function categorizeServices(services = []) {
  const serviceMap = _groupBy(services, "product.category")

  const voiceServices = serviceMap[ProductCategory.voice]
  const videoServices = serviceMap[ProductCategory.video]
  const faxServices = serviceMap[ProductCategory.fax]
  const didServices = serviceMap[ProductCategory.did]
  const discoverServices = serviceMap[ProductCategory.discover]
  const recordingServices = serviceMap[ProductCategory.recording]
  const contactCenterServices = serviceMap[ProductCategory.contactCenter]
  const smsServices = serviceMap[ProductCategory.sms]

  // This is to find the services that are not in the HUB enabled list (Floppy)
  const unsupportedServices = serviceMap[undefined]

  // Which services should show up in additional services:
  //
  // Any voice service other than the user's first voice service
  // Any video service other than the user's first video service
  // Any fax service other than the user's first fax service
  // Any call recording service other than the user's first call recording service
  // Any contact center service other than the user's first contact center service
  // Any discover service other than the user's first discover service
  // Any sms service other than the user's first sms service
  // Any other service

  const additionalServices = _concat(
    dropFirstService(voiceServices),
    dropFirstService(videoServices),
    dropFirstService(faxServices),
    dropFirstService(discoverServices),
    dropFirstService(recordingServices),
    dropFirstService(contactCenterServices),
    dropFirstService(smsServices),
    unsupportedServices ? unsupportedServices : []
  )

  return {
    voiceServices: voiceServices ? voiceServices : [],
    videoServices: videoServices ? videoServices : [],
    faxServices: faxServices ? faxServices : [],
    didServices: didServices ? didServices : [],
    discoverServices: discoverServices ? discoverServices : [],
    recordingServices: recordingServices ? recordingServices : [],
    contactCenterServices: contactCenterServices ? contactCenterServices : [],
    smsServices: smsServices ? smsServices : [],
    additionalServices: additionalServices ? additionalServices : []
  }
}

// Please note: "legacy" is only labeled for the services of tenants
// that is at Alloy Migration state and it is an Alloy customer but
// some of its legacy services haven't been migrated to Alloy yet.
const isLegacyService = service => service.product?.legacy

export {
  categorizeServices,
  getService,
  getServices,
  getServicesForUser,
  getServicesForUsers,
  getServicesWithoutPricingForUser,
  getServicesWithExtensionsForUser,
  getServicesWithExtensionsForUsers,
  getServicesWithExtensionsLocationsDepartmentsForUser,
  getServicesWithExtensionsLocationsDepartmentsForUsers,
  getServiceWithProductAndExtension,
  isLegacyService
}
