import React, { useState } from "react"
import { injectIntl } from "react-intl"
import { useInterval } from "react-use"
import _isEqual from "lodash/isEqual"
import { toastr } from "components/toastr"
import getCachedVersion, { getUncachedVersion } from "data/version"

const PRIMARY_INTERVAL = 10 * 60 * 1000 // 10 minutes
const SECONDARY_INTERVAL = 2 * 60 * 60 * 1000 // 2 hours

function reload() {
  window.location.reload()
}

function VersionUpdater({ intl }) {
  const [interval, setInterval] = useState(PRIMARY_INTERVAL)

  useInterval(async () => {
    const cachedVersionPromise = getCachedVersion()
    const uncachedVersionPromise = getUncachedVersion()

    const [cachedVersion, uncachedVersion] = await Promise.all([cachedVersionPromise, uncachedVersionPromise])

    if (!_isEqual(cachedVersion, uncachedVersion)) {
      toastr.info(
        intl.formatMessage({ id: "version.update.title" }),
        <p>{intl.formatMessage({ id: "version.update.description" })}</p>,
        {
          onClick: reload
        }
      )
      setInterval(SECONDARY_INTERVAL)
    }
  }, interval)

  return null
}

export default injectIntl(VersionUpdater)
