import { getUser } from "lib/user-session"

/**
 * Note that this isn't exactly like useUser but with the logged in user.
 * It also has warden data (which includes permissions)
 */
export default function useCurrentUser() {
  const user = getUser()

  return user
}
