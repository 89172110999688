import React, { Suspense } from "react"
import BaseLayout from "./BaseLayout"
import ToolboxSidebar from "./components/ToolboxSidebar"
import TopMenuLayout from "components/layouts/TopMenuLayout"
import { ProtectedRoute } from "@fuze/hub-authorization"
import PageSpinner from "components/spinner/PageSpinner"

/**
 * The ToolboxLayout is an extension of the BasicHubLayout that adds a CompanyName header (with link to CompanySettings)
 * and the Hub Sidebar
 */
const ToolboxLayout = ({ children }) => (
  <BaseLayout>
    <TopMenuLayout />
    <div className="content-layout">
      <div className="col-sidebar">
        <ToolboxSidebar />
      </div>
      <div id="main-content" className="col-page-content" tabIndex="-1">
        {children}
      </div>
    </div>
  </BaseLayout>
)

function ProtectedToolboxLayoutRoute({ children, Component, ...props }) {
  return (
    <ProtectedRoute
      {...props}
      render={routeProps => (
        <ToolboxLayout>
          <Suspense fallback={<PageSpinner />}>
            <Component {...routeProps} />
          </Suspense>
        </ToolboxLayout>
      )}
    />
  )
}

/**
 * Some of the Virtusa routes have a "global" view
 */
function ProtectedToolboxGlobalLayoutRoute({ children, Component, ...props }) {
  return (
    <ProtectedRoute
      {...props}
      render={routeProps => (
        <ToolboxLayout>
          <Suspense fallback={<PageSpinner />}>
            <Component globalView {...routeProps} />
          </Suspense>
        </ToolboxLayout>
      )}
    />
  )
}

export default ToolboxLayout
export { ProtectedToolboxLayoutRoute, ProtectedToolboxGlobalLayoutRoute }
