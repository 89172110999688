export const DISPLAY_ACTION_CONSTANTS = Object.freeze({
  CANCEL: "Cancel",
  DELETE: "Delete",
  CANCELLING: "Cancelling",
  DELETING: "Deleting",
  APPROVING: "Approving",
  REJECTING: "Rejecting"
})

export const PORTIN_ACTION_STATUS = Object.freeze({
  OPEN: 0,
  APPROVED: 1,
  REJECTED: 2,
  CANCELLED: 3
})

/**
 * Constants in Toolbox > DID porting > FOC change request
 * section
 */
export const FOC_CHANGE_REQUEST_USER_ACTIONS = Object.freeze({
  RESPOND: "respond"
})

export const FOC_CHANGE_REQUEST_TABLE_PROPS = Object.freeze({
  ROWS_PER_PAGE: 50
})

/**
 * Map data from the response
 */
export const FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING = Object.freeze({
  CREATED_DATE: "createdDate",
  ORGANIZATION_NAME: "organizationName",
  ORDER_ID: "externalOrderId",
  REQUEST_ORDER_ID: "portinExternalRequestId",
  RATE_CENTERS: "rateCenters",
  REQUESTED_QTY: "requestedQuantity",
  CUR_ACTIVATION_DATE: "actualFocDate",
  REQ_ACTIVATION_DATE: "newValue",
  ACTION_STATUS: "actionStatus",
  MODIFIED_DATE: "modifiedDate"
})

/**
 * Field name to key mapping
 */
export const FOC_CHANGE_REQUEST_TABLE_SORT_FIELD_MAPPING = Object.freeze({
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.CREATED_DATE]: "C_D",
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.ORGANIZATION_NAME]: "O_N",
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.ORDER_ID]: "P_O_I",
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.REQUEST_ORDER_ID]: "R_I",
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.RATE_CENTERS]: "R_C",
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.REQUESTED_QTY]: "R_Q",
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.CUR_ACTIVATION_DATE]: "A_F_C",
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.REQ_ACTIVATION_DATE]: "N_V",
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.ACTION_STATUS]: "A_S",
  [FOC_CHANGE_REQUEST_TABLE_FIELD_MAPPING.MODIFIED_DATE]: "M_D"
})

export const FOC_CHANGE_REQUEST_STATUS_MAPPING = Object.freeze({
  [PORTIN_ACTION_STATUS.OPEN]: "open",
  [PORTIN_ACTION_STATUS.APPROVED]: "approved",
  [PORTIN_ACTION_STATUS.REJECTED]: "rejected",
  [PORTIN_ACTION_STATUS.CANCELLED]: "cancelled"
})

export const FOC_CHANGE_REQUEST_TABLE_BUTTON_BY_STATUS = Object.freeze({
  [PORTIN_ACTION_STATUS.OPEN]: {
    id: "respond",
    action: FOC_CHANGE_REQUEST_USER_ACTIONS.RESPOND
  }
})

export const FOC_CHANGE_REQUEST_MODAL_NOTE_FIELD_ROWS = 5
export const FOC_CHANGE_REQUEST_MODAL_NOTE_FIELD_MAX_CHAR_COUNT = 256

export const PORTIN_ACTION_PAGINATION = Object.freeze({
  OFFSET_ZERO: 0,
  LIMIT_MAX: 25,
  PAGINATION_SIZE: 6
})
