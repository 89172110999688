const ExternalPathnames = Object.freeze({
  FUZE: "https://www.fuze.com",
  PORTAL_THINKINGPHONES: "https://portal.thinkingphones.com",
  STATUS_THINKINGPHONES: "https://status.fuze.com",
  DATA_FUZE: "https://data.fuze.com",
  LEARN_MORE: "https://fuzecommunity.force.com/support/s/article/Administrator-Guide-Fuze-Guests",
  SUPPORT: "https://fuzecommunity.force.com/support",
  TERMS_AND_CONDITIONS: "http://response.fuze.com/thehubtermsandconditions"
})

export default ExternalPathnames
