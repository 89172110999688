import React, { FunctionComponent } from "react"
import { useIntl } from "react-intl"
import classNames from "classnames"
import { Button } from "reactstrap"
import { useIsOperatorTenant } from "../hooks/useIsOperatorTenant"
import { useIsPartnerTenant } from "../hooks/useIsPartnerTenant"
import { logJavascriptError } from "lib/logging/logging"
import {
  getOrganization as getActingOrganization,
  getOriginalImpersonatorOrganization,
  getUser
} from "lib/user-session"
import { buildHomePath } from "Dashboard/Access"
import { useHistory } from "react-router"
import { getImpersonationToken } from "@fuze/apis-warden"
import TenantStatus from "./TenantStatus"
import { setImpersonation, deleteImpersonation } from "@fuze/services-auth/dist/token"
import IDisplayTenant from "../types/IDisplayTenant"
import { useIsImpersonating } from "../hooks/useIsImpersonating"

type TenantProps = {
  tenant: IDisplayTenant
  updateFavourites: Function
}
const Tenant: FunctionComponent<TenantProps> = ({ tenant, updateFavourites }) => {
  const isOperatorTenant = useIsOperatorTenant()
  const isPartnerTenant = useIsPartnerTenant()
  const isImpersonating = useIsImpersonating()
  const history = useHistory()
  const intl = useIntl()

  async function onTenantChange(tenantCode: string) {
    if (isOperatorTenant) {
      const homePath = buildHomePath(`?organization=${tenantCode}`)
      history.push(homePath)
    } else if (isPartnerTenant && !isImpersonating) {
      const impersonationToken = await getImpersonationToken(tenantCode)
      setImpersonation(impersonationToken)
      history.push(buildHomePath())
    } else if (isPartnerTenant && isImpersonating) {
      deleteImpersonation()
      if (tenant.code !== getOriginalImpersonatorOrganization()) {
        const impersonationToken = await getImpersonationToken(tenantCode)
        setImpersonation(impersonationToken)
      }
      history.push(buildHomePath())
    } else {
      logJavascriptError("User is trying to change tenant but is neither an operator nor a partner", {
        user: getUser(),
        organization: getActingOrganization()
      })
    }
  }

  return (
    <li className={"tenant"}>
      <Button
        color="transparent"
        onClick={() => onTenantChange(tenant.code)}
        className={"tenant__left"}
        aria-label={intl.formatMessage({ id: "tenantSwitcher.switchToCustomer" }, { customerName: tenant.name })}
      >
        <span className={"tenant__name"}>{tenant.name}</span>
        <TenantStatus tenant={tenant} />
      </Button>
      <Button
        color="transparent"
        className={classNames({
          "favorite-tenant-button--active": tenant.isFavourite,
          "favorite-tenant-button": !tenant.isFavourite
        })}
        onClick={() => updateFavourites(tenant.code)}
        aria-label={intl.formatMessage(
          { id: `tenantSwitcher.${tenant.isFavourite ? "removeFromFavourites" : "addToFavourites"}` },
          { customerName: tenant.name }
        )}
      >
        <i
          className={classNames({
            "icon-favorite-full": tenant.isFavourite,
            "icon-favorite-empty": !tenant.isFavourite
          })}
        />
      </Button>
    </li>
  )
}

export default Tenant
