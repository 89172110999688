import CacheStore from "./CacheStore"

const defaultMaximumCacheSize = 1000
const defaultKeyExtractor = x => x
const defaultValueExtractor = x => x.id
const defaultKeyComparator = (x, y) => x === y

export default function createCache(
  keyExtractor = defaultKeyExtractor,
  valueExtractor = defaultValueExtractor,
  keyComparator = defaultKeyComparator,
  maximumCacheSize = defaultMaximumCacheSize
) {
  return new CacheStore(keyExtractor, valueExtractor, keyComparator, maximumCacheSize)
}
