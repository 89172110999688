import React from "react"
import { FormattedMessage } from "react-intl"
import useSupportURL from "components/support/useSupportURL"

export default function ContactSupportInline(verbalizeSupport = false) {
  const supportURL = useSupportURL()
  const id = verbalizeSupport ? "errors.contactingSupportInline" : "errors.contactSupportInline"
  return (
    <a href={supportURL} target="_blank" rel="noopener noreferrer">
      <FormattedMessage id={id} />
    </a>
  )
}
