import React from "react"
import classnames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"
import { InfoButton } from "components/buttons/InfoButton"
import { getFullUserName, getInitialsFromFullName } from "lib/string-utilities"
import { Image, ImageContent, ImageRow } from "./Image"
import { Picture } from "@fuze/apis-contactive/dist/contacts/models/Origins"

type AvatarProps = {
  icon?: string
  avatar?: Picture
  initials?: string
  className?: string
  classes?: string
  active?: boolean
  isReadonly?: boolean
  userId?: string
}
const Avatar = ({
  icon,
  avatar,
  initials = "",
  className,
  classes = "",
  active = true,
  isReadonly = false,
  userId
}: AvatarProps) => {
  const { formatMessage } = useIntl()
  const classNames = classnames("avatar", className, classes, { inactive: !active })
  const arialLabelId = isReadonly ? "avatarAria.avatarReadOnly" : "avatarAria.avatar"

  return (
    <div className={classNames} aria-label={formatMessage({ id: arialLabelId })} role="img">
      <div className="avatar__overlay">
        <div className="avatar__initials">{initials}</div>
      </div>
      {displayContent()}
      {isReadonly && (
        <InfoButton
          anchor={
            <div id={`readonly-${userId}`} className="avatar__overlay avatar__overlay--locked">
              <i className="icon-lock avatar__icon" />
            </div>
          }
          id={`readonly-${userId}`}
        >
          <FormattedMessage id={"users.readonly"} />
        </InfoButton>
      )}
    </div>
  )

  function displayContent() {
    if (avatar?.smallUrl) {
      return (
        <div
          className="avatar__overlay avatar__overlay--image"
          style={{
            backgroundImage: `url(${avatar.smallUrl})`
          }}
        />
      )
    } else if (icon) {
      return (
        <div className="avatar__overlay avatar__overlay--icon">
          <i className={classnames("avatar__icon", icon)} />
        </div>
      )
    }

    return null
  }
}

export function AvatarWithName({ children, className, icon, user, ...props }) {
  if (!user) return null

  const fullName = user?.displayName || getFullUserName(user)
  const initials = getInitialsFromFullName(fullName)

  return (
    <ImageRow className={classnames(className, "avatar-with-name-container")}>
      <Image>
        <Avatar
          avatar={user.avatar}
          className="avatar--with-name"
          icon={!initials && icon}
          initials={initials}
          {...props}
        />
      </Image>
      <ImageContent>
        <div className="username">{fullName}</div>
        {children && <div className="user__additional-details">{children}</div>}
      </ImageContent>
    </ImageRow>
  )
}

export default Avatar
