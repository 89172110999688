import getConfiguration from "../configuration"
import _getEnabledProducts from "@fuze/apis-floppy/dist/products/functions/getEnabledProducts"
import _getAlloyProducts from "@fuze/apis-floppy/dist/products/functions/getAlloyProducts"
import _flatten from "lodash/flatten"
import { getToken } from "lib/token"
import memoizeOne from "memoize-one"
import _flow from "lodash/flow"
import { errorInterceptor } from "data/apis/errorInterceptor"
import { isOrganizationAlloy } from "data/apis/foundry/organizations"

async function getFloppyDataV1() {
  const { floppy } = await getConfiguration()
  const token = getToken()
  const url = `${floppy.url}/api`

  const { data } = await _getEnabledProducts(url, token)
  return data && data.value
}

async function getFloppyDataV2() {
  const { floppy } = await getConfiguration()
  const token = getToken()
  const url = `${floppy.url}/api`

  const { data } = await _getAlloyProducts(url, token)
  return data && data.value
}

const memoizedFloppyV1 = memoizeOne(getFloppyDataV1)
const memoizedFloppyV2 = memoizeOne(getFloppyDataV2)

async function getEnabledProducts(categories) {
  const isAlloy = await isOrganizationAlloy()
  let results
  if (isAlloy) {
    results = await memoizedFloppyV2()
  } else {
    results = await memoizedFloppyV1()
  }
  // filter results by category if an array of categories is provided, otherwise return all data
  return _flatten(
    categories && categories.length > 0
      ? categories.map(category => results.filter(product => product.category === category))
      : results
  )
}

async function getEnabledUCProducts() {
  let categories
  const isAlloyCustomer = await isOrganizationAlloy()

  if (isAlloyCustomer) {
    categories = ["voice"]
  } else {
    categories = ["voice", "video"]
  }
  const products = await getEnabledProducts(categories)
  return products
}

const getEnabledProductsHandled = _flow(errorInterceptor)(getEnabledProducts)

async function getNonAlloyEnabledProducts() {
  return await memoizedFloppyV1()
}

export { getEnabledProductsHandled as getEnabledProducts, getEnabledUCProducts, getNonAlloyEnabledProducts }
