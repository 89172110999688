import { useState, useEffect } from "react"
import { getOrganization as getActingOrganization } from "lib/user-session"
import { getEnablements } from "data/apis/foundry"
import { useAsync } from "react-use"

export default async function isEnabled(feature) {
  const organization = getActingOrganization()
  const enablements = await getEnablements(organization)

  return enablements.some(enablement => enablement.name === feature && enablement.enabled)
}

export const NUMBER_PORTING = "did-port-requests"
export const DEVELOPER = "developer-api"
export const LOCATION_ADD = "location-add"
export const CONTACT_ROSTERS = "contact-rosters"
export const STAR_RATINGS = "star-ratings"
export const LOCATION_REGISTER = "location-psap-registration"
export const CONTACT_CENTER_CHAT = "contact-center-chat"
export const ACT_ON_LIST = "partner-management-act-on-list"
export const VOICE_ADMINISTRATION = "voice-administration"

/**
 * Given a feature, retrieve whether the feature is enabled for the organization being acted on
 *
 * @param {String} feature
 * @param {String} organization
 */
export function useEnablements(feature) {
  const [featureEnabledForOrganization, setFeatureEnabledForOrganization] = useState(false)
  useEffect(() => {
    async function retrieveEnablements() {
      const featureIsEnabled = await isEnabled(feature)
      setFeatureEnabledForOrganization(featureIsEnabled)
    }
    retrieveEnablements()
  }, [feature])

  return featureEnabledForOrganization
}

export function useOrganizationEnablements() {
  const { value: enablements = [] } = useAsync(() => {
    const organization = getActingOrganization()
    return getEnablements(organization)
  })

  return enablements.reduce((accum, { name, enabled }) => (enabled ? [...accum, name] : accum), [])
}
