import { useHasPermissions } from "components/permissions/usePermissions"
import permissions from "constants/permissions"
import { ACT_ON_LIST, useEnablements } from "data/enablements"
import { useIsImpersonating } from "components/layouts/TopNavBar/hooks/useIsImpersonating"

function useIsAllowedToManageOthers() {
  const isAllowedToSwitchCustomer = useHasPermissions([permissions.users.switchCustomer])
  const isAllowedToImpersonate = useHasPermissions([
    permissions.tenantManagement.impersonateOwnTenant,
    permissions.tenantManagement.impersonateAnyTenant
  ])
  const isEnabled = useEnablements(ACT_ON_LIST)
  const isImpersonating = useIsImpersonating()

  return isAllowedToSwitchCustomer || (isAllowedToImpersonate && isEnabled) || isImpersonating
}

function searchOrganizations(query, orgs) {
  return orgs.filter(org => org.name.toLowerCase().indexOf(query) !== -1)
}

export { useIsAllowedToManageOthers, searchOrganizations }
