import { useMemo } from "react"
import { MAX_PAGE_SIZE_PAGINATION } from "data/apis/foundry/constants"
import { IFavouriteTenant, IFavouriteTenantCode } from "../types/IFavouriteTenant"
import useSearchOrganizationsWithResellers from "./useSearchOrganizationsWithResellers"

type useFavouriteCustomersProps = {
  favouriteCustomers: IFavouriteTenant[]
  loading: boolean
}

/**
 * Get all full Organization objects for favourite tenants
 * @param favouriteTenants
 */
function useFavouriteCustomers(favouriteTenants: IFavouriteTenantCode[]): useFavouriteCustomersProps {
  const pagination = useMemo(() => ({ offset: 0, limit: MAX_PAGE_SIZE_PAGINATION }), [])
  const { organizations, loading } = useSearchOrganizationsWithResellers(favouriteTenants, pagination)
  const favouriteCustomers = organizations?.map(organization => ({ ...organization, isFavourite: true })) || []

  return { favouriteCustomers, loading }
}

export default useFavouriteCustomers
