import { flattenMessages } from "./locale-utils" // will convert nested JSON notation to dot.notation
import moment from "moment"
import "moment/locale/fr"
import { shouldPolyfill } from "@formatjs/intl-pluralrules/should-polyfill"
async function polyfill(locale) {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import("@formatjs/intl-pluralrules/polyfill")
  }

  if (Intl.PluralRules.polyfilled) {
    switch (locale) {
      default:
        await import("@formatjs/intl-pluralrules/locale-data/en")
        break
      case "fr":
        await import("@formatjs/intl-pluralrules/locale-data/fr")
        break
    }
  }
}

async function getProductMessages(locale) {
  await polyfill(locale)
  if (locale === "fr") {
    await import("@formatjs/intl-pluralrules/locale-data/fr")
    return await import("../locale/fr-FR.json")
  }
  if (locale === "eo") {
    return await import("../locale/en-pseudo.json")
  }
  await import("@formatjs/intl-pluralrules/locale-data/en")
  return await import("../locale/en-US.json")
}

const validLocales = [
  { code: "en", pattern: /en.*/, default: true },
  { code: "fr", pattern: /fr.*/ },
  { code: "eo", pattern: /eo.*/ }
]

function findMatchingLocale() {
  const localeCandidates = (navigator.languages || []).concat(navigator.language).concat(navigator.userLanguage)

  const [locale] = localeCandidates
    .map(candidate => {
      const [validLocale] = validLocales.filter(locale => locale.pattern.test(candidate))
      return validLocale
    })
    .filter(locale => !!locale)
    .map(locale => locale.code)

  if (locale) {
    return locale
  }

  const [defaultLocale] = validLocales.filter(locale => locale.default)
  return defaultLocale.code
}

export default async function initializeTranslations() {
  const locale = findMatchingLocale()

  moment.locale(locale)

  const messages = await getProductMessages(locale)
  const flattenedMessages = await flattenMessages(messages)

  return {
    locale,
    messages: flattenedMessages
  }
}

export { findMatchingLocale }
