import { PORTIN_ORDER_STATUS_VALUES, PORTIN_ORDER_STATUS } from "./constants"
import { PORTIN_ACTION_STATUS } from "./portinActionConstants"
import _isEqual from "lodash/isEqual"
import {
  portOrdersReadyToSubmitPath,
  portOrdersInProgressPath,
  portOrdersArchivePath,
  portOrdersErrorsPath
} from "DIDs/PortRequests/Paths"

export const getDefaultOrderStatus = () => {
  return [
    PORTIN_ORDER_STATUS_VALUES.DRAFT,
    PORTIN_ORDER_STATUS_VALUES.READY_FOR_SUBMISSION,
    PORTIN_ORDER_STATUS_VALUES.SUBMITTED,
    PORTIN_ORDER_STATUS_VALUES.REQUESTED_SUPP,
    PORTIN_ORDER_STATUS_VALUES.EXCEPTION,
    PORTIN_ORDER_STATUS_VALUES.CANCELLED,
    PORTIN_ORDER_STATUS_VALUES.REQUESTED_CANCEL,
    PORTIN_ORDER_STATUS_VALUES.FOC,
    PORTIN_ORDER_STATUS_VALUES.COMPLETED
  ]
}

export const getDefaultStatusesForToolbox = () => {
  return [
    PORTIN_ORDER_STATUS_VALUES.SUBMITTED,
    PORTIN_ORDER_STATUS_VALUES.REQUESTED_SUPP,
    PORTIN_ORDER_STATUS_VALUES.EXCEPTION,
    PORTIN_ORDER_STATUS_VALUES.CANCELLED,
    PORTIN_ORDER_STATUS_VALUES.REQUESTED_CANCEL,
    PORTIN_ORDER_STATUS_VALUES.FOC,
    PORTIN_ORDER_STATUS_VALUES.COMPLETED
  ]
}
/**
 * Get default filters when navigating to Toolbox > DID Porting > FOC change request
 */
export const getDefaultFocChangeRequestStatusesForToolbox = () => {
  return [
    PORTIN_ACTION_STATUS.OPEN,
    PORTIN_ACTION_STATUS.APPROVED,
    PORTIN_ACTION_STATUS.REJECTED,
    PORTIN_ACTION_STATUS.CANCELLED
  ]
}

export const getStatusCodesForStatuses = statuses => {
  let statusCodes = []
  statuses && statuses.forEach(status => (statusCodes = statusCodes.concat(getStatusCodesForStatus(status))))
  return statusCodes
}

export const getStatusCodesForStatus = status => {
  const statusCodes = Object.keys(PORTIN_ORDER_STATUS)
    .filter(portinStatus => status === PORTIN_ORDER_STATUS[portinStatus].value)
    .map(portinStatus => PORTIN_ORDER_STATUS[portinStatus].statusCode)
  return statusCodes
}

export const getStatusesByCategory = statusCategory => {
  const statuses = Object.keys(PORTIN_ORDER_STATUS)
    .filter(portinStatusCode => statusCategory === PORTIN_ORDER_STATUS[portinStatusCode].category)
    .map(portinStatusCode => PORTIN_ORDER_STATUS[portinStatusCode].statusCode)
  return statuses
}

export const getDefaultNeedsInputOrderStatus = () => {
  return [PORTIN_ORDER_STATUS_VALUES.DRAFT]
}

export const getDefaultReadyToSubmitOrderStatus = () => {
  return [PORTIN_ORDER_STATUS_VALUES.READY_FOR_SUBMISSION]
}

export const getErrorsStatus = () => {
  return [PORTIN_ORDER_STATUS_VALUES.PRE_SUBMIT_ERRORS, PORTIN_ORDER_STATUS_VALUES.EXCEPTION]
}

export const getDefaultInProgressOrderStatus = () => {
  return [
    PORTIN_ORDER_STATUS_VALUES.SUBMITTED,
    PORTIN_ORDER_STATUS_VALUES.REQUESTED_SUPP,
    PORTIN_ORDER_STATUS_VALUES.REQUESTED_CANCEL,
    PORTIN_ORDER_STATUS_VALUES.FOC
  ]
}

export const getDefaultArchivedOrderStatus = () => {
  return [PORTIN_ORDER_STATUS_VALUES.CANCELLED, PORTIN_ORDER_STATUS_VALUES.COMPLETED]
}

/**
 * Get status by route path location
 * Based on the location path, returns allowed port order statuses
 */
export const getDefaultOrderStatusByCategory = path => {
  let statusCodes = []
  if (_isEqual(path, portOrdersReadyToSubmitPath)) {
    statusCodes = getDefaultReadyToSubmitOrderStatus()
  } else if (_isEqual(path, portOrdersErrorsPath)) {
    statusCodes = getErrorsStatus()
  } else if (_isEqual(path, portOrdersInProgressPath)) {
    statusCodes = getDefaultInProgressOrderStatus()
  } else if (_isEqual(path, portOrdersArchivePath)) {
    statusCodes = getDefaultArchivedOrderStatus()
  } else {
    statusCodes = getDefaultNeedsInputOrderStatus()
  }
  return statusCodes
}
