import _validateServices from "@fuze/apis-foundry/dist/services/functions/validateServices"
import _provisionServices from "@fuze/apis-foundry/dist/services/functions/provisionServices"
import _updateServices from "@fuze/apis-foundry/dist/services/functions/updateServices"
import _validateUpdateServices from "@fuze/apis-foundry/dist/services/functions/validateUpdateServices"
import _validateDeprovisioningServices from "@fuze/apis-foundry/dist/services/functions/validateDeprovisioningServices"
import _deprovisionServices from "@fuze/apis-foundry/dist/services/functions/deprovisionServices"
import _getExpandedJob from "@fuze/apis-foundry/dist/jobs/functions/getExpandedJob"
import _searchJobs from "@fuze/apis-foundry/dist/jobs/functions/searchJobs"

import { wrapFoundryFunction, wrapFoundryFunctionWithoutOrganizationPaginated } from "./decorators"

export const getExpandedJob = wrapFoundryFunction(_getExpandedJob)
export const searchJobs = uowIds =>
  wrapFoundryFunctionWithoutOrganizationPaginated(_searchJobs)({ unitOfWorkIds: uowIds })
export const validateServices = wrapFoundryFunction(_validateServices)
export const provisionServices = wrapFoundryFunction(_provisionServices)
export const updateServices = wrapFoundryFunction(_updateServices)
export const validateUpdateServices = wrapFoundryFunction(_validateUpdateServices)
export const validateDeprovisioningServices = wrapFoundryFunction(_validateDeprovisioningServices)
export const deprovisionServices = wrapFoundryFunction(_deprovisionServices)
