import { registerUrlProvider, registerBearerTokenProvider, registerErrorHandlerProvider } from "@fuze/apis-contactive"

import getConfiguration from "data/configuration"
import { getToken } from "lib/token"
import { logApiError } from "lib/logging/logging"

async function errorInterceptor(e, fn, args) {
  console.error(e)
  // as Warden or JWT token is always 2nd in the args list, we want to hide it and not to expose it
  // in Splunk log.
  args[1] = "token"
  await logApiError("Intercepted by errorInterceptor", e, { api: fn, arguments: args })
  return []
}
export default function registerApiProviders() {
  registerUrlProvider(() => getConfiguration().then(data => `${data.vortex.url}/contactive/v1.0`))
  registerBearerTokenProvider(async () => getToken())
  registerErrorHandlerProvider(async () => errorInterceptor)
}
