import React from "react"
import { useEnablements } from "data/enablements"
import { getUser } from "lib/user-session"

export const EnablementAccess = ({ enablementFlag, children, fallback = <></> }) => {
  const isEnabled = useEnablements(enablementFlag)
  return isEnabled ? children : fallback
}

export const NotManagedAccess = ({ children, fallback = <></> }) => {
  const user = getUser()
  return user?.managedBy === user?.organization?.code ? children : fallback
}

export const enablements = {
  regional: "regional-management"
}
