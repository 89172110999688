import React from "react"
import { useIntl } from "react-intl"
import SearchInput from "components/SearchInput"

const SearchCustomers = ({ filterValue = "", onFilterChange, ...props }) => {
  const intl = useIntl()
  return (
    <div className={"tenant-switcher--dropdown-menu--filter"}>
      <p className={"tenant-switcher--dropdown-menu--filter--title"}>
        {intl.formatMessage({ id: "tenantSwitcher.changeCustomer" })}
      </p>
      <SearchInput
        value={filterValue}
        onChange={onFilterChange}
        intl={intl}
        isExpandable={false}
        placeholder={intl.formatMessage({ id: "tenantSwitcher.searchCustomers" })}
        ariaLabel={intl.formatMessage({ id: "tenantSwitcher.searchCustomers" })}
        id={"search-customers-filter"}
        autoFocus={true}
        {...props}
      />
    </div>
  )
}

export default SearchCustomers
