import {
  registerUrlProvider,
  registerAppKeyProvider,
  registerErrorHandlerProvider,
  registerBearerTokenProvider,
  registerOrganizationProvider,
  registerImpersonatorBearerTokenProvider,
  registerImpersonatorOrganizationProvider
} from "@fuze/apis-warden"

import getConfiguration from "data/configuration"
import { getNonImpersonatingToken, getToken } from "lib/token"
import { errorHandler } from "../errorInterceptor"
import { getOrganization as getActingOrganization, getOriginalImpersonatorOrganization } from "lib/user-session"

export default function registerApiProviders() {
  registerUrlProvider(() => getConfiguration().then(data => data.warden.url))
  registerAppKeyProvider(() => getConfiguration().then(data => data.warden.appKey))
  registerBearerTokenProvider(async () => getToken())
  registerErrorHandlerProvider(async () => errorHandler)
  registerOrganizationProvider(() => getActingOrganization())
  registerImpersonatorBearerTokenProvider(() => getNonImpersonatingToken())
  registerImpersonatorOrganizationProvider(() => getOriginalImpersonatorOrganization())
}
