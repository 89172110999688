import _flow from "lodash/flow"

import _getLocations from "@fuze/apis-foundry/dist/locations/functions/getLocations"
import _searchLocations from "@fuze/apis-foundry/dist/locations/functions/searchLocations"
import _searchLocationRegistrations from "@fuze/apis-foundry/dist/locations/functions/searchLocationRegistrations"
import _getLocationRegistration from "@fuze/apis-foundry/dist/locations/functions/getLocationRegistration"
import _createLocationRegistration from "@fuze/apis-foundry/dist/locations/functions/createLocationRegistration"
import _convertLocationIstRegistration from "@fuze/apis-foundry/dist/locations/functions/convertLocationIstRegistration"
import _updateLocationRegistration from "@fuze/apis-foundry/dist/locations/functions/updateLocationRegistration"
import _bulkUpdateRegistrationCallerId from "@fuze/apis-foundry/dist/locations/functions/bulkUpdateRegistrationCallerId"
import _updateLocation from "@fuze/apis-foundry/dist/locations/functions/updateLocation"
import _createLocation from "@fuze/apis-foundry/dist/locations/functions/createLocation"
import _deleteLocation from "@fuze/apis-foundry/dist/locations/functions/deleteLocation"
import _validateLocationDelete from "@fuze/apis-foundry/dist/locations/functions/validateLocationDelete"
import _getLocationEmergencyContacts from "@fuze/apis-foundry/dist/locations/functions/getLocationEmergencyContacts"
import _createLocationEmergencyContact from "@fuze/apis-foundry/dist/locations/functions/createLocationEmergencyContact"
import _deleteLocationEmergencyContact from "@fuze/apis-foundry/dist/locations/functions/deleteLocationEmergencyContact"

import {
  wrapAllResults,
  wrapAllResultsNoOrganization,
  wrapAllResultsWithIds,
  wrapAllResultsNoOrganizationNoDataExtracting
} from "./decorators"
import { cached } from "../utils"

export const getLocations = q => wrapAllResults(_getLocations)(q)
export const searchLocationRegistrations = wrapAllResultsNoOrganization(_searchLocationRegistrations)
export const searchLocationRegistrationsCount = wrapAllResultsNoOrganizationNoDataExtracting(
  _searchLocationRegistrations
)
export const getLocationRegistration = wrapAllResultsNoOrganization(_getLocationRegistration)
export const createLocationRegistration = (locationId, data) =>
  wrapAllResultsNoOrganization(_createLocationRegistration)(locationId, data)
export const convertLocationIstRegistration = id => wrapAllResultsNoOrganization(_convertLocationIstRegistration)(id)
export const updateLocationRegistration = (locationId, data) =>
  wrapAllResultsNoOrganization(_updateLocationRegistration)(locationId, data)
export const bulkUpdateRegistrationCallerId = data =>
  wrapAllResultsNoOrganization(_bulkUpdateRegistrationCallerId)(data)
export const getLocationsByIds = _flow(wrapAllResultsWithIds, cached)(_searchLocations)
export const updateLocation = (q, data) => wrapAllResultsNoOrganization(_updateLocation)(q, data)
export const createLocation = data => wrapAllResultsNoOrganization(_createLocation)(data)
export const deleteLocation = id => wrapAllResultsNoOrganization(_deleteLocation)(id)
export const validateLocationDelete = id => wrapAllResultsNoOrganization(_validateLocationDelete)(id)
export const getOrgLocations = (org, q) => wrapAllResultsNoOrganization(_getLocations)(org, q)
export const getLocationEmergencyContacts = id => wrapAllResultsNoOrganization(_getLocationEmergencyContacts)(id)
export const createLocationEmergencyContact = (locationId, peerId) =>
  wrapAllResultsNoOrganization(_createLocationEmergencyContact)(locationId, peerId)
export const deleteLocationEmergencyContact = (locationId, peerId) =>
  wrapAllResultsNoOrganization(_deleteLocationEmergencyContact)(locationId, peerId)
