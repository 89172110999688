import { useEffect, useState } from "react"
import { useMountedState } from "react-use"
import { getAllLocations, getLocations, getStoredVoiceEnabledLocations } from "data/locations"

export default function useLocations() {
  const [locations, setLocations] = useState([])
  const [isLoading, setLoading] = useState(true)
  const isMounted = useMountedState()

  async function retrieveLocations(invalidateCache) {
    try {
      setLoading(true)
      const locations = await getLocations(null, invalidateCache)
      if (isMounted()) {
        setLocations(locations)
      }
    } finally {
      if (isMounted()) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    retrieveLocations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [locations, isLoading, retrieveLocations]
}

/**
 * useAllLocations is used to retrieve allocated and non-allocated locations for Regional Admin
 * Essentially is returns the same array of locations as a Global Admin would get.
 * This hook should only be used in certain scenarios,
 * ie. finding out if a specific location exists but not assign.
 */
export const useAllLocations = () => {
  const [allLocations, setAllLocations] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function retrieveData() {
      const newAllLocations = await getAllLocations()

      setAllLocations(newAllLocations)
      setIsLoading(false)
    }

    retrieveData()
  }, [])

  return [allLocations, isLoading]
}

export function useLocationOptions() {
  const [locations, isLoading] = useLocations()

  return [locations.map(location => ({ value: location.id, label: location.name })), isLoading]
}

export function useVoiceEnabledLocationOptions() {
  const [locations, setLocations] = useState([])

  useEffect(() => {
    async function retrieveLocations() {
      const locations = await getStoredVoiceEnabledLocations()
      setLocations(locations.map(location => ({ value: location.id, label: location.name })))
    }
    retrieveLocations()
  }, [])

  return locations
}

// TODO Rename this to useLocations and useLocations to useLocationKeyValues or something
// Just don't want to do it for a hotfix
export function useRawLocations() {
  const [locations, setLocations] = useState([])

  useEffect(() => {
    async function retrieveLocations() {
      const locations = await getLocations()
      setLocations(locations)
    }
    retrieveLocations()
  }, [])

  return locations
}

export function useAllLocationsById(locationId) {
  const [location, setLocation] = useState()

  useEffect(() => {
    async function retrieveLocations() {
      const locations = await getAllLocations()
      setLocation(locations.find(location => location.id === locationId))
    }
    retrieveLocations()
  }, [locationId])

  return location
}
