import { detect } from "detect-browser"
import getVersion from "data/version"

export default async function getOrigin() {
  const { name, version } = detect()
  const application = "hub"
  const { branch, build } = await getVersion()
  const origin = { platform: `${name} ${version}`, application, version: `${branch}:${build}` }
  return origin
}
