import _groupBy from "lodash/groupBy"
import _chunk from "lodash/chunk"
import _flatten from "lodash/flatten"

import { getContacts } from "@fuze/apis-contactive"

async function retrieveContactsByFoundryUsers(users) {
  // Guests functionality can ask for users from different organizations
  const usersGroupedByOrganizationCode = _groupBy(users, user => user.organization.code)

  /**
   * For each organization, group users into buckets of no more than 100 and make a request
   * This is to avoid overloading contactive (it returns a 401 authentication error if you send too many user ids)
   */
  const chunkSize = 100

  const promises = Object.entries(usersGroupedByOrganizationCode).flatMap(([organizationCode, users]) =>
    _chunk(users, chunkSize).map(usersChunk =>
      getContacts(
        organizationCode,
        usersChunk.map(user => user.id)
      )
    )
  )

  return Promise.all(promises).then(_flatten)
}

export { retrieveContactsByFoundryUsers }
