import moment from "moment"
import timezone from "moment-timezone"
import { PORT_TIME_FILTERS } from "../data/constants"

//Returns next week day after 'days' from given date
export const getNextWeekDay = (date, days) => {
  date = date.clone().startOf("day")
  let i = 0
  while (i < days) {
    date = date.clone().add(1, "days")
    if (isWeekday(date)) {
      i++
    }
  }
  return date
}

export const getFutureDate = (date, days, options) => {
  // To preven erroring out user may ommit the options param altogeth
  // or add one param or send a blank object. This should handle this
  // issue
  const { excludeWeekends = true, blackoutDates = [] } = options

  // Clone the start date
  let endDate = moment(date).startOf("day").clone()

  // Count days
  let i = 1

  while (i < days) {
    // Get the next possible date from last date
    const tempDate = endDate.clone()
    const nextDate = tempDate.clone().add(1, "days")

    // Check if the date should be excluded. Such as in a holiday
    //we do not calculate excluded date if next date is a weekend as it is calculated isWeekend scenario
    const _isExcluded = isABlackoutDate(nextDate, blackoutDates) && !isWeekend(nextDate)

    // If weekend and should be excluded
    // move the next day forward by two days
    if (_isExcluded) {
      nextDate.add(1, "days")
    }

    // Whether or not a day is excluded check for weekends
    let _isWeekend = isWeekend(nextDate)

    if (_isWeekend && excludeWeekends) {
      if (nextDate.day === 0) {
        //Sunday
        nextDate.add(1, "days")
      } else {
        nextDate.add(2, "days") //Saturday
      }
    }

    // Move the next date to end date
    endDate = nextDate
    ++i
  }
  return endDate
}

/**
 * check for excluded dates
 *
 * @param {moment} date
 * @param {string[]} exclusionList this should be in YYYY-MM-DD
 *
 * @returns {boolean}
 */
export const isABlackoutDate = (date, exclusionList) =>
  exclusionList.some(value => {
    //the reason to do string comaprison is because the exclusionList
    //sends YYYY-MM-DD which is converted to local zone and the calendar automatically
    //pushes the date back to previous day because of EST rendenring
    return value === date.format("YYYY-MM-DD")
  })

export const isWeekend = date => {
  const day = moment(date).day()
  return day === 0 || day === 6
}

//returns if provided date is a weekday
export const isWeekday = date => {
  const day = moment(date).day()
  return day > 0 && day < 6
}

export const isFriday = date => {
  const day = moment(date).day()
  return day === 5
}

export const getDaysInBetween = (startDate, endDate) => {
  return startDate.clone().startOf("day").diff(endDate, "days")
}

export const setTime = (momentValue, hour, minute, second) => {
  return momentValue.set("hour", hour).set("minute", minute).set("second", second)
}

//Not a great method, but atleast gives a picture that a string can be converted to moment date using this method
export const getMomentDateFromString = dateString => {
  return moment(dateString)
}

export const getCurrentDate = () => {
  return moment()
}

/**
 * A given moment object will be converted to EST
 * @param {moment} date
 */
export const dateToEST = date => timezone(date).tz("America/New_York")
// Returns no of days in between by excluding weekends
export const getBusinessDaysInBetween = (startDate, endDate) => {
  let actualFocDate = startDate.startOf("day")
  // Find actual days in between
  const actualDaysInBetween = getDaysInBetween(startDate, endDate)

  // If actualFocDate >= currunt date set businessDaysInBetween to 0
  let businessDaysInBetween = 0
  // Only validate until current date is reached to FOC date
  if (actualDaysInBetween > 0) {
    let i
    for (i = 0; i <= actualDaysInBetween; i++) {
      actualFocDate = actualFocDate.subtract(1, "days")
      // Considered bussiness days only
      if (isWeekday(actualFocDate)) {
        businessDaysInBetween++
      }
    }
  }
  // Return business days between
  return businessDaysInBetween
}

// Return the closest matching time when changing dates.
export const getClosestPortTime = date => {
  const dayOfTheWeek = moment(date).format("dddd").toUpperCase()
  const earliestStartTime = PORT_TIME_FILTERS[dayOfTheWeek + "_START"]
  const latestStartTime = PORT_TIME_FILTERS[dayOfTheWeek + "_END"]
  /*
   If the current selected data is > than the latest start time for this day
   of the week, replace with the latest start time.  If not, then replace 
   with the earliest start time.
  */
  return moment(date).format("H") > latestStartTime
    ? moment(date).set({ h: latestStartTime })
    : moment(date).set({ h: earliestStartTime })
}

export const updateForTimezone = date => {
  const timezoneDiff = moment().tz("America/New_York").utcOffset() - moment().utcOffset()
  return dateToEST(moment(date).subtract(timezoneDiff, "minutes"))
}

export const updateDisplayTimezone = date => {
  const timezoneDiff = moment().utcOffset() - moment().tz("America/New_York").utcOffset()
  return dateToEST(moment(date).subtract(timezoneDiff, "minutes"))
}

// The allowed port times are defined in the data/constants.js file
export const isAllowedPortTime = date => {
  // can use === to test if we cast hour & minute to integer
  const momentDate = moment(date)
  const datePickerHour = parseInt(momentDate.format("H"))
  const datePickerMinute = parseInt(momentDate.format("m"))
  const upperCaseDayOfWeek = momentDate.format("dddd").toUpperCase()
  const dayStart = PORT_TIME_FILTERS[upperCaseDayOfWeek + "_START"]
  const dayEnd = PORT_TIME_FILTERS[upperCaseDayOfWeek + "_END"]

  return (
    (datePickerHour >= dayStart && datePickerHour < dayEnd) || (datePickerHour === dayEnd && datePickerMinute === 0)
  )
}

export const fixSuggestedDates = supportedOrders => {
  supportedOrders.forEach(supportedOrder => {
    if (supportedOrder?.portinOrder?.activation?.suggestedDate) {
      // First, make sure it's in EST
      supportedOrder.portinOrder.activation.suggestedDate = dateToEST(
        supportedOrder.portinOrder.activation.suggestedDate
      )
      // Now see if it needs adjusted based on allowed porting times
      const suggestedDate = moment(supportedOrder?.portinOrder?.activation?.suggestedDate)
      if (!isAllowedPortTime(suggestedDate)) {
        const fixedDateTime = getClosestPortTime(suggestedDate)
        supportedOrder.portinOrder.activation.suggestedDate = fixedDateTime
      }
    }
  })
  return supportedOrders
}
//Fix a single date.
//export const fixSuggestedDate = supportedOrders => {
export const fixSuggestedDate = suggestedDate => {
  let newSuggestedDate = suggestedDate
  // First, make sure it's in EST
  newSuggestedDate = dateToEST(newSuggestedDate)
  // Now see if it needs adjusted based on allowed porting times
  newSuggestedDate = moment(newSuggestedDate)
  if (!isAllowedPortTime(newSuggestedDate)) {
    const fixedDateTime = getClosestPortTime(newSuggestedDate)
    newSuggestedDate = fixedDateTime
  }
  return newSuggestedDate
}
// return the end of day per the PORT_TIME_FILTERS, given a date passed in
export const resetTimeToTheEndOfDay = date => {
  const portTimeFilterProp = moment(date).format("dddd").toUpperCase() + "_END"
  const dateClone = date.clone()
  return setTime(dateToEST(dateClone), PORT_TIME_FILTERS[portTimeFilterProp], 0, 0)
}
