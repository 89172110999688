export function register(user, organization) {
  if (window.Appcues) {
    window.Appcues.identify(user.userId, { customer: organization.name })
  }
}

export function logPageView() {
  if (window.Appcues) {
    window.Appcues.page()
  }
}

export function track(event) {
  if (window.Appcues) {
    window.Appcues.track(event)
  }
}
