import mixpanel from "mixpanel-browser"
import memoizeOne from "memoize-one"
import getConfiguration from "./configuration"
import { getOrganization } from "lib/user-session"

async function _initialize() {
  const { analytics } = await getConfiguration()

  if (analytics && analytics.token) {
    mixpanel.init(analytics.token)
    return true
  }

  return false
}

const initialize = memoizeOne(_initialize)

async function identify(entity) {
  const initialized = await initialize()
  if (initialized) {
    mixpanel.identify(entity.origin.id)
    /**
     * We're storing both customerCode and organization because the angular legacy codebase was
     * sending customerCode, but the more modern term is organization. There may be reports driven off
     * customerCode.
     */
    mixpanel.people.set({
      name: entity.fullName,
      customerCode: entity.tenantKey,
      organization: entity.tenantKey
    })
  }
}

async function track(eventName, properties = {}) {
  const initialized = await initialize()
  if (initialized) {
    mixpanel.track(eventName, { ...properties, organization: getOrganization() })
  }
}

export { identify, track }
