import _zip from "lodash/zip"

/**
 * Given a list of entities and a list of resolver lookup objects,
 * combine the root entities with the dependencies
 *
 * A passed-in resolver is an object with a resolve function and a reduce function. The
 * resolve function is responsible for reading the necessary data from the root entities in order
 * to query for the dependent entities. The reduce function is responsible for taking the
 * data returned from the dependency query and combining that with the root entities. The reason that
 * these two functions are split up is for some optimization in that we can often make a lot
 * of the network calls in parallel for dependencies
 *
 * For example, suppose that I wanted a list of services with extensions and users
 *
 * I would start by making a call to search services (based on service ids, for instance)
 * Then I would pass in dependencies for users and extensions
 *
 * The extensions resolver would have a resolve function that would grab all of the services
 * and then return a promise with the list of extensions that are relevant for those services. It would
 * then have a reduce function that would take a list of services and extensions and combine the
 * extension object into the service object
 *
 * const services = await searchServices({ ids })
 * const services = await resolveDependencies(services, extensions(), users())
 *
 * where extensions() might be defined like this:
 *
 * const extensions = services => {
 *   return {
 *     resolve: (services) => {
 *       const serviceIds = services.map(service => service.id)
 *       return searchExtensions({ serviceIds })
 *     },
 *     reduce: (services, extensions) => {
 *       const extensionMap = _keyBy(extensions, "service.id")
 *       return services.map(service => ({
 *         ..service,
 *         extension: extensionMap[service.id]
 *       }))
 *     }
 *   }
 *
 * }
 *
 * interface Resolver {
 *   resolve: function,
 *   reduce: function
 * }
 *
 * @param {Object[]} entities
 * @param  {Resolver[]} resolvers
 */
export async function resolveDependencies(entities, ...resolvers) {
  // Iterate through the resolvers and get a list of promises that will provide
  // access to the dependent entities
  const dependencyPromises = resolvers.map(resolver => resolver.resolve(entities))
  // Wait for all of the promises to resolve. This is an optimization to allow the
  // network requests to be made in parallel
  const resolvedDependencies = await Promise.all(dependencyPromises)
  // Take the list of dependency objects and zip them with the resolved dependent entities
  // Example from lodash documentation
  // _.zip(['a', 'b'], [1, 2], [true, false]);
  // => [['a', 1, true], ['b', 2, false]]
  const zippedDependencies = _zip(resolvers, resolvedDependencies)

  // Combine the root entities with the resolved dependent entites using the dependent resolve
  // functions
  const reducedEntities = zippedDependencies.reduce((entities, zippedDependencies) => {
    return zippedDependencies[0].reduce(entities, zippedDependencies[1])
  }, entities)

  return reducedEntities
}

/**
 * This is a convenience class that makes the queries a little nice to read/work with
 *
 * The above example could be rewritten like:
 *
 * const services = await new DependencyBuilder()
 *   .with(extensions())
 *   .with(users())
 *   .execute(services)
 */
export class DependenciesResolverBuilder {
  dependencies = []

  // Pass in a dependency and have it added to the list
  // Returning this allows for chaining
  with(...dependencies) {
    this.dependencies = this.dependencies.concat(dependencies)
    return this
  }

  // Execute the function, retrieving the dependencies and combining them with the provided entities
  async execute(entities) {
    return await resolveDependencies(entities, ...this.dependencies)
  }
}

/**
 * One more piece that I find makes using the library easier to read
 *
 * const services = await buildDependenciesResolver()
 *   .with(extensions())
 *   .with(users())
 *   .execute(services)
 *
 * You could also write it as
 *
 * const services = await buildDependenciesResolver()
 *   .with(extensions(), users())
 *   .execute(services)
 *
 * @param {function} query
 */
export const buildDependenciesResolver = () => new DependenciesResolverBuilder()
