import React from "react"
import { Button } from "reactstrap"
import BaseLayout from "components/layouts/BaseLayout"
import ErrorCover from "components/layouts/components/ErrorCover"
import ErrorCoverTopBar from "components/layouts/components/ErrorCoverTopBar"
import useCurrentUser from "hooks/useCurrentUser"
import { supportData } from "constants/support"
import { FormattedMessage, useIntl } from "react-intl"
import { useLogout } from "@fuze/hub-authorization"
import { Helmet } from "react-helmet"

/**
 * General Error Page whenever there is an error we can't handle
 */
export default function GeneralErrorPage() {
  const { formatMessage } = useIntl()
  const currentUser = useCurrentUser()
  const logout = useLogout()

  function handleOnClick() {
    window.open(supportData.supportDocURL)
  }

  return (
    <BaseLayout>
      <Helmet>
        <title>{formatMessage({ id: "errors.errorBoundary.title" })}</title>
      </Helmet>
      <ErrorCoverTopBar
        action={
          currentUser && (
            <Button onClick={logout}>
              <FormattedMessage id="header.logout" />
            </Button>
          )
        }
      />
      <ErrorCover
        action={
          <button className="btn btn-primary" type="button" onClick={handleOnClick}>
            <FormattedMessage id={"support.info"} />
          </button>
        }
        description={<FormattedMessage id="errors.errorBoundary.description" />}
        title={<FormattedMessage id="errors.errorBoundary.title" />}
      />
    </BaseLayout>
  )
}
