import React from "react"
import { FormattedMessage } from "react-intl"
import { DropdownItem } from "reactstrap"
import { UserAvatar } from "components/layouts/components/UserAvatar"
import { getUser, loggedInUserManagedLocations } from "lib/user-session"
import { useRawLocations } from "hooks/useLocations"
import { InfoButton, InfoButtonText } from "components/buttons/InfoButton"
import { EnablementAccess, enablements } from "Access"
import { Spinner } from "reactstrap"
import usePrimaryUserRole from "hooks/usePrimaryUserRole"
import _sortBy from "lodash/sortBy"
import useLogout from "components/authentication/useLogout"
import { useProtectedLinkSection } from "@fuze/hub-authorization"

const DisplayManagedLocations = ({ managedLocationsNames }) => {
  const [location1, location2, ...otherLocations] = _sortBy(managedLocationsNames)

  if (!managedLocationsNames.length) return null

  return (
    <span id={"top-menu--user-details--managed-locations"} className={"member-managedLocations"}>
      {[location1, location2].join(", ")}
      {displayAdditionalLocationNames()}
    </span>
  )

  function displayAdditionalLocationNames() {
    if (!otherLocations.length) return null

    return (
      <>
        <span>{", "}</span>
        <span id={"header-managedLocations"} className={"member-managedLocations--additional"}>
          <InfoButton
            id={"header-managedLocations"}
            anchor={
              <FormattedMessage
                id="header.managedLocations"
                values={{ numAdditionalLocations: otherLocations.length }}
              />
            }
          >
            <InfoButtonText>{otherLocations.join(", ")}</InfoButtonText>
          </InfoButton>
        </span>
      </>
    )
  }
}

const HeaderLoggedInUserDescription = () => {
  const logout = useLogout()
  const user = getUser()
  const locations = useRawLocations()
  const managedLocationsNames = loggedInUserManagedLocations().map(
    locationId => locations.find(location => location.id === locationId)?.name
  )
  const [primaryRole, loadingPrimaryRole] = usePrimaryUserRole()
  const [UserProfileSection, UserProfileLink] = useProtectedLinkSection(`/users/${user.origin.id}`)

  return (
    <>
      <DropdownItem text className="dropdown-title" id="ua-label">
        <FormattedMessage id="header.myAccount" />
      </DropdownItem>
      <ul className="member-details" aria-labelledby="ua-label">
        <li className="member-detail member-detail-user">
          <DropdownItem text>
            <UserAvatar className="member-avatar" user={user} />
            <div className={"member-details-role-locations"}>
              <strong className="member-name">{user.fullName}</strong>
              {loadingPrimaryRole ? (
                <Spinner />
              ) : (
                <span id={"top-menu--user-details--role"} className={"text--no-wrap"}>
                  {primaryRole?.name}
                </span>
              )}
              <EnablementAccess enablementFlag={enablements.regional}>
                <DisplayManagedLocations managedLocationsNames={managedLocationsNames} />
              </EnablementAccess>
            </div>
          </DropdownItem>
        </li>
        <UserProfileSection>
          <li className="member-detail member-link">
            <DropdownItem tag={UserProfileLink}>
              <FormattedMessage id="header.profile" />
            </DropdownItem>
          </li>
        </UserProfileSection>
        <li className="member-detail member-link">
          <DropdownItem onClick={logout}>
            <FormattedMessage id="header.logout" />
          </DropdownItem>
        </li>
      </ul>
    </>
  )
}

export default HeaderLoggedInUserDescription
