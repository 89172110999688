import React from "react"

import PermissionsProtected from "components/permissions/PermissionsProtected"
import permissions from "constants/permissions"
import Access, { useAccess } from "components/permissions/Access"
import { isLoggedInOrganizationThinkingphones } from "lib/user-session"
import { didRequestsPath } from "./Paths"
import { stringify } from "query-string"
import { getDefaultRequestStatus } from "./data/convertStatuses"
import { useCustomerLocation, US } from "data/customer-location"

const viewDidPermissions = [permissions.dids.viewDids]
const newDidPermissions = [permissions.dids.newDids]
const buildDidRequestsPath = queryParams => {
  const withDefault = {
    ...queryParams,
    status: getDefaultRequestStatus()
  }
  const serializedQueryParams = stringify(withDefault)
  return `${didRequestsPath}?${serializedQueryParams}`
}

const buildDidRequestPath = requestId => `${didRequestsPath}?${stringify({ requestId: requestId })}`

export const DIDAccess = ({ children }) => {
  const [hasPermissions, path] = useDIDsAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

const useDIDsAccess = () => {
  const isCustomerHavingUSLocation = useCustomerLocation(US)
  const [hasDidsPermissions, path] = useAccess([permissions.dids.viewDids], buildDidRequestsPath())
  const [hasNoDidRestrictions] = useAccess([permissions.dids.didRequestCreateNoRestrictions])

  const canUserSeeNumberRequests =
    !isLoggedInOrganizationThinkingphones() &&
    (hasNoDidRestrictions || isCustomerHavingUSLocation) &&
    hasDidsPermissions

  return [canUserSeeNumberRequests, path]
}

export const CreateDIDAccess = ({ children }) => {
  /*
   * isActiveOrganizationEnabledForDids is used to identify if DID is enabled for a customer.
   * If this is false for a customer, then customer admin cannot create number requests.
   * This check is overridden by hasNoDidRestrictions i.e. by a Fuze user.
   */
  /*
   * isCustomerHavingUSLocation restricts the DID operations for customers that has NO US location.
   * This check is overridden by hasNoDidRestrictions i.e. by a Fuze user.
   */
  const isCustomerHavingUSLocation = useCustomerLocation(US)

  /*
    * hasNoDidRestrictions permission is used to replace the isLoggedInUserThinkingPhones check
    * The hasNoDidRestrictions allows users to perform following operations
    * 1. Can Create Number requests for any customer with or without a US Location.
    * 2. Can View Number requests of any customer with or without a US Location.
    * 3. Can Create Number requests & View number requests for a customer who does not have the Organization enabled
         for DIDs.
    */
  const [hasNoDidRestrictions] = useAccess([permissions.dids.didRequestCreateNoRestrictions])

  /*
   *isLoggedInOrganizationThinkingphones is used to restrict Fuze users from creating Number requests
    when Fuze organization is selected.
  */
  return (
    <>
      {!isLoggedInOrganizationThinkingphones() && (hasNoDidRestrictions || isCustomerHavingUSLocation) && (
        <PermissionsProtected requiredPermissions={newDidPermissions}>{children}</PermissionsProtected>
      )}
    </>
  )
}

export const FuzeViewDIDsAccess = ({ children, fallback }) => {
  const [hasNoDidRestrictions] = useAccess([permissions.dids.didRequestCreateNoRestrictions])

  return (
    <>
      {hasNoDidRestrictions && (
        <PermissionsProtected requiredPermissions={viewDidPermissions}>{children}</PermissionsProtected>
      )}
      {!hasNoDidRestrictions && fallback && (
        <PermissionsProtected requiredPermissions={viewDidPermissions}>{fallback}</PermissionsProtected>
      )}
    </>
  )
}

export { useDIDsAccess, buildDidRequestPath }
