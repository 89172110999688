import {
  DID_REQUEST_STATUS_CATEGORIES,
  DID_REQUEST_STATUS,
  DID_EMAIL_PROCESSED_TYPES,
  DID_REQUEST_STATUS_VALUES
} from "./constants"
import { doesUserHavePermission } from "lib/user-session"
import permissions from "constants/permissions"

export const getStatusCodesForStatuses = statuses => {
  let statusCodes = []
  statuses && statuses.forEach(status => (statusCodes = statusCodes.concat(getStatusCodesForStatus(status))))
  return statusCodes
}

export const getStatusCodesForStatus = status => {
  const statusCodes = Object.keys(DID_REQUEST_STATUS)
    .filter(didStatus => status === DID_REQUEST_STATUS[didStatus].value)
    .map(didStatus => DID_REQUEST_STATUS[didStatus].statusCode)
  return statusCodes
}

export const getStatusesByCategory = statusCategory => {
  const statuses = Object.keys(DID_REQUEST_STATUS)
    .filter(
      didStatusCode =>
        statusCategory === DID_REQUEST_STATUS_CATEGORIES.ALL ||
        statusCategory === DID_REQUEST_STATUS[didStatusCode].category
    )
    .map(didStatusCode => DID_REQUEST_STATUS[didStatusCode].statusCode)
  return statuses
}

export const getStatusesByCategories = (
  status,
  inprogressRequestStatuses,
  completedRequestStatuses,
  rejectedRequestStatuses
) => {
  let statuses = []
  if (
    status &&
    status.length > 0 &&
    inprogressRequestStatuses &&
    inprogressRequestStatuses.length > 0 &&
    status.includes(DID_REQUEST_STATUS_CATEGORIES.IN_PROGRESS)
  ) {
    statuses = statuses.concat(inprogressRequestStatuses.map(statusItem => statusItem.statusId))
  }
  if (
    status &&
    status.length > 0 &&
    completedRequestStatuses &&
    completedRequestStatuses.length > 0 &&
    status.includes(DID_REQUEST_STATUS_CATEGORIES.COMPLETED)
  ) {
    statuses = statuses.concat(completedRequestStatuses.map(statusItem => statusItem.statusId))
  }
  if (
    status &&
    status.length > 0 &&
    rejectedRequestStatuses &&
    rejectedRequestStatuses.length > 0 &&
    status.includes(DID_REQUEST_STATUS_CATEGORIES.REJECTED)
  ) {
    statuses = statuses.concat(rejectedRequestStatuses.map(statusItem => statusItem.statusId))
  }
  return statuses
}

export const getProcessedTypeCodes = emailStatuses => {
  let statuses = []
  if (emailStatuses && emailStatuses.length > 0) {
    statuses = statuses.concat(
      emailStatuses.map(emailStatus => DID_EMAIL_PROCESSED_TYPES[emailStatus.toUpperCase()].code)
    )
  }
  return statuses
}

export const getDefaultRequestStatus = () => {
  const hasNoDidRestrictions = doesUserHavePermission(permissions.dids.didRequestCreateNoRestrictions)

  if (hasNoDidRestrictions) {
    // Fuze will be interested on pending requests
    return [
      DID_REQUEST_STATUS_VALUES.REQUEST_RECEIVED,
      DID_REQUEST_STATUS_VALUES.SUBMITTED_TO_CARRIER,
      DID_REQUEST_STATUS_VALUES.CARRIER_ACKNOWLEDGED,
      DID_REQUEST_STATUS_VALUES.PARTIALLY_COMPLETED
    ]
  } else {
    // Customers will be interested on all requests
    return []
  }
}

export const getDefaultProcessedTypes = () => {
  return [
    DID_EMAIL_PROCESSED_TYPES.ERRORED.value,
    DID_EMAIL_PROCESSED_TYPES.UNRECOGNIZED.value,
    DID_EMAIL_PROCESSED_TYPES.SPAM.value
  ]
}
