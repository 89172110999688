import getConfiguration from "../../configuration"
import { populateParameter } from "../utils"

export const url = populateParameter(async () => {
  const { warden } = await getConfiguration()
  return warden.url
})

export const appKey = populateParameter(async () => {
  const { warden } = await getConfiguration()
  return warden.appKey
})
