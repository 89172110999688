import React from "react"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import { hasSomePermission } from "lib/permissions"
import { useAccess } from "components/permissions/Access"
import permissions from "constants/permissions"
import { stringify } from "query-string"
import { getDefaultProcessedTypes, getDefaultRequestStatus } from "./NumberRequests/data/convertStatuses"
import { allDidRequestsPath, didInvestigationsPath } from "./NumberRequests/Paths"
import {
  logisticsPortinsPath,
  managePortinOrderByLogisticsPath,
  managerFocChangeRequestsLogisticsPath,
  holidayPortalPath
} from "./PortRequests/Paths"
import { getDefaultFocChangeRequestStatusesForToolbox } from "./PortRequests/data/convertStatuses"

//A Logistics user should have updateDids to be able to see the Customer Dids menu on ToolboxSideBar
//used to control All number requests, Rate centers & DID failures on Toolbox sidebar
const didNumberRequestsAccessForLogistics = [permissions.dids.updateDids]
//A Logistics user should have updatePortinOrdersElevated to be able to see the Number porting menu on ToolboxSideBar
//Used to control All number ports & Foc change requests on left nav
const didNumberPortingAccessForLogistics = [permissions.portRequests.updatePortinOrdersElevated]
//The above permissions are assigned to a FUZE_LOGISTICS which is an add-on to FUZE_INTERNAL
//The FUZE_INTERNAL will automatically get the view permissions of the Number Requests and Number Porting data.
//Used to control Holiday Portal on left nav
const holidatPortalAccessForLogistics = [permissions.portRequests.updatePortinCalendar]

const toolboxPermissions = [
  permissions.dids.updateDids,
  permissions.portRequests.updatePortinOrdersElevated,
  permissions.alloyMigration.migrationToolbox
]

const buildManageLogisticPortinOrderPath = portinOrderId =>
  managePortinOrderByLogisticsPath.replace(":portinOrderId", portinOrderId)
const buildDidRequestsPath = queryParams => {
  const withDefault = {
    ...queryParams,
    status: getDefaultRequestStatus()
  }
  const serializedQueryParams = stringify(withDefault)
  return `${allDidRequestsPath}?${serializedQueryParams}`
}

const buildDidInvestigationsPath = queryParams => {
  const withDefault = {
    ...queryParams,
    emailStatus: getDefaultProcessedTypes()
  }
  const serializedQueryParams = stringify(withDefault)
  return `${didInvestigationsPath}?${serializedQueryParams}`
}

const buildDidInvestigationPath = emailMessageId => `${didInvestigationsPath}/${emailMessageId}/resolve`

const buildDidInvestigationsPathForAllErrors = () => {
  return `${didInvestigationsPath}?${stringify({ emailStatus: getDefaultProcessedTypes() })}`
}

export const ToolboxAccess = ({ children }) => {
  return (
    <>
      <PermissionsProtected requiredPermissions={toolboxPermissions} permissionsCheck={hasSomePermission}>
        {children}
      </PermissionsProtected>
    </>
  )
}

export const useViewAllDIDsAccess = () => useAccess(didNumberRequestsAccessForLogistics, buildDidRequestsPath({}))

export const ViewAllDIDsAccess = ({ children }) => {
  return (
    <>
      <PermissionsProtected requiredPermissions={didNumberRequestsAccessForLogistics}>
        {children(buildDidRequestsPath({}))}
      </PermissionsProtected>
    </>
  )
}

export const UpdateDIDAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={didNumberRequestsAccessForLogistics}>{children}</PermissionsProtected>
  )
}

export const useInvestigateDIDErrorsAccess = () =>
  useAccess(didNumberRequestsAccessForLogistics, () => buildDidInvestigationsPath({}))

export const InvestigateDIDErrorsAccess = ({ children }) => {
  return (
    <>
      <PermissionsProtected
        requiredPermissions={didNumberRequestsAccessForLogistics}
        permissionsCheck={hasSomePermission}
      >
        {children(buildDidInvestigationsPath({}))}
      </PermissionsProtected>
    </>
  )
}

export const ResolveInvestigationAccess = ({ children, emailMessageId, fallback }) => {
  return (
    <>
      <PermissionsProtected requiredPermissions={didNumberRequestsAccessForLogistics} fallback={fallback}>
        {children(buildDidInvestigationPath(emailMessageId))}
      </PermissionsProtected>
    </>
  )
}

export const ManagePortinOrderElevatedAccess = ({ children, portinOrderId, fallback }) => {
  return (
    <PermissionsProtected requiredPermissions={didNumberPortingAccessForLogistics} fallback={fallback}>
      {children(buildManageLogisticPortinOrderPath(portinOrderId))}
    </PermissionsProtected>
  )
}

const buildAllPortinOrdersPath = queryParams => {
  const withDefault = { ...queryParams, status: [] }
  const serializedQueryParams = stringify(withDefault)
  return `${logisticsPortinsPath}?${serializedQueryParams}`
}

export const useViewAllPortinOrdersAccess = () =>
  useAccess(didNumberPortingAccessForLogistics, buildAllPortinOrdersPath({}))

/**
 * Builds the query parameters for the FOC request change path
 *
 * @param {object} queryParams External query params to be build
 */
const buildAllFocChangeRequestPaths = queryParams => {
  const withDefault = {
    ...queryParams,
    status: getDefaultFocChangeRequestStatusesForToolbox()
  }
  const serializedQueryParams = stringify(withDefault)
  return `${managerFocChangeRequestsLogisticsPath}?${serializedQueryParams}`
}

/**
 * Create permission object
 */
export const useManageFocChangeRequestsAccess = () =>
  useAccess(didNumberPortingAccessForLogistics, buildAllFocChangeRequestPaths({}))

export const useHolidayModuleAccess = () => useAccess(holidatPortalAccessForLogistics, holidayPortalPath)

export const useDidInvestigationsAccess = () =>
  useAccess(didNumberRequestsAccessForLogistics, buildDidInvestigationsPath({}))

export { buildDidInvestigationsPath, buildDidInvestigationsPathForAllErrors }
