/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "property" }]*/

import React from "react"

/**
 *
 * @param {string} queryString Assumes a format of ?a=b&c=d&e=f
 */
function parseKeyValues(keyValueList, delimiter = "&", kvDelimiter = "=") {
  if (!keyValueList) return {}

  return keyValueList
    .split(delimiter)
    .map(x => x.split(kvDelimiter))
    .filter(x => x.length === 2) // ignore if there is no value (ie. a&b=c)
    .reduce((accumulator, item) => {
      return { ...accumulator, [item[0].trim()]: item[1].trim() }
    }, {})
}

/**
 * Converts an Javascript Object into a list of key values based on the Object properties
 *
 * @param {Object} map
 * @param {string} keyName a key to use for the property
 * @param {string} valueName a key to use for the property value
 *
 * ie.
 * mapToKeyValueList({a: 1, b: 2}) => [{ key: 'a', value: 1 }, { key: 'b', value: 2 }]
 * mapToKeyValueList({a: 1, b: 2}, 'customKey', 'customValue')
 * 	=> [{ customKey: 'a', customValue: 1 }, { customKey: 'b', customValue: 2 }]
 */
function mapToKeyValueList(map, keyName = "key", valueName = "value") {
  const list = []
  for (let property in map) {
    if (map.hasOwnProperty(property)) {
      if (!!map[property]) {
        list.push({ [keyName]: property, [valueName]: map[property] })
      }
    }
  }
  return list
}

/**
 *
 * @param {Object} map
 * @param {string} delimiter
 * @param {string} kvDelimiter
 */
function serializeKeyValues(map, delimiter = "&", kvDelimiter = "=") {
  const list = mapToKeyValueList(map)
  return list.map(item => `${item.key}${kvDelimiter}${item.value}`).join(delimiter)
}

/**
 * Retrieves the initials from a list of words
 * Limits this to two characters and will use the first and last words
 *
 * @param {string[]} names
 */
function getInitialsFromNames(names) {
  const length = names.length
  return names
    ? names
        // take only the first two initials
        .filter((word, i) => i === 0 || i === length - 1)
        // take the first character of each word
        .map(word => word[0])
        // join the characters together
        .join("")
    : ""
}

/**
 * Retrieves the initials from a name
 *
 * @param {string} fullName
 */
function getInitialsFromFullName(fullName) {
  return fullName && fullName.split ? getInitialsFromNames(fullName.split(/\s+/gi)) : ""
}

/**
 * Adds a query to baseURL, if baseURL already has a query parameters - current query is added on top
 * @param {String} baseURL - URL, might contain query parameters
 * @param {String} query - New query parameters to concatenate to the above URL
 * @return {String} URL with the query
 */
function consolidateUrlQuery(baseURL, query) {
  if (baseURL.slice(-1) === "/") {
    baseURL = baseURL.slice(0, -1)
  }
  if (baseURL.indexOf("?") >= 0) {
    return baseURL.concat("&").concat(query)
  } else {
    return baseURL.concat("?").concat(query)
  }
}

/**
 * Retrieve full name from a user object
 * @param user User object as returned from Foundry
 * @return {string} Capitalized full user name
 */
function getFullUserName(user) {
  return [user?.firstName, user?.lastName].join(" ")
}

/**
 * Capitalize the first letter of a string (ex. for a user's first name)
 * @param string The string to capitalize
 * @return {string} Capitalized string
 */
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/*
  Return true if str is a valid email
  The pattern is taken from http://emailregex.com/
 */
function isValidEmail(str) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(str)
}

function getCurrencySymbol(currency) {
  switch (currency) {
    case "USD":
      return String.fromCharCode(36)
    case "GBP":
      return String.fromCharCode(163)
    case "EUR":
      return String.fromCharCode(8364)
    default:
      return ""
  }
}

const getBoldFullName = user => ({ fullName: getFullUserName(user), bold: str => <strong key={str}>{str}</strong> })

function removeSpecialCharacters(id) {
  const blank = "blank"
  if (!id || id.length === 0) {
    return blank
  }

  const code = id
    .trim() // remove any leading or trailing whitespace
    .toLowerCase()
    .replace(/\s+/g, "-") // replace any multiple spaces with a dash
    .replace(/[^a-z0-9-_]+/g, "") // remove any non alpha-numeric, -, _, +
    .replace(/^[-]+/, "") // remove leading dashes
    .replace(/[-]+$/, "") // remove trailing dashes

  if (!code || code.length === 0) {
    return blank
  }

  return code
}

export {
  parseKeyValues,
  serializeKeyValues,
  getInitialsFromNames,
  getInitialsFromFullName,
  consolidateUrlQuery,
  isValidEmail,
  getCurrencySymbol,
  getFullUserName,
  capitalizeFirstLetter,
  getBoldFullName,
  removeSpecialCharacters
}
