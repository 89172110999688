import _flow from "lodash/flow"

import _createDepartment from "@fuze/apis-foundry/dist/departments/functions/createDepartment"
import _searchDepartments from "@fuze/apis-foundry/dist/departments/functions/searchDepartments"
import _getDepartments from "@fuze/apis-foundry/dist/departments/functions/getDepartments"

import { wrapFoundryFunction, wrapAllResults, wrapAllResultsWithIds } from "./decorators"
import { cached } from "../utils"

export const createDepartment = wrapFoundryFunction(_createDepartment)
export const getDepartments = (q, active) => wrapAllResults(_getDepartments)(q, active)
export const getDepartmentsByIds = _flow(wrapAllResultsWithIds, cached)(_searchDepartments)
