import { isOrganizationAlloy } from "data/apis/foundry/organizations"
import { getOrganization, getProductsByLocation, getCurrencyByLocationFoundry } from "./apis/foundry"
import { getContractedProducts, getServicePrices as getMintServicePrices, getMintLocation } from "./apis/mint"
import { getOrganization as getActingOrganization } from "../lib/user-session"
import _flatten from "lodash/flatten"
import _groupBy from "lodash/groupBy"

/**
 * Given a list of foundry services, returns the pricing information for those services
 *
 * @param services The services to retrieve prices for
 */
const getServicePrices = async services => {
  const organizationCode = getActingOrganization()
  const organization = await getOrganization(organizationCode)
  if (organization.isMsa) {
    return await getMSAServicePrices(services, organizationCode)
  } else {
    return await getCSAServicePrices(services)
  }
}

const getServicePrice = async (service, productType) => {
  const [servicePrice] = await getServicePrices([service], productType)
  return servicePrice
}

const getCSAServicePrices = services => getMintServicePrices(services.map(service => service.id))

const getMSAServicePrices = async (services, organizationCode) => {
  const contractedProducts = await getContractedProducts(organizationCode)
  const contractedRecords = contractedProducts.length ? contractedProducts[0]["records"] : []
  const servicesGroupedByLocation = _groupBy(
    services.filter(service => !!service.location),
    "location.id"
  )

  const locationIds = Object.keys(servicesGroupedByLocation)

  const servicePricePromises = locationIds.map(async locationId => {
    const services = servicesGroupedByLocation[locationId]

    const currencyPromise = getCurrencyByLocation(locationId)
    const productsPromise = getProductsByLocation(locationId, false)

    const [currency, locationProducts] = await Promise.all([currencyPromise, productsPromise])

    const contractedProductsFilteredBuyCurrency = contractedRecords.length
      ? contractedRecords.filter(records => records.currency === currency)[0]["products"]
      : []

    return services.map(service => {
      let product = contractedProductsFilteredBuyCurrency.filter(product => product.sku === service.sku)[0]

      if (!!product) {
        return {
          sku: service.sku,
          serviceId: service.id,
          price: Number(product?.price),
          currency: currency
        }
      }

      product = locationProducts.filter(product => product.sku === service.sku)[0]
      return {
        sku: service.sku,
        serviceId: service.id,
        price: Number(product?.productPrice?.price),
        currency: product?.productPrice?.threeDigit
      }
    })
  })

  const servicePrices = _flatten(await Promise.all(servicePricePromises))
  return servicePrices
}

async function getCurrencyByLocation(locationId) {
  const isAlloyCustomer = await isOrganizationAlloy()
  let data
  if (isAlloyCustomer) {
    data = await getMintLocation(locationId)
  } else {
    data = await getCurrencyByLocationFoundry(locationId)
  }
  return data.currency
}

export { getServicePrices, getServicePrice, getCurrencyByLocation }
