import React from "react"
import { Modal, ModalHeader, ModalBanner, ModalBody } from "@fuze/react-fuzzy"
import { Link } from "routing"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"
import { supportData } from "../constants/support"
import illustrationSrc from "../images/error_illustration@2x.png"
import { ImageRow, Image, ImageContent } from "./Image"
import supportIllustration from "../images/support_illustration.png"

const ModalErrorGeneral = ({ isOpen, onClosed, toggle }) => (
  <Modal isOpen={isOpen} className="modal-error-general" color="danger" onClosed={onClosed} toggle={toggle}>
    <ModalHeader>
      <FormattedMessage id="modal.errorGeneral.title" />
    </ModalHeader>
    <ModalBanner>
      <img src={illustrationSrc} alt="" />
    </ModalBanner>
    <ModalBody>
      {<FormattedHTMLMessage id="modal.errorGeneral.description" />}
      <ImageRow>
        <Image fontSize="3rem">
          <img src={supportIllustration} alt="" />
        </Image>
        <ImageContent centered>
          <span>
            <FormattedMessage id="modal.errorGeneral.contactInstructions" />
          </span>
          <span>
            <Link to={supportData.supportDocURL}>
              <FormattedMessage id="common.contactSupport" />
            </Link>
          </span>
        </ImageContent>
      </ImageRow>
    </ModalBody>
  </Modal>
)

export default ModalErrorGeneral
