import React from "react"
import errorIcon from "images/fuzebot_avatar_warning@2x.png"

export default function ErrorCover({ action = null, description, icon, moreInfo, title }) {
  return (
    <div className="error-cover-view">
      <div className="error-cover-view__inner">
        <div className="error-cover-view__icon">{icon || <img src={errorIcon} alt="error-icon" />}</div>
        <h1 className="error-cover-view__title">{title}</h1>
        <div className="error-cover-view__description">{description}</div>
        {action && <div className="error-cover-view__action">{action}</div>}
        {moreInfo && <div className="error-cover-view__more-info">{moreInfo}</div>}
      </div>
    </div>
  )
}
