import { getOrganization as getActingOrganization } from "@fuze/services-auth/dist/user-session"

import {
  getOrganizationDetails,
  getOrganizationDetailsForImpersonator,
  invalidateOrganizationDetails,
  getOrganization,
  getOrganizations,
  searchOrganizations,
  getEnablements,
  updateWelcomeEmailDefault
} from "@fuze/apis-foundry/dist/organizations"

export {
  getOrganizationDetails,
  getOrganizationDetailsForImpersonator,
  invalidateOrganizationDetails,
  getOrganization,
  searchOrganizations,
  getEnablements,
  updateWelcomeEmailDefault
}

export const searchOrganizationsByKeys = keys => searchOrganizations({ codes: keys }, 10)
export const searchOrganizationsPage = (query, pagination) => {
  return getOrganizations(query, pagination)
}
export async function getOrganizationCatalog() {
  const organizationCode = getActingOrganization()
  const organization = await getOrganization(organizationCode)
  return organization.catalog
}

export async function isOrganizationAlloy() {
  const catalog = await getOrganizationCatalog()
  return catalog === "ALLOY"
}

export async function isOrganizationMSA() {
  const organizationCode = getActingOrganization()
  const { organization } = await getOrganizationDetails(organizationCode)
  return organization?.isMsa
}
