import React from "react"
import BaseLayout from "./BaseLayout"
import TopNavBar from "components/layouts/TopNavBar/TopNavBar"

const TopMenuLayout = ({ children }) => (
  <BaseLayout>
    <TopNavBar />
    {children}
  </BaseLayout>
)

export default TopMenuLayout
