import getConfiguration from "../../configuration"
import { getAuthorizationHeader } from "@fuze/services-auth/dist/token"
import { getOrganization } from "lib/user-session"
import redirect from "lib/redirect"
import { errorUnauthorizedPath, error404Path } from "Errors/Paths"
import { goToUniversalLoginUrlDelayed } from "../authentication-utilities"
import { errorInterceptor } from "data/apis/errorInterceptor"
import { pingHealthCheckUrl } from "./authentication"

import _flow from "lodash/flow"
import { getGuests as _getGuests } from "@fuze/apis-warden/dist/guests"
import { bearerToken, extractResults, organization, populateParameter } from "data/apis/utils"

const defaultWardenHeaders = {
  "X-Long-Encoding": "string"
}

// TODO Possible duplicate functions

async function goToOutageUrl() {
  redirect(getOutageUrl())
}

function getOutageUrl() {
  return error404Path
}

async function goToPermissionsUrl() {
  redirect(getPermissionsUrl())
}

function getPermissionsUrl() {
  return errorUnauthorizedPath
}
// End Possible duplicate functions

// TODO Find a better way to handle these errors than to do these redirects
// see https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
async function fetchFromWardenUnhandled(url, options = {}) {
  const headers = { headers: { ...options.headers, ...getAuthorizationHeader() } }
  const combinedOptions = { ...options, ...headers }
  return fetch(url, combinedOptions)
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        if (response.status === 401) {
          response.json().then(data => {
            if (data.status === 1008) {
              goToPermissionsUrl()
            } else {
              goToUniversalLoginUrlDelayed()
            }
          })
        } else if (response.status === 403) {
          goToPermissionsUrl()
        } else if (response.status === 429) {
          response.json().catch(error => {
            throw new Error(error.msg)
          })
        } else {
          return pingHealthCheckUrl().then(response => {
            if (response.status === 200) {
              throw new Error(response.statusText)
            } else {
              goToOutageUrl()
            }
          })
        }
      }
    })
    .then(response => response.data)
}
const fetchFromWarden = _flow(errorInterceptor)(fetchFromWardenUnhandled)

async function getGuestsPolicy() {
  const { warden } = await getConfiguration()
  const organization = getOrganization()
  const tenantURL = `${warden.url}/api/v2/tenants/${organization}/administration/guestInvite/`
  const usersURL = `${warden.url}/api/v2/tenants/${organization}/administration/guestBeInvited/`
  return Promise.all([fetchFromWarden(tenantURL, { method: "GET" }), fetchFromWarden(usersURL, { method: "GET" })])
}

async function setGuestsProfile(profile) {
  const { warden } = await getConfiguration()
  const organization = getOrganization()
  const tenantURL = `${warden.url}/api/v2/tenants/${organization}/administration/guestInvite/${profile}`
  const usersURL = `${warden.url}/api/v2/tenants/${organization}/administration/guestBeInvited/${profile}`
  return Promise.all([fetchFromWarden(tenantURL, { method: "PUT" }), fetchFromWarden(usersURL, { method: "PUT" })])
}

async function setGuestsEmailNotifications(state) {
  const { warden } = await getConfiguration()
  const notificationsURL = `${warden.url}/api/v2/tenants/mine/administration/users/me/notifications/all/${state}`
  return fetchFromWarden(notificationsURL, { method: "PUT" })
}

async function getGuestsEmailNotifications() {
  const { warden } = await getConfiguration()
  const notificationsURL = `${warden.url}/api/v2/tenants/mine/administration/users/me/notifications`
  return fetchFromWarden(notificationsURL, { method: "GET" })
}

const url = populateParameter(async () => {
  const { warden } = await getConfiguration()
  return warden.url
})
const defaultParameters = _flow(errorInterceptor, url, bearerToken)
const defaultOrganizationParameters = _flow(defaultParameters, organization)

const wrapWardenFunctionWithOrganization = _flow(defaultOrganizationParameters, extractResults)
const getGuests = wrapWardenFunctionWithOrganization(_getGuests)

async function deactivateGuest(userId) {
  const { warden } = await getConfiguration()
  const organization = getOrganization()
  const retrieveGuestsURL = `${warden.url}/api/v2/tenants/${organization}/guests/${userId}`
  return fetchFromWarden(retrieveGuestsURL, {
    method: "DELETE",
    headers: {
      ...defaultWardenHeaders
    }
  })
}

export {
  getGuestsPolicy,
  setGuestsProfile,
  setGuestsEmailNotifications,
  getGuests,
  deactivateGuest,
  getGuestsEmailNotifications
}
