import React from "react"
import { FormattedMessage } from "react-intl"
import { Button } from "reactstrap"
import ErrorCoverTopBar from "components/layouts/components/ErrorCoverTopBar"
import { useLogout } from "@fuze/hub-authorization"
import ErrorCover from "components/layouts/components/ErrorCover"
import ExternalPathnames from "constants/external-pathnames"

const handleOnClick = e => {
  e.preventDefault()
  window.open(ExternalPathnames.TERMS_AND_CONDITIONS, "_blank, noopener, noreferrer")
}

const ErrorUnauthorized = () => {
  const logout = useLogout()
  return (
    <>
      <ErrorCoverTopBar
        action={
          <Button color="secondary" onClick={logout}>
            <FormattedMessage id="header.logout" />
          </Button>
        }
      />
      <ErrorCover
        action={
          <Button color="primary" onClick={handleOnClick}>
            <FormattedMessage id="errors.unauthorized.action" />
          </Button>
        }
        description={<FormattedMessage id="errors.unauthorized.description" />}
        moreInfo={
          <p className="error-section__more-info">
            <span>
              <FormattedMessage id="errors.unauthorized.notSure" />
            </span>
            <a href={ExternalPathnames.SUPPORT} target="blank" rel="noopener noreferrer">
              <FormattedMessage id="errors.unauthorized.learnMore" />
            </a>
          </p>
        }
        title={<FormattedMessage id="errors.unauthorized.title" />}
      />
    </>
  )
}

export default ErrorUnauthorized
