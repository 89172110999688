import React, { Suspense } from "react"
import HubSidebar from "./components/HubSidebar"
import BasicHubLayout from "./BasicHubLayout"
import { useIntl } from "react-intl"
import { ProtectedRoute } from "@fuze/hub-authorization"
import PageSpinner from "components/spinner/PageSpinner"

/**
 * The HubLayout is an extension of the BasicHubLayout that adds a CompanyName header (with link to CompanySettings)
 * and the Hub Sidebar
 */
const HubLayout = ({ children }) => {
  const { formatMessage } = useIntl()
  return (
    <BasicHubLayout>
      <nav className="col-sidebar" role="navigation" aria-label={formatMessage({ id: "sidebar.ariaLabel" })}>
        <HubSidebar />
      </nav>
      <main id="main-content" className="col-page-content" tabIndex="-1" role="main">
        {children}
      </main>
    </BasicHubLayout>
  )
}

const renderHubLayout = Component => {
  return props => (
    <HubLayout>
      <Component {...props} />
    </HubLayout>
  )
}

function ProtectedHubLayoutRoute({ children, Component, ...props }) {
  return (
    <ProtectedRoute
      {...props}
      render={routeProps => (
        <HubLayout>
          <Suspense fallback={<PageSpinner />}>
            <Component {...routeProps} />
          </Suspense>
        </HubLayout>
      )}
    />
  )
}

export default HubLayout
export { renderHubLayout, ProtectedHubLayoutRoute }
