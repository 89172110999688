import { useHasPermissions } from "@fuze/hub-authorization"

/**
 * This is a low-level component to prevent many different Access components from having to check for permissions
 *
 * Usage:
 * function PathAccess = ({ children }) => {
 *   const [hasPermissions, path] = usePathAccess()
 *   return <Access hasPermissions={hasPermissions} path={path} children={children} />
 * }
 *
 * @param {*} children The children to be rendered if the user has permissions to access the path
 * @param {boolean} hasPermissions Whether the user has permissions
 * @param {string} path The path to be navigated to
 */
function Access({
  children,
  hasPermissions,
  path
}: {
  children: any
  hasPermissions: boolean
  path: string
}): React.ReactNode {
  if (hasPermissions) {
    return children(path)
  }
  return null
}

/**
 * Base hook for writing access hooks
 *
 * Usage:
 * const usePathAccess = () => useAccess(["path.permission1", "path.permission2"], "/path")
 *
 * Using this hook will return a tuple with a boolean of whether the user is allowed access and the path
 *
 * @param {string[]} requiredPermissions A list of permission strings to check for
 * @param {string} path The path to be accessed
 * @param {function} permissionsCheck A function that returns if the user has the required permissions to access the children
 * By default, the permissionsCheck will return true if any of the permissions match
 *
 */
type useAccessParams = (
  requiredPermissions: string[],
  path: string,
  permissionsCheck: () => boolean
) => [boolean, string]
const useAccess: useAccessParams = (requiredPermissions, path, permissionsCheck) => {
  const hasPermissions = useHasPermissions(requiredPermissions, permissionsCheck)
  return [hasPermissions, path]
}

export default Access
export { useAccess }
