import { useLocation, matchPath } from "routing"

function isToolbox(path: string): boolean {
  return matchPath(path, { path: "/dids/logistics", exact: false, strict: true }) !== null
}

function isAlloyMigration(path: string): boolean {
  return matchPath(path, { path: "/alloy/migration", exact: false, strict: true }) !== null
}

/**
 * Return true if the active path is not one of the toolbox paths
 */
const useIsHubActive = (): boolean => {
  const state = useLocation()
  return !isToolbox(state.pathname) && !isAlloyMigration(state.pathname)
}

const useIsToolboxActive = (): boolean => {
  const state = useLocation()
  return isToolbox(state.pathname)
}

export { useIsToolboxActive }
export default useIsHubActive
