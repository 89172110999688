import { Badge } from "reactstrap"
import classNames from "classnames"

function HubSidebarBadge({ children, className, ...props }) {
  return (
    <Badge pill className={classNames(className, "side-bar__badge")} {...props}>
      {children}
    </Badge>
  )
}

export default HubSidebarBadge
