import React, { HTMLAttributes, FunctionComponent } from "react"
import classnames from "classnames"

type ImageContentProps = FunctionComponent<HTMLAttributes<HTMLOrSVGElement> & { alignTop?: string; centered?: string }>
const ImageContent: ImageContentProps = ({ centered, alignTop, children, className = "" }) => {
  return (
    <div
      className={classnames("image-row__content", className, {
        "image-row__content--centered": centered,
        "image-row__content--align-vertical-top": alignTop
      })}
    >
      {children}
    </div>
  )
}

export default ImageContent
