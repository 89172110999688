import React from "react"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import permissions from "constants/permissions"
import { homePath } from "./Paths"

export const HomeAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.generalAccess]}>
    {children(homePath)}
  </PermissionsProtected>
)

export const buildHomePath = (suffix = "") => `${homePath}/${suffix}`
