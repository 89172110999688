import { isLoggedInUserRegionalAdmin, loggedInUserManagedLocations } from "../lib/user-session"
import _difference from "lodash/difference"
import _uniq from "lodash/uniq"
import _compact from "lodash/compact"
import { categorizeServices } from "./services"
import memoizeOne from "memoize-one"
import { getAllRoles } from "data/apis/warden"
import _intersectionWith from "lodash/intersectionWith"

export function viewedUserHasOutsideLocation(location) {
  return isLoggedInUserRegionalAdmin() && !loggedInUserManagedLocations()?.includes(location)
}

/**
 * @param {String[]} roles The roles of the user being acted upon
 *
 * Returns a boolean value determining if the logged in user is a regional admin
 * and if they can edit the user being acted upon/viewed. Regional admins can only
 * edit other regional admins or "Standard Fuze Users" (users with no roles)
 */
export function isAdminAllowedToEdit(roles) {
  // disregard any of the regional admin checks if logged in user is not a regional admin
  return !isLoggedInUserRegionalAdmin() || roles?.includes("CUSTOMER_ADMIN_REGIONAL") || !roles?.length
}

function doesViewedUserHaveOutsideServiceLocations(services, handsets) {
  const serviceLocations = services?.map(service => service.location?.id) || []
  const handsetLocations = handsets?.map(handset => handset.location?.id || handset.service?.location?.id) || []
  const serviceAndHandsetLocations = [...serviceLocations, ...handsetLocations]
  return (
    isLoggedInUserRegionalAdmin() &&
    _difference(_compact(_uniq(serviceAndHandsetLocations)) || [], loggedInUserManagedLocations())?.length > 0
  )
}

export function isViewedUserReadOnly(user) {
  return (
    viewedUserHasOutsideLocation(user?.location?.id) ||
    !isAdminAllowedToEdit(user?.roles) ||
    doesViewedUserHaveOutsideServiceLocations(user?.services, user?.handsets)
  )
}

export function viewedUserHasNoServices(services, isUsingAlloy) {
  const { faxServices, contactCenterServices, recordingServices, smsServices } = categorizeServices(services)
  const hasFaxService = faxServices.length > 0
  const hasContactCenter = isUsingAlloy && contactCenterServices.length > 0
  const hasCallRecording = recordingServices.length > 0
  const hasSMS = isUsingAlloy && smsServices.length > 0

  return !hasFaxService && !hasContactCenter && !hasCallRecording && !hasSMS
}

const memoizedRoles = memoizeOne(getAllRoles)
export async function getPrimaryUserRole(user) {
  const dataRoles = await memoizedRoles()
  const primaryRoles = extractPrimaryUserRoles(user?.roles, dataRoles)

  return primaryRoles[0] || { id: "standard" }
}

function extractPrimaryUserRoles(userRoles, allRoles) {
  return _intersectionWith(allRoles, userRoles, (role, userRole) => userRole === role.id && role.type === "primary")
}
