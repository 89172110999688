import { logApiError } from "lib/logging/logging"
import { goToUniversalLoginUrlDelayed } from "data/apis/authentication-utilities"

async function errorHandler(e, fn, args) {
  switch (e?.response?.status) {
    case 401:
      return goToUniversalLoginUrlDelayed()
    case 404:
      throw e

    default:
      // This makes the assumption that the second argument is the bearer token
      args[1] = "token"
      await logApiError("Intercepted by errorInterceptor", e, { api: fn, arguments: args })
      throw e
  }
}

const errorInterceptor = fn => {
  return async function (...args) {
    try {
      return await fn(...args)
    } catch (e) {
      // This is necessary to allow an error handler to clean up
      return errorHandler(e, fn, args)
    }
  }
}

export { errorInterceptor, errorHandler }
