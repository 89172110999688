import { Badge, Tooltip } from "reactstrap"
import React from "react"
import { useToggle } from "react-use"
import classNames from "classnames"

export const ErrorBadgeWithTooltip = ({ id, errorCount, tooltipMsg, innerClassNames, ...props }) => {
  const [openTooltip, toggleTooltip] = useToggle(false)

  const badgeId = `error-badge--${id}`

  if (!errorCount) return null

  return (
    <>
      <Badge id={badgeId} color="danger" className={classNames("error-badge", innerClassNames)} pill>
        {errorCount}
      </Badge>

      <Tooltip
        target={badgeId}
        innerClassName={"error-badge__tooltip"}
        arrowClassName={"error-badge__tooltip-arrow"}
        placement={"bottom"}
        isOpen={openTooltip}
        toggle={toggleTooltip}
        {...props}
      >
        <span>{tooltipMsg}</span>
      </Tooltip>
    </>
  )
}
