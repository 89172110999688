import { useEffect, useRef, useState } from "react"
import _throttle from "lodash/throttle"
import { getValidationErrors, pollFoundryJobTillComplete } from "../data/provisioning"
import {
  deprovisionServices,
  getRecordingConfiguration,
  provisionServices,
  setRecordingConfiguration,
  updateServices,
  validateDeprovisioningServices,
  validateServices,
  validateUpdateServices
} from "data/apis/foundry"
import { getMetadata, prepareNewServiceForExistingUser } from "data/provisioning"
import { searchExtensions } from "data/apis/foundry/extensions"
import { getLocationRegistration } from "../data/locations"
import { logJavascriptError } from "lib/logging/logging"

const useScrollPosition = () => {
  const [scrollYPosition, setScrollYPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => setScrollYPosition(window.scrollY)
    const throttledScroll = _throttle(handleScroll, 300)
    window.addEventListener("scroll", throttledScroll)
    return () => window.removeEventListener("scroll", throttledScroll)
  }, [])

  return scrollYPosition
}

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function isJobSuccessful(job) {
  return job.result === "ALL_OK"
}

/* A general note for the hooks below:
   Due to the asynchronous nature of react state (the useState hook) the order of setting state vars matters:
   For example in this case we could briefly end up with a pristine state (neither processing nor complete)
    setProcessing(false)
    setIsComplete(true)
   To fix this issue we should be setting the final state before isComplete
 */

const validateServicesFn = async ({ services }) => {
  const validating = await validateServices(services)
  return validating
}

const validateDeprovisioningServicesFn = async ({ services, unitOfWork }) => {
  const validating = await validateDeprovisioningServices(unitOfWork, services)
  return validating
}

const validateUpdateServicesFn = async ({ services, unitOfWork }) => {
  const validating = await validateUpdateServices(unitOfWork, { services })
  return validating
}

async function validateProcess(validationFn, payload) {
  let validation = { warnings: [], errors: [] }
  let warningsAndErrors
  if (validationFn) {
    try {
      validation = await validationFn(payload)
      warningsAndErrors = getValidationErrors(validation)
      if (!!warningsAndErrors?.warnings?.length) {
        console.warn("Validation warning", { message: "validation warning" }, { warnings: warningsAndErrors?.warnings })
      }
      if (!!warningsAndErrors?.errors?.length) {
        logJavascriptError("Validation failed", { message: "validation errors" }, { errors: warningsAndErrors?.errors })
      }
    } catch (e) {
      logJavascriptError("Failed to complete validation", e)
    }
  }

  const { warnings, errors } = warningsAndErrors
  return { warnings, errors }
}

const useProvisionTemplate = (validateFn, provisionFn) => {
  const {
    processJob,
    isFailed,
    isProcessing,
    isComplete,
    isTimedOut,
    validationErrors,
    processingErrors
  } = useProcessProvision({
    validateFn: validateFn,
    provisionFn: provisionFn
  })

  return {
    processJob,
    isProcessing,
    isFailed,
    isComplete,
    isTimedOut,
    validationErrors,
    processingErrors
  }
}

const useProvision = () => useProvisionTemplate(validateServicesFn, provisionServices)
const useDeprovision = () => useProvisionTemplate(validateDeprovisioningServicesFn, deprovisionServices)
const useUpdateProvision = () => useProvisionTemplate(validateUpdateServicesFn, updateServices)

function useProcessProvision({ validateFn, provisionFn }) {
  const [isFailed, setIsFailed] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [isTimedOut, setTimedOut] = useState(false)
  const [isProcessing, setProcessing] = useState(false)
  const [processingErrors, setProcessingErrors] = useState(null)
  const [validationErrors, setValidationErrors] = useState([])

  // payload takes { unitOfWork, notifications, services, metadata }
  const processJob = async payload => {
    if (payload) {
      try {
        setProcessing(true)
        const { errors } = await validateProcess(validateFn, payload)
        setValidationErrors(errors)

        if (!!validationErrors.length) {
          setIsFailed(true)
          setProcessing(false)
          return
        }
      } catch (e) {
        setIsFailed(true)
        setProcessing(false)
        return
      }
    }

    const notifications = [{ type: "EMAIL", notify: false }]
    try {
      const job = await provisionFn(payload.unitOfWork, {
        notifications: payload.notifications || notifications,
        data: payload.services,
        services: payload.services,
        metadata: payload.metadata && getMetadata(payload.metadata.action, payload.metadata.isBillable),
        ...(payload.sendWelcomeEmail && { sendWelcomeEmail: payload.sendWelcomeEmail })
      })
      try {
        const data = await pollFoundryJobTillComplete(job.job)
        setIsComplete(isJobSuccessful(data))
        setProcessing(false)
        setIsFailed(!isJobSuccessful(data))
        return data
      } catch (e) {
        setTimedOut(true)
        setProcessing(false)
        setProcessingErrors(e)
        logJavascriptError("Service provision processing failed", e)
      }
    } catch (e) {
      setIsFailed(true)
      setProcessing(false)
      setProcessingErrors(e)
      logJavascriptError("Service provision processing failed", e)
      return
    }
  }

  return {
    processJob,
    isProcessing,
    isFailed,
    isComplete,
    isTimedOut,
    validationErrors,
    processingErrors
  }
}

const useExtensions = servicesIds => {
  const [extensions, setExtensions] = useState()
  const [loading, setLoading] = useState()
  useEffect(() => {
    const retrieveExtensions = async () => {
      setLoading(true)
      if (servicesIds && servicesIds.length > 0) {
        const extensions = await searchExtensions({ services: servicesIds })
        setExtensions(extensions)
      }
      setLoading(false)
    }
    retrieveExtensions()
  }, [servicesIds])
  return [extensions, loading]
}

function useProvisionRecording() {
  const {
    processJob,
    isFailed: isProvisionFailed,
    isTimedOut: isProvisionTimedOut,
    validationErrors,
    processingErrors
  } = useProvision()

  const { setRecordingConfigFn } = useSetRecordingConfig()

  const [isProcessing, setIsProcessing] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [isTimedOut, setIsTimedOut] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  useEffect(() => {
    if (validationErrors?.length || processingErrors?.length || isProvisionFailed) {
      setIsFailed(true)
    }

    if (isProvisionTimedOut) {
      setIsTimedOut(true)
    }
  }, [validationErrors, processingErrors, isProvisionFailed, isProvisionTimedOut])

  const provisionRecording = async (user, sku, recordConfig, unitOfWork, sendNotificationEmail) => {
    const service = prepareNewServiceForExistingUser(user, sku, undefined, sendNotificationEmail)
    const metadata = { action: "addRecording", isBillable: true }
    const payload = { services: [service], unitOfWork, metadata }
    try {
      setIsProcessing(true)
      const serviceResponse = await processJob(payload)
      if (serviceResponse) {
        const setRecordConfigResponse = await setRecordingConfigFn(unitOfWork, user, recordConfig)

        setIsProcessing(false)
        if (setRecordConfigResponse) {
          setIsComplete(true)
        } else {
          setIsFailed(true)
        }
      } else {
        setIsTimedOut(true)
      }
    } catch (e) {
      setIsFailed(true)
      logJavascriptError("Service provision processing failed", e)
    } finally {
      setIsProcessing(false)
    }
  }

  return {
    provisionRecording,
    isProcessing,
    isComplete,
    isTimedOut,
    isFailed,
    validationErrors,
    processingErrors
  }
}

function useSetRecordingConfig() {
  const [isComplete, setIsComplete] = useState(false)
  const [isProcessing, setProcessing] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [isTimedOut, setTimedOut] = useState(false)

  const setRecordingConfig = async (unitOfWorkToken, user, recordConfig) => {
    try {
      setProcessing(true)
      const recordingUpdateJob = await setRecordingConfiguration(unitOfWorkToken, user.id, recordConfig)
      try {
        const data = await pollFoundryJobTillComplete(recordingUpdateJob.job)
        setIsComplete(true)
        return data
      } catch (e) {
        console.error(e)
        setIsFailed(true)
        logJavascriptError("Setting user recording config failed", e, { recordConfig })
      }
    } catch (e) {
      console.error(e)
      setTimedOut(true)
      logJavascriptError("Setting user recording config failed", e, { recordConfig })
    } finally {
      setProcessing(false)
    }
  }

  return {
    setRecordingConfigFn: setRecordingConfig,
    isProcessing,
    isComplete,
    isTimedOut,
    isFailed
  }
}

function useGetRecordingConfig(userId) {
  const [recordConfig, setRecordConfig] = useState(undefined)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  useEffect(() => {
    const getRecordingConfig = async userId => {
      if (userId) {
        setIsProcessing(true)
        setIsFailed(false)
        try {
          const recordConfiguration = await getRecordingConfiguration(userId)
          if (recordConfiguration) {
            setRecordConfig(recordConfiguration)
          }
        } catch (e) {
          setIsFailed(true)
          logJavascriptError("error on useGetRecordingConfig", e)
        } finally {
          setIsComplete(true)
          setIsProcessing(false)
        }
      }
    }

    getRecordingConfig(userId)
  }, [userId])

  return {
    recordConfig,
    setRecordConfig,
    isProcessing,
    isComplete,
    isFailed
  }
}

const useLocationVoiceVideoEnabled = locationId => {
  const [locationVoiceEnabled, setLocationVoiceEnabled] = useState(false)
  const [locationVideoEnabled, setLocationVideoEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const checkLocationRegistration = async () => {
      if (locationId) {
        setLoading(true)
        const locationRegistration = await getLocationRegistration(locationId)
        setLocationVoiceEnabled(!!locationRegistration && locationRegistration.voiceEnabled)
        setLocationVideoEnabled(!!locationRegistration && locationRegistration.videoEnabled)
        setLoading(false)
      }
    }
    checkLocationRegistration()
  }, [locationId])

  return { locationVoiceEnabled, locationVideoEnabled, loading }
}

export {
  useScrollPosition,
  usePrevious,
  useProvision,
  useDeprovision,
  useUpdateProvision,
  useProvisionRecording,
  useGetRecordingConfig,
  useSetRecordingConfig,
  useExtensions,
  useLocationVoiceVideoEnabled,
  validateProcess,
  validateServicesFn
}
