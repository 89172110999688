import React, { Suspense } from "react"
import { ProtectedRoute } from "@fuze/hub-authorization"
import TopMenuLayout from "./TopMenuLayout"
import PageSpinner from "components/spinner/PageSpinner"

/**
 * The BasicHubLayout is an extension of the TopMenuLayout that adds a CompanyName header (with link to CompanySettings)
 *
 * It is basically a HubLayout without a sidebar
 */
const BasicHubLayout = ({ children }) => (
  <TopMenuLayout>
    <div className="content-layout">{children}</div>
  </TopMenuLayout>
)

const renderBasicHubLayout = Component => {
  return props => (
    <BasicHubLayout>
      <Component {...props} />
    </BasicHubLayout>
  )
}

function ProtectedBasicHubLayoutRoute({ children, ...props }) {
  return (
    <ProtectedRoute {...props}>
      <BasicHubLayout>
        <Suspense fallback={<PageSpinner />}>{children}</Suspense>
      </BasicHubLayout>
    </ProtectedRoute>
  )
}

export default BasicHubLayout
export { renderBasicHubLayout, ProtectedBasicHubLayoutRoute }
