import _intersectionWith from "lodash/intersectionWith"
import _differenceWith from "lodash/differenceWith"

export default class CacheStore {
  data = []

  constructor(keyExtractor, valueExtractor, keyComparator, maxCacheSize) {
    this.keyExtractor = keyExtractor
    this.valueExtractor = valueExtractor
    this.keyComparator = keyComparator
    this.maxCacheSize = maxCacheSize
  }

  readFromCache(keys) {
    const cached = _intersectionWith(this.data, keys.map(this.keyExtractor), (x, y) => this.keyComparator(x.key, y))
    const uncachedKeys = _differenceWith(
      keys,
      cached.map(kv => kv.key),
      (x, y) => this.keyComparator(this.keyExtractor(x), y)
    )
    const cachedValues = cached.map(kv => kv.value)

    return { cachedValues, uncachedKeys }
  }

  writeToCache(values) {
    // Remove duplicates from cache
    this.data = _differenceWith(this.data, values, (x, y) => this.keyComparator(x.key, this.valueExtractor(y)))

    // Add new values to cache
    this.data = this.data.concat(values.map(value => ({ key: this.valueExtractor(value), value })))

    // Limit size, by taking the last maxCacheSize values
    this.data = this.data.slice(-this.maxCacheSize)
  }
}
