import { restrictionForNumberValidationRegex } from "../schemas/regex"

export const fileNameTrim = fileName => fileName.replace(/_+/g, " ").trim()

export const get10DigitBillingTelephoneNumber = billingTelephoneNumber => {
  let removeSpecialCharsFromBillingTelephoneNumber = billingTelephoneNumber?.replace(
    restrictionForNumberValidationRegex,
    ""
  )
  return removeSpecialCharsFromBillingTelephoneNumber?.length >= 10
    ? removeSpecialCharsFromBillingTelephoneNumber.slice(-10)
    : ""
}
