import React from "react"
import AppErrorBoundary from "../Errors/AppErrorBoundary"
import useActingOrganization from "hooks/useActingOrganization"

import "../styles/style.scss"
import PageViews from "lib/logging/PageViews"

import VersionUpdater from "./VersionUpdater"
import { ActingOrganizationIsSuspended, isSuspended } from "../Errors/SuspendedOrganization"
import PageSpinner from "components/spinner/PageSpinner"
import "focus-visible"
import AuthenticationUnauthorized from "components/AuthenticationUnauthorized/AuthenticationUnauthorized"
import { ToastContainer } from "react-toastify"
import Routes from "./Routes"

function App() {
  const [organization, loading] = useActingOrganization()

  if (loading) {
    return <PageSpinner />
  }

  if (isSuspended(organization)) {
    return <ActingOrganizationIsSuspended />
  }

  return (
    <>
      <VersionUpdater />
      <ToastContainer containerId="toast-notifications-container" />
      <AppErrorBoundary>
        <PageViews />
        <Routes />
      </AppErrorBoundary>
      <AuthenticationUnauthorized />
    </>
  )
}

export default App
