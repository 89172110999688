// the line below aims to fix the IE11 bug which was caused by react-scripts upgrade to v4
// the fix is taken from this thread https://github.com/facebook/create-react-app/issues/9906
/** @jsxRuntime classic */
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "lib/polyfill"

import React from "react"
import ReactDOM from "react-dom"
import { unregister } from "./registerServiceWorker"

import TranslationProvider from "./app/TranslationProvider"
import { ConfigurationProvider } from "hooks/useConfiguration"
import AuthProvider from "components/authentication/AuthenticationProvider"

import App from "./app/App"
import { hideSpinner } from "components/spinner/PageSpinner"

import registerApiProviders from "data/apis/apiProviders"

registerApiProviders()

async function renderApplication() {
  hideSpinner()

  ReactDOM.render(
    <TranslationProvider>
      <ConfigurationProvider>
        <AuthProvider appName="dids">
          <App />
        </AuthProvider>
      </ConfigurationProvider>
    </TranslationProvider>,
    document.getElementById("root")
  )
  unregister()
}

renderApplication()
