import getConfiguration from "../configuration"
import { parseKeyValues, serializeKeyValues } from "lib/string-utilities"
import redirect from "lib/redirect"
import { error404Path, errorUnauthorizedPath } from "Errors/Paths.js"

export function goToUniversalLoginUrlDelayed(mode) {
  const overlay = document.body
  const visibilityClass = "session--unauthorized"
  const isLoggingOutClass = "is-logging-out"
  let delay = 5000

  if (overlay.classList.contains(visibilityClass || isLoggingOutClass)) return

  if (mode === "logout") {
    overlay.classList.add(isLoggingOutClass)
    delay = 0
  }

  overlay.classList.add(visibilityClass)

  setTimeout(() => {
    goToUniversalLoginUrl()
  }, delay)
}

export async function goToUniversalLoginUrl(mode) {
  redirect(await getUniversalLoginUrl(mode))
}

export async function getUniversalLoginUrl(mode = "") {
  const { warden } = await getConfiguration()

  // reconstructing the redirect back url to exclude the token, statusCode and msg query params
  const locationParams = parseKeyValues(window.location.search.replace("?", ""))
  let { host, pathname, protocol } = window.location
  // warden might redirect to /undefined when it's having trouble invalidating a token (401)
  pathname = pathname.replace("undefined", "")
  const params = serializeKeyValues(locationParams)

  const errorPaths = [error404Path, errorUnauthorizedPath]

  delete locationParams.token
  delete locationParams.statusCode
  delete locationParams.msg

  let newHref = `${protocol}//${host}`

  if (!errorPaths.includes(pathname)) {
    newHref += `${pathname}&${params}`
  }

  // query mode is the default and should not be explicitly used
  const queryParams = {
    accessToken: warden.appKey,
    redirectUri: newHref,
    showRememberMe: true,
    mode
  }
  const serializedQueryParams = serializeKeyValues(queryParams)

  const universalLoginUrl = `${warden.universalLoginUrl}?${serializedQueryParams}`
  return universalLoginUrl
}
