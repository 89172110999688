import {
  registerUrlProvider,
  registerBearerTokenProvider,
  registerOrganizationProvider,
  registerErrorHandlerProvider
} from "@fuze/apis-developer"

import getConfiguration from "data/configuration"
import { getToken } from "lib/token"
import { getOrganization as getActingOrganization } from "lib/user-session"

import { errorHandler } from "../errorInterceptor"

export default function registerApiProviders() {
  registerUrlProvider(() => getConfiguration().then(data => `${data.developer.url}/api`))
  registerBearerTokenProvider(async () => getToken())
  registerOrganizationProvider(async () => getActingOrganization())
  registerErrorHandlerProvider(async () => errorHandler)
}
