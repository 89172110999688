import withSideEffect from "react-side-effect"

import "./Spinner.scss"

// #page-spinner is pre-populated on index.html
const spinnerDOMElement = document.getElementById("page-spinner")
const FADE_OUT_TIMER_MS = 1000 // matches CSS fade-out setting

// We need a component that doesn't actually do anything
const NullComponent = () => null

export function showSpinner() {
  spinnerDOMElement.classList.add("spinner", "page-spinner", "fade-in")
  return true
}

export function hideSpinner() {
  spinnerDOMElement.classList.remove("fade-in")
  spinnerDOMElement.classList.add("spinner", "page-spinner", "fade-out")
  // // Remove all classes, leaving only <div id="spinner-page">,
  // //   i.e. no styling, no animation, no cpu cycles.
  setTimeout(() => {
    if (!spinnerDOMElement.classList.contains("fade-in")) {
      spinnerDOMElement.classList.remove("spinner", "page-spinner", "fade-out")
    }
  }, FADE_OUT_TIMER_MS)
  return true
}

function reduceStateToProps(propsList) {
  return propsList.some(x => !x.hidden)
}

const handleStateChangeOnClient = show => {
  if (show) {
    showSpinner()
  } else {
    hideSpinner()
  }
}

export default withSideEffect(reduceStateToProps, handleStateChangeOnClient)(NullComponent)
