import React from "react"
import BasicHubLayout from "components/layouts/BasicHubLayout"
import ErrorCover from "components/layouts/components/ErrorCover"
import { useLogout } from "@fuze/hub-authorization"
import { FormattedMessage } from "react-intl"

const Error404 = () => {
  const logout = useLogout()
  return (
    <BasicHubLayout>
      <ErrorCover
        action={
          <button className="btn btn-primary" type="button" onClick={logout}>
            <FormattedMessage id="errors.404.action" />
          </button>
        }
        description={<FormattedMessage id="errors.404.description" />}
        title={<FormattedMessage id="errors.404.title" />}
      />
    </BasicHubLayout>
  )
}

export default Error404
