/**
 * Check if all values in an array conform with the given formats.
 * Returns false if at least one is invalid
 *
 * @param {[]} values Array of values as strings
 * @param {RegExp} formats A regex containing valid formats
 *
 * @returns {boolean}
 */
import _uniqBy from "lodash/uniqBy"

/**
 * Validate an array of same values against a regex. Simply, pass the array
 * of values.
 *
 * @param {[]} values Array of values to be validated
 * @param {RegExp} formats RegExp to validate against
 */
export const isInValidFormat = (values, formats) => !values.some(value => !formats.test(value.trim()))

export const translateValidationErrors = (intl, validationErrors) => {
  const c = _uniqBy(validationErrors, "code")
    .map(error => intl.formatMessage({ id: error.code, defaultMessage: error.message }))
    .join(" ")
  return c
}
