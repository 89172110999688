import getConfiguration from "../../data/configuration"
import getVersion from "data/version"

import axios from "axios"
import { getOrganization, getUser } from "../user-session"

async function logData(description, data, severity) {
  const { splunk } = await getConfiguration()
  const userData = getUser()
  const organization = getOrganization()

  const time = Math.floor(Date.now() / 1000)
  const serverData = {
    time: time,
    index: splunk.index,
    source: "Client Browsers",
    host: "Client Browsers",
    sourcetype: "_json-hub",
    event: {
      description,
      data: { ...data, browser: window.navigator.userAgent, hubVersion: await getVersion() },
      userData: userData
        ? {
            username: userData.userKeys[0],
            userId: userData.userId,
            fullName: userData.fullName,
            tenantKey: userData.tenantKey,
            actingOrganization: organization
          }
        : {},
      severity
    }
  }

  if (splunk && splunk.url && splunk.token && splunk.index) {
    axios({
      method: "POST",
      url: splunk.url,
      headers: {
        Authorization: `Splunk ${splunk.token}`,
        // Our splunk setup does not support "Content-Type": "application/json"
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      data: serverData
    })
  } else {
    console.error(serverData)
  }
}

export async function logInfo(description, data) {
  logData(description, data, "info")
}

// This is a private function. Please DO NOT use it directly.
// There are two categories of error logs, "API error" and "Execution error" under attribute "description"
// in Splunk logs.
async function logError(description, subDescription, error, additionalData) {
  console.error(description, "(", subDescription, ")", "\n", error)
  let data
  if (additionalData) {
    console.error("AdditionalData", additionalData)
    data = { subDescription, error, additionalData }
  } else {
    data = { subDescription, error }
  }
  logData(description, data, "error")
}

// This function is to log "API error", which is done in the function wrapper, errorInterceptor.
// You should NOT try to use this function directly, but should wrap our API function with errorInterceptor
// if not done so.
// @param
// subDescription: brief description of the error
// error: exception object
// additionalData: optional. any additional data you would like to log
export async function logApiError(subDescription, error, additionalData) {
  logError("API error", subDescription, error, additionalData)
}

// This function is to log "Execution error", which is used in the most cases of reporting errors in HUB.
// @param
// subDescription: brief description of the error
// error: exception object
// additionalData: optional. any additional data you would like to log
export async function logJavascriptError(subDescription, error, additionalData) {
  logError("Execution error", subDescription, error ? error.toString() : null, additionalData)
}
