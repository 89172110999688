import React from "react"
import { Helmet } from "react-helmet"
import { injectIntl } from "react-intl"

const HelmetDefaults = props => {
  const { intl } = props

  // see https://github.com/nfl/react-helmet
  return (
    <Helmet
      defaultTitle={intl.formatMessage({ id: "pageTitle.default" })}
      titleTemplate={intl.formatMessage({ id: "pageTitle.template" })}
    />
  )
}

export default injectIntl(HelmetDefaults)
