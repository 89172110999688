import _range from "lodash/range"
import _concat from "lodash/concat"
import { consolidateUrlQuery } from "lib/string-utilities"

/**
 * Pagination decorator for pagination aware API connectors, eg. Foundry getUsers
 */
export const decorateWithPaginationFunctionAware = (fn, pageSize = 1000) => {
  return async function (...args) {
    let pagination = { offset: 0, limit: pageSize }
    return fn(...args, pagination).then(firstResponse => {
      //  once we've made a request for the first page, we can determine if we need to do additional work
      if (!firstResponse.pagination || firstResponse.pagination.total <= pageSize) {
        return firstResponse
      }

      // create promises for pages 2 ... n
      const otherRequests = _range(pageSize, firstResponse.pagination.total, pageSize).map(offset => {
        pagination = { offset: offset, limit: pageSize }
        return fn(...args, pagination)
      })

      // resolve promises for pages 2 ... n and concat on the first response
      return Promise.all(otherRequests).then(otherResponses => {
        const allResponsesData = otherResponses
          .map(response => response.data)
          .reduce((acc, curr) => acc.concat(curr), firstResponse.data)
        return { data: allResponsesData }
      })
    })
  }
}

export const decorateNetSuiteWithPagination = fn => {
  return async function (baseURL, options) {
    return fn(baseURL, options).then(firstResponse => {
      //  once we've made a request for the first page, we can determine if we need to do additional work
      if (!firstResponse.data || firstResponse.data.data.total_pages === 1) {
        return firstResponse.data.data.results
      }

      // create promises for pages 2 ... n,
      // _range progression is up to, but not including the end (second param) -> hence total_pages + 1
      const otherRequests = _range(2, firstResponse.data.data.total_pages + 1).map(page_number => {
        const url = consolidateUrlQuery(baseURL, `page_number=${page_number}`)
        return fn(url, options)
      })

      // resolve promises for pages 2 ... n and concat on the first response
      return Promise.all(otherRequests).then(otherResponses => {
        return _concat(firstResponse.data.data.results, ...otherResponses.map(response => response.data.data.results))
      })
    })
  }
}

/**
 * decorateWardenWithPagination - paginate on Warden requests until the data is exhausted
 * @param fn - warden api function to invoke
 * @param hardLimit - optional upper limit on the number of results that we draw. no more requests when this number is reached
 * @param pageSize - how many results are retrieved in a chunk (usually the limit is set by the api)
 * @returns {function(...[*]): *}
 */
export const decorateWardenWithPagination = (fn, hardLimit = Number.MAX_SAFE_INTEGER, pageSize = 100) => {
  return async function (...args) {
    let pagination = { cursor: "", limit: pageSize }
    return fn(...args, pagination.cursor, pagination.limit).then(async ({ data }) => {
      let results = data.results
      let paginationResults = data.pagination
      let latestResponse
      if (paginationResults && paginationResults.nextCursor && results.length >= pagination.limit) {
        do {
          pagination.cursor = paginationResults.nextCursor
          latestResponse = await fn(...args, pagination.cursor, pagination.limit)
          results = [...results, ...latestResponse.data.results]
          paginationResults = latestResponse.data.pagination
        } while (
          paginationResults.nextCursor &&
          latestResponse.data.results.length >= pagination.limit &&
          results.length < hardLimit
        )
      }
      return { data: { results } }
    })
  }
}
