import React from "react"
import { UserAvatar } from "components/layouts/components/UserAvatar"
import HeaderLoggedInUserDescription from "components/layouts/components/HeaderLoggedInUserDescription"
import { Collapse, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from "reactstrap"
import { getUser } from "lib/user-session"
import { getFullUserName } from "lib/string-utilities"
import ExternalPathnames from "constants/external-pathnames"

type UserMenuProps = { isOpen: boolean }
const UserMenu: React.FunctionComponent<UserMenuProps> = ({ isOpen }) => {
  const user = getUser()

  return (
    <>
      <Collapse isOpen={isOpen} navbar>
        <Nav id="header-right" navbar>
          <UncontrolledDropdown nav className="nav-item top-nav-bar__user">
            <DropdownToggle nav>
              <UserAvatar className="icon" user={user} />
              <span className="member-name" data-before-content={getFullUserName(user)} />
              <i className="icon-arrow-down" />
            </DropdownToggle>
            <div className={"top-nav-bar--user-help"}>
              <a href={ExternalPathnames.SUPPORT} target="_blank" rel="noopener noreferrer">
                <i className="icon-hubnav_help top-nav-bar--user-help--icon" />
              </a>
            </div>
            <DropdownMenu
              modifiers={{
                computeStyle: {
                  fn: (data, options) => ({
                    ...data,
                    styles: {
                      ...data.styles,
                      left: "auto",
                      right: "0"
                    }
                  })
                }
              }}
            >
              <HeaderLoggedInUserDescription />
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </>
  )
}

export default UserMenu
