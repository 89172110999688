import _flow from "lodash/flow"
import memoizeOne from "memoize-one"
import getConfiguration from "../../configuration"
import { errorInterceptor } from "data/apis/errorInterceptor"
import { bearerToken, extractData, organization, populateParameter } from "../utils"

import {
  getTenantIDPsMetadata,
  getTenantIDPs,
  addTenantIDP,
  updateTenantIDP,
  deleteTenantIDP,
  updateUserIDP,
  bulkUpdatePartialUsersIDP,
  bulkUpdateAllUsersIDP
} from "@fuze/apis-warden/dist/IdentityManagement"
import IDPResponse from "@fuze/apis-warden/dist/IdentityManagement/models/IDPResponse"
import IDPMetadata from "@fuze/apis-warden/dist/IdentityManagement/models/IDPMetadata"
import IDPRequest from "@fuze/apis-warden/dist/IdentityManagement/models/IDPRequest"

// Possible duplicates!
const url = populateParameter(async () => {
  const { warden } = await getConfiguration()
  return warden.url
})

const defaultParameters = _flow(errorInterceptor, url, bearerToken)
const defaultOrganizationParameters = _flow(defaultParameters, organization)

const wrapWardenFunctionWithOrganization = _flow(defaultOrganizationParameters, extractData)
const wrapWardenFunctionWithoutOrganization = _flow(defaultParameters, extractData)

// End Possible duplicates!

const getWardenIDPsMetadata = wrapWardenFunctionWithoutOrganization(getTenantIDPsMetadata)

const getWardenTenantIDPs: () => Promise<IDPResponse[]> = wrapWardenFunctionWithOrganization(getTenantIDPs)
const addWardenTenantIDP = (idp: IDPRequest): Promise<IDPResponse> =>
  wrapWardenFunctionWithOrganization(addTenantIDP)(idp)
const updateWardenTenantIDP = (idp: IDPRequest) => wrapWardenFunctionWithOrganization(updateTenantIDP)(idp)
const updateWardenUserIDP = (userId: string, idpId: number) =>
  wrapWardenFunctionWithoutOrganization(updateUserIDP)(userId, idpId)
const deleteWardenTenantIDP = (idpId: number, replacementIdpId: number) =>
  wrapWardenFunctionWithOrganization(deleteTenantIDP)(idpId, replacementIdpId)

const memoizedIDPsMetadata = memoizeOne(getWardenIDPsMetadata)

const wardenBulkUpdateAllUsersIDP = (idpId: number) => wrapWardenFunctionWithOrganization(bulkUpdateAllUsersIDP)(idpId)
const wardenBulkUpdatePartialUsersIDP = (idpId: number, useIds: string[]) =>
  wrapWardenFunctionWithOrganization(bulkUpdatePartialUsersIDP)(idpId, useIds)

const getWardenIDPsMetadataHandled: () => Promise<IDPMetadata[]> = _flow(errorInterceptor)(memoizedIDPsMetadata)

export {
  getWardenIDPsMetadataHandled as getWardenIDPsMetadata,
  getWardenTenantIDPs,
  addWardenTenantIDP,
  updateWardenTenantIDP,
  deleteWardenTenantIDP,
  updateWardenUserIDP,
  wardenBulkUpdateAllUsersIDP,
  wardenBulkUpdatePartialUsersIDP
}
