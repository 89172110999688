import {
  registerUrlProvider,
  registerBearerTokenProvider,
  registerErrorHandlerProvider,
  registerAppKeyProvider,
  registerImpersonatorBearerTokenProvider
} from "@fuze/apis-floppy"

import getConfiguration from "data/configuration"
import { getToken } from "lib/token"
import { errorHandler } from "../errorInterceptor"
import { getNonImpersonatingToken } from "@fuze/services-auth/dist/token"

export default function registerApiProviders() {
  registerUrlProvider(() => getConfiguration().then(data => data.floppy.url))
  registerAppKeyProvider(() => getConfiguration().then(data => data.floppy.appKey))
  registerBearerTokenProvider(async () => getToken())
  registerErrorHandlerProvider(async () => errorHandler)
  registerImpersonatorBearerTokenProvider(() => getNonImpersonatingToken())
}
