import { useHasPermissions } from "@fuze/hub-authorization"
import { hasEveryPermission } from "lib/permissions"

// TODO Add storybook for these components
// TODO Add react-testing-library tests for these components
/**
 * Protects content from being access by someone who doesn't have permissions
 *
 * Usage:
 *
 * <PermissionsProtected requiredPermissions={["user_add"]}>
 *   ... some content you want only users with the "user_add" permissions to see ...
 * </PermissionsProtected>
 *
 * You can provide a fallback if the user does not have the required permissions
 *
 * <PermissionsProtected
 *    requiredPermissions{["user_add"]}
 *    fallback={... show something to the user if they don't have access ...}>
 *   ... some content you want only users with the "user_add" permissions to see ...
 * </PermissionsProtected>
 *
 * You can provide the permissions check function. By default, the component will ensure
 * that the user has all of the required permissions, but also provides a function out of
 * the box that will check if the user has ANY of the permissions
 *
 * See hasSomePermission for that example
 *
 * <PermissionsProtected
 *    requiredPermissions={["user_add", "user_view"]}
 *    permissionsCheck={hasSomePermission}>
 *  ... some content you want only users with at least "user_add" or "user_view" to see ...
 * </PermissionsProtected>
 */

type PermissionsProtectedProps = {
  requiredPermissions: string[]
  permissionsCheck?: (permissions: string[], requiredPermissions: string[]) => boolean
  fallback?: JSX.Element
  children: JSX.Element
}

const PermissionsProtected = ({
  requiredPermissions,
  permissionsCheck = hasEveryPermission,
  fallback,
  children
}: PermissionsProtectedProps) => {
  const hasNecessaryPermissions = useHasPermissions(requiredPermissions, permissionsCheck)

  if (!hasNecessaryPermissions) {
    if (fallback) {
      return fallback
    }
    return null
  }

  return children
}

export default PermissionsProtected
