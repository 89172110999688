const permissions = {
  users: {
    add: "user_add",
    deactivate: "user_deactivate",
    reactivate: "user_reactivate",
    export: "user_export",
    view: "user_view",
    ratings: "ratings_read",
    edit: "user_edit",
    upgrade: "user_upgrade",
    managePartners: "user_manage_partners",
    manageRoles: "user_manage_roles",
    addDiscovery: "user_add_discovery",
    addFax: "user_add_fax",
    addRecording: "user_add_recording",
    addContactCenter: "user_add_contact_center",
    addSMS: "user_add_sms",
    addPhoneNumber: "user_add_phone_number",
    changePhoneNumber: "user_change_phone_number",
    serviceDeprovision: "service_deprovision",
    sendWelcomeEmail: "send_welcome_email",
    generalAccess: "general_access",
    switchCustomer: "switch_customer",
    idmEditUserProvider: "idm_edit_user_provider",
    resetPassword: "user_reset_password",
    addUserNoService: "user_add_no_services",
    viewSatisfaction: "user_ratings_read"
  },
  contactRosters: {
    view: "contact_rosters_view",
    add: "contact_rosters_add",
    edit: "contact_rosters_edit",
    viewRosterDetails: "contact_rosters_view_details",
    delete: "contact_rosters_delete",
    viewUsersOfRoster: "contact_rosters_view_users",
    addUsersToRoster: "contact_rosters_add_users",
    deleteUsersFromRoster: "contact_rosters_delete_users",
    viewRostersOfUser: "contact_rosters_view_user_rosters",
    companyVisibilityAccess: "contact_rosters_company_visibility_access"
  },
  legal: {
    clickthrough: "clickthrough_accept"
  },
  lines: {
    activate: "line_activate"
  },
  orders: {
    requiredPermissions: "order_access"
  },
  guests: {
    enableFeature: "guest_enable_feature",
    manage: "guest_manage_guests"
  },
  devices: {
    access: "device_access",
    bindHandset: "device_bind_handset",
    claimHandset: "device_claim_handset",
    orderHardware: "order_hardware"
  },
  locations: {
    view: "location_view",
    edit: "location_edit",
    add: "location_add",
    delete: "location_delete",
    bulkEditCallerId: "location_update_callerid_bulk_locations"
  },
  emailSettings: {
    customerWelcomeEmailUpdate: "customer_welcome_email_update"
  },
  bulkProvisioning: {
    userUploadCsv: "user_upload_csv",
    serviceSetFutureActivationDate: "service_set_future_activation_date"
  },
  desktopUpdates: {
    companySettingsAccess: "company_settings_access",
    manageDesktopVersion: "desktop_manage_version"
  },
  identityManagement: {
    idmAddProvider: "idm_add_provider",
    idmEditProvider: "idm_edit_provider",
    idmRemoveProvider: "idm_remove_provider"
  },
  utility: {
    add: "utility_add",
    edit: "utility_edit",
    view: "utility_view"
  },
  dids: {
    newDids: "did_request_create",
    viewDids: "did_request_view",
    updateDids: "did_request_update",
    viewDidRateCenters: "did_ratecenter_view",
    updateDidRateCenters: "did_ratecenter_update",
    didRequestCreateNoRestrictions: "did_request_create_no_restrictions"
  },
  portRequests: {
    createOrUpdatePortinOrders: "did_porting_portins_create_or_supp",
    viewPortinOrders: "did_porting_portins_view",
    portinsNoLocationRestrictions: "did_porting_portins_no_location_restrictions",
    updatePortinOrdersElevated: "did_porting_portins_supp_elevated",
    updatePortinCalendar: "did_porting_portins_calendar_update",
    viewPortinCalendar: "did_porting_portins_calendar_view"
  },
  developerApi: {
    developerApiAccess: "developer_api_access",
    developerApiKeyAdd: "developer_api_key_add",
    developerApiKeySuspend: "developer_api_key_suspend",
    developerApiKeyRevoke: "developer_api_key_revoke"
  },
  alloyMigration: {
    migrationToolbox: "migration_toolbox",
    migrationDashboard: "migration_dashboard",
    migrationReport: "migration_report",
    migrationPlan: "migration_plan"
  },
  tenantManagement: {
    readOwnActOnList: "act_on_read",
    readAnyActOnList: "act_on_read_any",
    editOwnActOnList: "act_on_write",
    editAnyActOnList: "act_on_write_any",
    impersonateOwnTenant: "user_sudo",
    impersonateAnyTenant: "user_sudo_any"
  }
}

export default permissions
