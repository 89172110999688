export const ProductCategory = {
  voice: "voice",
  video: "video",
  fax: "fax",
  discover: "discover",
  recording: "call_recording",
  contactCenter: "contactCenter",
  did: "did",
  sms: "sms"
}
