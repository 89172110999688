import { lazy } from "react"

import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"
import { didRequestsPath } from "./Paths"

const DidRequests = lazy(() => import("./DidRequests"))

// don't render if the active app doesn't "own" the route
// render "unauthorized" page if the user doesn't have permissions
// nice to have: render "not enabled" page if the customer isn't enabled (foundry enablements)
// render (or redirect) to legal page if the customer hasn't signed their EULA (end user license agreement)
// to consider: the legal page doesn't require this check
const Routes = () => {
  return (
    <>
      <ProtectedHubLayoutRoute exact path={didRequestsPath} Component={DidRequests} />
    </>
  )
}

export default Routes
