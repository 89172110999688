import { useEffect, useMemo, useState } from "react"
import { MAX_PAGE_SIZE_PAGINATION } from "data/apis/foundry/constants"
import Organization from "@fuze/apis-foundry/dist/organizations/models/Organization"
import unionBy from "lodash/unionBy"
import { isEqual } from "lodash"
import { PaginatedEnvelope } from "@fuze/apis-core/dist/envelope"
import { IFavouriteTenant } from "../types/IFavouriteTenant"
import { PaginationResponse } from "@fuze/apis-core/dist/pagination"
import useOrganizationsWithResellers from "./useOrganizationsWithResellers"
import usePartnerOrganizations from "./usePartnerOrganizations"
import { useIsOperatorTenant } from "./useIsOperatorTenant"
import IDisplayTenant from "../types/IDisplayTenant"
import useImpersonatorOrganization from "./useImpersonatorOrganization"

// scrolling tenants is rarely done on purpose, lets limit the batch in order to free up resources
const PAGINATION_SIZE_TENANTS = MAX_PAGE_SIZE_PAGINATION / 5

type useAllTenantsProps = {
  tenants: IDisplayTenant[]
  pagination?: PaginationResponse
  loading: boolean
  error?: Error
  loadMore: () => any // the type is identical to Function, InfiniteScroll is particular in what they accept
}

/**
 * Get full Organization details for the query in question, also allow loading more data for existing query
 *
 * @param query a filter to search by (can be a tenant name or tenant code)
 */
function useAllTenants(query: string = ""): useAllTenantsProps {
  // remember the last pagination offset that we asked data for
  const [latestLoaded, setLatestLoaded] = useState<number>(0)
  const [tenants, setTenants] = useState<IFavouriteTenant[]>([])
  // this is used to determine if current invocation of useAllTenants received a new query and should drop all know data
  const [queryOnRecord, setQueryOnRecord] = useState<string>(query)
  // used to make sure we dont issue extra api calls due to page re-renders
  const [lastKnownResponse, setLastKnownResponse] = useState<PaginatedEnvelope<Organization>>()
  const isNewQuery = query !== queryOnRecord
  // reset pagination offset for new queries
  const offset = isNewQuery ? 0 : latestLoaded
  const pagination = useMemo(() => ({ offset: offset, limit: PAGINATION_SIZE_TENANTS }), [offset])
  const { organizations, loading, error, loadMore } = useOrganizationsWithResellers(query, pagination)

  // for partners the solution is more simple: no pagination is required as the max clients is set to 50
  const { value: partnerOrganizations, loading: partnerOrganizationsLoading } = usePartnerOrganizations()
  const isOperatorTenant = useIsOperatorTenant()
  const { value: partnerOwnOrganization } = useImpersonatorOrganization()

  // add newly loaded tenants on top of the existing or reset to new data if this is a new query
  useEffect(() => {
    if (organizations && !isEqual(lastKnownResponse, organizations) && isOperatorTenant) {
      // remember data and query as described above
      setLatestLoaded(organizations.pagination.offset + PAGINATION_SIZE_TENANTS)
      setQueryOnRecord(query)
      setLastKnownResponse(organizations)

      // the data that this hook returns should be whatever we had stored plus newly retrieved data
      // or start from scratch if this is a new query
      setTenants(isNewQuery ? organizations.data : unionBy(tenants, organizations.data, "code"))
    }
  }, [query, organizations, isNewQuery, lastKnownResponse, tenants, isOperatorTenant])

  if (isOperatorTenant) {
    return { tenants, pagination: organizations?.pagination, loading, error, loadMore }
  } else {
    return {
      tenants:
        partnerOrganizations && partnerOwnOrganization
          ? [...partnerOrganizations, partnerOwnOrganization.organization]
          : [],
      loading: partnerOrganizationsLoading,
      loadMore: () => null // no need to load more data for partners
    }
  }
}

export default useAllTenants
