import React from "react"
import Logo from "images/logo.png"

export default function ErrorCoverTopBar({ action = null }) {
  return (
    <div className="error-cover-view__top-bar">
      <img src={Logo} alt="fuze" />
      {action}
    </div>
  )
}
