import React from "react"
import { useIntl, FormattedMessage } from "react-intl"
import ToggleSection from "./ToggleSection"
import useFavouriteCustomers from "../hooks/useFavouriteCustomers"
import Tenant from "./Tenant"
import { Spinner } from "reactstrap"
import { useToggle } from "react-use"

const FavoriteCustomers = ({ favouriteTenants, updateFavourites }) => {
  const intl = useIntl()
  const { favouriteCustomers, loading } = useFavouriteCustomers(favouriteTenants)
  const [isOpen, toggle] = useToggle(true)

  return (
    <ToggleSection
      title={intl.formatMessage({ id: "tenantSwitcher.favouriteCustomers" })}
      isOpen={isOpen}
      toggleOpen={toggle}
    >
      <ul
        id={"tenant-switcher--dropdown-menu--favourite-customers"}
        className={"tenant-switcher--dropdown-menu--section"}
      >
        {loading && <Spinner />}
        {favouriteCustomers?.map(customer => (
          <Tenant tenant={customer} key={customer.code} updateFavourites={updateFavourites} />
        ))}
        {!favouriteCustomers.length && (
          <span className={"tenant-switcher__no-match"}>
            <FormattedMessage id="tenantSwitcher.noFavourites" />
          </span>
        )}
      </ul>
    </ToggleSection>
  )
}

export default FavoriteCustomers
