function getExtension(file) {
  const filename = file.name
  const [extension] = filename.split(".").slice(-1)
  return extension
}

function renameExtension(filename, newExtension) {
  const [extension] = filename.split(".").slice(0, 1)
  return extension + `.${newExtension}`
}

/**
 * Reads a Web API File and returns a string
 *
 * @param {File} file https://developer.mozilla.org/en-US/docs/Web/API/File
 *
 * @return Promise<string>
 */
function readFile(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = () => resolve(fileReader.result)
    fileReader.onerror = error => reject(error)

    fileReader.readAsText(file)
  })
}

export { getExtension, renameExtension, readFile }
