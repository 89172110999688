import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { logJavascriptError } from "lib/logging/logging"
import { isDevEnv } from "lib/user-session"
import BasicHubLayout from "components/layouts/BasicHubLayout"
import ErrorCover from "components/layouts/components/ErrorCover"
import { supportData } from "constants/support"
import { FormattedMessage } from "react-intl"

class AppErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired
  }

  static defaultProps = {
    children: <Fragment />
  }

  state = {
    hasError: false,
    error: ""
  }

  componentDidCatch(error, info) {
    if (isDevEnv) {
      console.error(error)
    } else {
      logJavascriptError("Intercepted by AppErrorBoundary", error)
    }
    this.setState({ hasError: true, error: JSON.stringify({ error, info }) })
  }

  componentDidUpdate() {
    const { fetchError, handleFetchError } = this.props
    if (fetchError !== null && fetchError !== undefined && !fetchError.handled) {
      this.componentDidCatch(fetchError)
      handleFetchError()
    }
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    } else {
      return (
        <BasicHubLayout>
          <ErrorCover
            action={
              <button className="btn btn-primary" type="button" onClick={this._handleOnClick}>
                <FormattedMessage id={"support.info"} />
              </button>
            }
            description={<FormattedMessage id="errors.errorBoundary.description" />}
            title={<FormattedMessage id="errors.errorBoundary.title" />}
          />
        </BasicHubLayout>
      )
    }
  }

  _handleOnClick() {
    window.open(supportData.supportDocURL)
  }
}

export default AppErrorBoundary
