import { useState, useEffect } from "react"

import { getOrganization as getActingOrganization } from "lib/user-session"
import { getOrganization as getOrganizationDetails } from "data/apis/foundry"
import Organization from "@fuze/apis-foundry/dist/organizations/models/Organization"
import { Optional } from "../types/hubTypes"

export default function useActingOrganization(): [Optional<Organization>, boolean] {
  const [organization, setOrganization] = useState<Organization>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    async function getOrganization() {
      const organization = await getOrganizationDetails(getActingOrganization())
      setOrganization(organization)
      setLoading(false)
    }
    getOrganization()
  }, [])

  return [organization, loading]
}
