import React from "react"
import { Spinner } from "reactstrap"
import { useIntl } from "react-intl"

const AuthenticationUnauthorized = () => {
  const { formatMessage } = useIntl()

  return (
    <div className="overlay overlay--authentication-unauthorized">
      <h1>{formatMessage({ id: "unauthorized.title" })}</h1>
      <div>
        <Spinner />
        <span> {formatMessage({ id: "unauthorized.description" })}</span>
      </div>
    </div>
  )
}

export default AuthenticationUnauthorized
