import { useAsyncRetry } from "react-use"
import { getPartnerTenantsByOrg } from "@fuze/apis-warden"
import _flatten from "lodash/flatten"
import _unionBy from "lodash/unionBy"
import { searchOrganizationDetailsForImpersonator } from "@fuze/apis-foundry/dist/organizations"

// this hook is similar to useOrganizationsWithResellers but uses different api endpoint to search for several organizations at once
function useSearchOrganizationsWithResellers(tenantCodes, paginationParams) {
  const { value: organizations, loading, error, retry: loadMore } = useAsyncRetry(async () => {
    if (tenantCodes.length) {
      const organizations = await searchOrganizationDetailsForImpersonator({ codes: tenantCodes }, paginationParams)
      const resellers = organizations.filter(organization => organization.type === "reseller")
      const resellersMap = (
        await Promise.all(
          resellers.map(async reseller => {
            const { actOn: actOnList } = await getPartnerTenantsByOrg(reseller.code)
            return { reseller, actOnList }
          })
        )
      ).filter(item => item.actOnList.length)

      const managedOrganizations = _flatten(
        resellersMap.map(reseller => {
          const resellerManagedCodes = reseller.actOnList.map(item => item.tenantKey)

          //managed organizations by this reseller
          return organizations
            .filter(organization => resellerManagedCodes.includes(organization.code))
            .map(organization => ({ ...organization, managedByPartner: reseller.reseller }))
        })
      )

      return _unionBy(managedOrganizations, organizations, "code")
    } else {
      return []
    }
  }, [tenantCodes])

  return { organizations, loading, error, loadMore }
}

export default useSearchOrganizationsWithResellers
