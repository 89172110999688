import getOrigin from "lib/origin"
import { getToken } from "lib/token"
import { getOrganization as getActingOrganization } from "lib/user-session"
import createCache from "../caching/createCache"
import decorateWithCache from "../caching/decorateWithCache"
import _curryRight from "lodash/curryRight"

interface APIResponsePaginated {
  data?: any[] | any
  pagination: { offset: number; limit: number; total: number }
}

/**
 * Given a parameterPopulator function, this passes the result of the populator as a parameter into the wrapped function
 *
 * Example:
 * const url = populateParameter(() => "https://foundry.fuze.com")
 *
 * @param {Function} parameterPopulator A function that will generate a value that will be added to the parameter list of the underlying function
 */
const populateParameter = <T extends Function, U>(parameterPopulator: () => U) => (fn: T) => {
  return async function (...args: any[]): Promise<T> {
    const paramValue = await parameterPopulator()
    return await fn(paramValue, ...args)
  }
}

/**
 * Adds the bearer token as a parameter to the wrapped function
 */
const bearerToken = populateParameter(() => getToken())

/**
 * Adds the origin header as a parameter to the wrapped function
 */
const originHeader = populateParameter(async () => {
  return await getOrigin()
})

/**
 * Adds the organization as a parameter to the wrapped function
 */
const organization = populateParameter(() => getActingOrganization())

/**
 * Adds a null organization as a parameter to the wrapped function
 */
const anyOrganization = populateParameter(() => null)

/**
 * Given a function, this extracts the .data from the response
 *
 * Essentially, this is un-enveloping
 *
 * @param {Function} fn The function to extract data from
 */
const extractData = (fn: (...args: any) => APIResponsePaginated) => {
  return async function (...args: any) {
    const { data } = await fn(...args)
    return data
  }
}

/**
 * Given a function, this extracts the .data.results from the response
 *
 * This is used primarily by some of the Warden endpoint
 *
 * @param {Function} fn The function to extract results from
 */
const extractResults = (fn: (...args: any) => APIResponsePaginated) => {
  return async function (...args: any) {
    const response = await fn(...args)
    return response?.data?.results
  }
}

/**
 * Instead of having to call fn({ ids: ids }), we can simply write fn(ids)
 *
 *
 * @param {Function} fn
 */
const acceptIds = (fn: (arg: any) => Promise<APIResponsePaginated>) => async (ids: (number | string)[]) => {
  ids = ids.filter(Number)

  if (ids.length) {
    return await fn({ ids })
  }
  return { data: [], pagination: { offset: 0, limit: 0, total: 0 } }
}

const cached = _curryRight(decorateWithCache)(createCache())

export {
  populateParameter,
  bearerToken,
  originHeader,
  organization,
  anyOrganization,
  extractData,
  extractResults,
  acceptIds,
  cached
}
