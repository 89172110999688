/**
 * Helper utility is for things that cannot be grouped under other categories
 *
 */

import _forEach from "lodash/forEach"

/**
 * Group values in to buckets a given given key. values which can fall
 * in to multiple buckets are duplicated (by ref) in each bucket
 *
 * @param {[]} values Array of objects to be sorted
 * @param {string} key Property in the object to be checked against
 * @param {function} getBucketsFn Function to get possible buckets per key,
 * this is nothing but the allowed actions from the ALLOWED_ACTIONS_MENU_ITEMS_BY_STATUS constant.
 * If the array returned contains DELETE, SCHEDULE_ACTIVATION_DATE then the order is added to
 * delete bucket and schedule activation bucket and so on.
 * @param {function} pruneFn Function to prune values against bucket keys. E.g: - Not all cancellation should
 * be shown. Event if valid for cancellation, orders also need to adhere to the MIN_SLA_FOR_CANCELLATION rule
 *
 * @returns {object} Object of key value pairs {groupId: [value1,..., valueN]}
 */
export const sortValuesInToBuckets = (values, key, getBucketsFn, pruneFn = () => true) => {
  const buckets = {}

  _forEach(values, value => {
    // Loop over the values and find possible buckets for the given value
    // against the given key
    const possibleBuckets = getBucketsFn(value[key])

    // Loop over the possible buckets and if not available in buckets push to the buckets
    // Then add the values to the correct bucket. Assumes bucket to be a string
    _forEach(possibleBuckets, bucket => {
      if (!buckets.hasOwnProperty(bucket)) {
        buckets[bucket] = []
      }

      // If prune returns false we don't add this to the bucket
      if (!!pruneFn(bucket, value)) {
        buckets[bucket].push(value)
      }
    })
  })
  // Zero element buckets should be pruned as well
  const prunedBuckets = {}

  // Note: Probably can remove this loop by re-arranging  nested
  // loop code
  _forEach(buckets, (value, bucketKey) => {
    if (!!value.length) {
      prunedBuckets[bucketKey] = value
    }
  })

  return prunedBuckets //buckets
}

/**
 * Scrolls the element in to view
 *
 * @param {string} id Element id of the DOM element to scroll in to view
 */
export const scrollIntoView = id => document.getElementById(id).scrollIntoView()

/**
 * Extracts, the numbers from a given string.
 * Used in extracting numbers for duplicated numbers.
 * Doesn't support floating or decimals point values
 *
 * @param {string} str A string containing the numeric values
 *
 * @returns {number[]}
 */
export const getIntFromString = str => str.match(/\d+/gm).map(value => parseInt(value, 10)) ?? []
