/**
 * Wraps a function that takes a list of ids with caching of those entities
 * @param {Function} fn A function (that takes a list of ids as a parameter) to cache
 * @param {Object} cacheStore A CacheMedium object
 */
// function oldDecorateWithCache(fn, cacheStore) {
//   return async function (ids) {
//     const cached = cacheStore.getIds(ids)
//     // filter out ids that were stored in the cache
//     const filteredIds = _filter(ids, id => !_findKey(cached, { id: id }))

//     if (filteredIds.length) {
//       const retrieved = await fn(filteredIds)
//       cacheStore.data = cacheStore.data.concat(retrieved)
//       return [...cached, ...retrieved]
//     } else {
//       return cached
//     }
//   }
// }

/**
 * Wraps a function that takes a list of ids with caching of those entities
 * @param {Function} fn A function (that takes a list of ids as a parameter) to cache
 * @param {Object} cacheStore A CacheStore object
 */
export default function decorateWithCache(fn, store) {
  return async function (requests) {
    if (requests && (requests.length || Object.keys(requests).length)) {
      const { uncachedKeys, cachedValues } = await store.readFromCache(requests)

      if (uncachedKeys.length) {
        const uncachedValues = await fn(uncachedKeys)

        const combinedValues = [...cachedValues, ...uncachedValues]

        store.writeToCache(combinedValues)

        return combinedValues
      } else {
        return cachedValues
      }
    } else {
      const uncachedValues = await fn(requests)
      store.writeToCache(uncachedValues)

      return uncachedValues
    }
  }
}
