import { getUser } from "lib/user-session"
import { useEffect, useState } from "react"
import { getPrimaryUserRole } from "data/user"

export default function usePrimaryUserRole(user = getUser()) {
  const [role, setRole] = useState()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const retrievePrimaryUserRole = async () => {
      const userPrimaryRole = await getPrimaryUserRole(user)
      setLoading(false)
      setRole(userPrimaryRole)
    }
    retrievePrimaryUserRole()
  }, [user])

  return [role, loading]
}
