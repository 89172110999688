import React from "react"
import { toast, Slide } from "react-toastify"
import confirmationIcon from "../images/confirmation_icon.svg"
import errorIcon from "../images/error_icon_round@3x.svg"
import fuzeBotIcon from "../images/Fuzebot_reg.png"

const Toast = ({ title, body, icon, closeToast, toastProps, ...props }) => (
  <div className="toast__container" {...props}>
    <div className="toast__icon">
      <img src={icon} alt="" />
    </div>
    <div className="toast__content">
      <div className="toast__title">{title}</div>
      {body}
    </div>
  </div>
)

const showToast = ({ body, options = {}, ...props }) => {
  // Legacy check from react-redux-toastr because the body is a function
  const bodyContent = typeof body === "function" ? body() : body
  const combinedOptions = {
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: false,
    transition: Slide,
    role: "alert",
    ...options
  }

  toast(<Toast body={bodyContent} {...props} />, combinedOptions)
}

const toastr = {
  success: (title, body, options) => {
    const icon = confirmationIcon
    showToast({ title, body, icon, options })
  },
  error: (title, body, options) => {
    const icon = errorIcon
    showToast({ title, body, icon, options })
  },
  info: (title, body, options) => {
    const icon = fuzeBotIcon
    showToast({ title, body, icon, options })
  }
}

export { toastr }
