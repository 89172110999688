import {
  Link as RouterLink,
  NavLink,
  Redirect,
  Switch,
  Route,
  Router,
  matchPath,
  useHistory,
  useLocation,
  useParams,
  withRouter,
  ReactRouterRoute
} from "@fuze/hub-router"
import { ProtectedLink, useProtectedLinkSection } from "@fuze/hub-authorization"

export function Link({ children, ...props }) {
  return (
    <ProtectedLink Tag={RouterLink} {...props}>
      {children}
    </ProtectedLink>
  )
}

const useMultiAppProtectedLinkSection = (to, Link = RouterLink) => useProtectedLinkSection(to, Link)

export {
  useHistory,
  NavLink,
  Redirect,
  Switch,
  Route,
  Router,
  matchPath,
  useLocation,
  useParams,
  withRouter,
  ReactRouterRoute
}
export { RouterLink, ProtectedLink, useMultiAppProtectedLinkSection as useProtectedLinkSection }
